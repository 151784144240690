import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { ControlForm, Modal } from "../../../components";
import InputForm from "../../../components/controls/InputForm";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useEffect, useState, useCallback } from "react";
import TextEditorQuill from "../../../components/TextEditorQuill";
import { IWorkflow } from "../../../models/workflow";
import { ITag } from "../../../models/user";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import StageService from "../../../services/api/stage.service";
import { Wrapper } from "./styles";
import { IStage, IStageRequest } from "../../../models/Stage";
import useAxios from "../../../components/UseAxios/useAxios";
import { toggleMessage } from "../../../components/Toast/Toast";

import {
  EStageColumnColor,
  EWorkflowProcess
} from "../../../models/common/models.enum";

interface IProps {
  open: boolean;
  workflow: IWorkflow;
  stageSelected?: IStage;
  isUpdate?: boolean;
  side?: number;
  handleClose: () => void;
  handleSuccess: (newStage: IStage) => void;
}

interface DataForm {
  workflowName: string;
  stageName: string;
  stageOwners: ITag[];
  stageDefaultFolowers: ITag[];
  stageWorkers: ITag[];
  jobAssign: string;
  stageReAssignTaskId: string;
  expectedDuration: string;
  expectedDurationRule: string;
  insertAt: string;
  guideline: string;
  color: string;
}

/**
 * A modal component for creating or updating workflow stages.
 *
 * @component
 * @param {IProps} props - Component props
 * @param {boolean} props.open - Controls the visibility of the modal
 * @param {IWorkflow} props.workflow - The workflow object containing workflow information
 * @param {IStage} props.stageSelected - The currently selected stage (if updating)
 * @param {string} props.side - The side where the stage will be positioned
 * @param {boolean} props.isUpdate - Flag indicating if this is an update operation
 * @param {() => void} props.handleClose - Callback function to close the modal
 * @param {(stage: IStage) => void} props.handleSuccess - Callback function called after successful creation/update
 *
 * @description
 * This modal component provides a form interface for creating or updating workflow stages.
 * It includes fields for:
 * - Stage name
 * - Color selection
 * - Stage administrators
 * - Default followers
 * - Stage executors
 * - Job assignment rules
 * - Expected duration
 * - Guidelines
 *
 * The component handles both creation and update operations, with different validation rules
 * and API calls depending on the operation type. It uses form validation through Yup schema
 * and manages state through React Hook Form.
 *
 * @example
 * <CreateStageModal
 *   open={true}
 *   workflow={workflowData}
 *   stageSelected={selectedStage}
 *   side="right"
 *   isUpdate={false}
 *   handleClose={() => setModalOpen(false)}
 *   handleSuccess={(stage) => handleStageCreated(stage)}
 * />
 */
const CreateStageModal = ({
  open,
  workflow,
  stageSelected,
  side,
  isUpdate,
  handleClose,
  handleSuccess
}: IProps) => {
  const createStageAxios = useAxios<IStage>({ loading: "OnRequest" });
  const getStageAxios = useAxios<IStage>({ loading: "OnRequest" });
  const updateStageAxios = useAxios<IStage>({ loading: "OnRequest" });
  const getStageByWorkflowAxios = useAxios<IStage[]>({ loading: "OnRequest" });

  const [updateLoaded, setUpdateLoaded] = useState<boolean>(false);

  const jobAssignOptions = [
    {
      title: "Giữ nguyên người nhận việc ở giai đoạn trước",
      value: EWorkflowProcess.Continue.toString()
    },
    {
      title: "Giao về cho người nhận nhiệm vụ đầu tiên",
      value: EWorkflowProcess.First.toString()
    },
    {
      title: "Không giao cho ai - Để người quản lí giai đoạn quyết định",
      value: EWorkflowProcess.Other.toString()
    },
    {
      title: "Để người chuyển giai đoạn quyết định",
      value: EWorkflowProcess.TaskAssigneeDecides.toString()
    },
    {
      title: "Chọn 1 người ngẫu nhiên nhận nhiệm vụ",
      value:
        EWorkflowProcess.Randomly_Select_One_Person_To_Take_On_The_Task.toString()
    },
    {
      title: "Giao lại cho người thực thi ở 1 giai đoạn phía trước",
      value: EWorkflowProcess.SelectStep.toString()
    }
  ];

  const validationSchema = Yup.object().shape({
    stageName: Yup.string().trim().required("Vui lòng nhập tên giai đoạn"),
    jobAssign: Yup.string().required("Vui lòng chọn cách công việc được giao"),
    stageReAssignTaskId: Yup.mixed<string>()
      .nullable()
      .when("jobAssign", {
        is: EWorkflowProcess.SelectStep.toString(),
        then: schema => schema.required("Vui lòng chọn giai đoạn (bắt buộc)"),
        otherwise: schema => schema.nullable()
      }),
    expectedDuration: Yup.string()
      .nullable()
      .test(
        "is-decimal",
        "Vui lòng nhập đúng định dạng vd: 12:30",
        (value, _) => {
          if (!value) return true;
          return /^(\d+:\d{2}|\d+)$/.test(value.toString());
        }
      )
  }) as any;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (open === true) {
      reset();
      setUpdateLoaded(false);
      setValue("workflowName", workflow.name);
      if (isUpdate) {
        handleGetStageInfo();
      }
    }
    return () => {
      createStageAxios.reset();
      getStageAxios.reset();
      updateStageAxios.reset();
      getStageByWorkflowAxios.reset();
    };
  }, [open]);

  useEffect(() => {
    if (getValues("jobAssign") === "4" && !getStageByWorkflowAxios.data) {
      getStageByWorkflowAxios.request(
        StageService.getListStageByWorkflowId(workflow.id)
      );
    }

    if (getValues("jobAssign") !== EWorkflowProcess.SelectStep.toString()) {
      getStageByWorkflowAxios.reset();
    }
  }, [getValues("jobAssign")]);

  useEffect(() => {
    if (createStageAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: createStageAxios.message ?? "Tạo giai đoạn thành công"
      });
      handleSuccess(createStageAxios.data!);
      handleClose();
    } else if (createStageAxios.error) {
      toggleMessage({
        type: "error",
        message: createStageAxios.error?.message ?? ""
      });
    }
  }, [createStageAxios.error, createStageAxios.isSuccess]);

  useEffect(() => {
    if (getStageAxios.error) {
      toggleMessage({
        type: "error",
        message: getStageAxios.error?.message ?? ""
      });
      handleClose();
    }
    if (getStageAxios.isSuccess && getStageAxios.data) {
      const stageData = getStageAxios.data;

      setValue("color", stageData?.color ?? "#F1F5F6");
      setValue("stageName", stageData.name);
      setValue(
        "stageOwners",
        stageData.stageManagements.map(e => ({
          id: e.id,
          username: e.userName,
          fullName: e.fullName,
          type: e.type
        }))
      );
      setValue(
        "stageDefaultFolowers",
        stageData.stageTrackers.map(e => ({
          id: e.id,
          username: e.userName,
          fullName: e.fullName,
          type: e.type
        }))
      );
      setValue(
        "stageWorkers",
        stageData.stageExecutors.map(e => ({
          id: e.id,
          username: e.userName,
          fullName: e.fullName,
          type: e.type
        }))
      );
      setValue("jobAssign", stageData.reassignedWork.toString());
      setValue("expectedDuration", stageData.expectedTime ?? "");
      setValue("stageReAssignTaskId", stageData.stageReAssignTaskId);
      // setValue("guideline", stageData.guideline);
      setUpdateLoaded(true);
    }
  }, [getStageAxios.error, getStageAxios.isSuccess]);

  useEffect(() => {
    if (updateStageAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateStageAxios.message ?? "Cập nhật thành công!"
      });
      handleSuccess(updateStageAxios.data!);
      handleClose();
    } else if (updateStageAxios.error) {
      toggleMessage({
        type: "error",
        message: updateStageAxios.error?.message ?? ""
      });
    }
  }, [updateStageAxios.error, updateStageAxios.isSuccess]);

  const onSubmit = useCallback(
    (data: DataForm) => {
      const request: IStageRequest = {
        Name: data.stageName,
        boardId: workflow.id,
        ExpectedTime: data.expectedDuration
          ? data.expectedDuration.toString()
          : undefined,
        StageManagements: data.stageOwners,
        StageTrackers: data.stageDefaultFolowers,
        StageExecutors: data.stageWorkers,
        ReassignedWork: parseInt(data.jobAssign),
        StageReAssignTaskId:
          data.jobAssign === EWorkflowProcess.SelectStep.toString()
            ? data.stageReAssignTaskId
            : undefined,
        Color: data.color
        // Guideline: data.guideline
      };
      console.log(request);

      if (stageSelected) {
        request.PositonSelected = stageSelected.position;
        request.Side = side;
      }

      if (isUpdate) {
        updateStageAxios.request(
          StageService.updateStage(stageSelected!.id, request)
        );
      } else {
        createStageAxios.request(StageService.createStage(request));
      }
    },
    [workflow, stageSelected, side, isUpdate]
  );

  const handleGetStageInfo = () => {
    if (stageSelected?.id) {
      getStageAxios.request(StageService.getStageById(stageSelected.id));
    }
  };

  return (
    <Modal
      title={isUpdate ? "Chỉnh sửa giai đoạn" : "Thêm giai đoạn mới"}
      textSubmit={isUpdate ? "Cập nhật" : "Tạo giai đoạn"}
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={
        createStageAxios.isLoading ||
        getStageAxios.isLoading ||
        updateStageAxios.isLoading
      }
      disabledSubmit={
        createStageAxios.isLoading ||
        getStageAxios.isLoading ||
        updateStageAxios.isLoading
      }
      onClose={
        !createStageAxios.isLoading && !updateStageAxios.isLoading
          ? handleClose
          : undefined
      }
      width="580px"
    >
      {isUpdate && getStageAxios.isLoading && (
        <center>
          <CircularProgress />
        </center>
      )}
      {(!isUpdate || (isUpdate && getStageAxios.isSuccess && updateLoaded)) && (
        <Wrapper
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px"
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ControlForm
            title="Tên luồng công việc"
            classname="brand-form"
            children={
              <InputForm
                placeholder="Tên luồng công việc"
                disabled
                name="workflowName"
                errors={errors}
                control={control}
                required
                size="small"
              />
            }
          />

          <ControlForm title="Tên giai đoạn" classname="brand-form" isRequired>
            <InputForm
              placeholder="Tên giai đoạn"
              required
              name="stageName"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>

          <ControlForm title="Màu" classname="brand-form">
            <Controller
              name="color"
              control={control}
              render={({ field }) => {
                return (
                  <ToggleButtonGroup
                    {...field}
                    onChange={(
                      event: React.MouseEvent<HTMLElement>,
                      value: string
                    ) => {
                      setValue(field.name, value);
                    }}
                    color="info"
                    exclusive
                  >
                    {Object.values(EStageColumnColor).map((value, inx) => (
                      <ToggleButton
                        value={value}
                        key={inx}
                        sx={{
                          // width: "24px",
                          backgroundColor: value,
                          border: `2px solid ${field.value === value ? "#3091F3" : "white"}`,
                          marginRight: "8px",
                          "&:hover": {
                            border: `2px solid #c1c1c1`,
                            backgroundColor: value
                          },
                          "&:focus": {
                            border: `2px solid #3091F3`,
                            backgroundColor: value
                          },
                          "&.Mui-selected": {
                            border: `2px solid #3091F3`,
                            backgroundColor: value,
                            "&:hover": {
                              backgroundColor: value
                            }
                          }
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "transparent",
                            width: "32px",
                            height: "20px"
                          }}
                        />
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                );
              }}
            />
            {/* <Box display="flex">
              {Object.values(EStageColumnColor).map((value, inx) => (
                <Box
                  key={inx}
                  onClick={() => setColor(value)}
                  sx={{
                    backgroundColor: value,
                    width: "40px",
                    height: "24px",
                    mariginRight: "8px",
                    border: `2px solid ${color === value ? "#3091F3" : "white"}`,
                    borderRadius: "4px"
                  }}
                /> */}
            {/* ))} */}
            {/* </Box> */}
          </ControlForm>

          <ControlForm title="Người quản trị giai đoạn" classname="brand-form">
            <TagInput
              name={"stageOwners"}
              store="workflow"
              storeId={workflow.id}
              control={control}
              errors={errors}
              isError={!!errors.stageOwners}
              placeholder={"Sử dụng @ để tag thành viên quản trị"}
            />
          </ControlForm>

          <ControlForm
            title="Người theo dõi mặc định của giai đoạn"
            classname="brand-form"
          >
            <TagInput
              name={"stageDefaultFolowers"}
              store="workflow"
              storeId={workflow.id}
              control={control}
              errors={errors}
              isError={!!errors.stageDefaultFolowers}
              placeholder={"Dùng @ để tag người theo dõi mặc định"}
            />
          </ControlForm>

          <ControlForm title="Người thực thi giai đoạn" classname="brand-form">
            <TagInput
              name={"stageWorkers"}
              store="workflow"
              storeId={workflow.id}
              control={control}
              errors={errors}
              isError={!!errors.stageDefaultFolowers}
              placeholder={"Sử dụng @ để tag thành viên thực hiện công việc"}
            />
          </ControlForm>

          <ControlForm
            title="Công việc được giao/giao lại như thế nào?"
            classname="brand-form"
          >
            <Controller
              control={control}
              name="jobAssign"
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    size="small"
                    displayEmpty
                    value={field.value || ""}
                    labelId="simple-select-label"
                    id="jobAssign-select"
                    IconComponent={ExpandLessIcon}
                    onChange={value => {
                      field.onChange(value);
                      trigger("stageReAssignTaskId");
                    }}
                    sx={{
                      "& .MuiSelect-icon": {
                        fontSize: 24
                      },
                      fontSize: 13
                    }}
                  >
                    {jobAssignOptions.map((option, index) => (
                      <MenuItem
                        sx={{ fontSize: 13 }}
                        value={option.value}
                        key={index}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors.jobAssign && (
                    <FormHelperText sx={{ color: "#ED3E47" }}>
                      {errors.jobAssign.message ?? ""}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </ControlForm>
          {getStageByWorkflowAxios.data && (
            <ControlForm
              title="Công việc được giao cho người thực hiện giai đoạn nào?"
              classname="brand-form"
            >
              <Controller
                control={control}
                name="stageReAssignTaskId"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      onChange={value => {
                        field.onChange(value);
                        trigger("stageReAssignTaskId");
                      }}
                      size="small"
                      displayEmpty
                      value={field.value || ""}
                      labelId="simple-select-label"
                      id="stageReAssignTaskId-select"
                      IconComponent={ExpandLessIcon}
                      sx={{ fontSize: 13, marginTop: 1 }}
                    >
                      {getStageByWorkflowAxios.data?.map((option, index) => (
                        <MenuItem
                          sx={{ fontSize: 13 }}
                          value={option.id}
                          key={index}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.stageReAssignTaskId && (
                      <FormHelperText sx={{ color: "#ED3E47" }}>
                        {errors.stageReAssignTaskId.message ?? ""}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </ControlForm>
          )}

          <ControlForm
            title="Thời gian dự kiến"
            infor="số giờ : số phút"
            classname="brand-form"
          >
            <Box sx={{ display: "flex", gap: 1.5 }}>
              <Controller
                control={control}
                name={"expectedDuration"}
                render={({ field }) => (
                  <InputForm
                    {...field}
                    placeholder="Giờ:Phút"
                    endText="H"
                    errors={errors}
                    control={control}
                    inputProps={{
                      inputMode: "text",
                      pattern: "[0-9]*:[0-9]*",
                      maxLength: 7
                    }}
                    type="text"
                    size="small"
                    onChange={value => {
                      const numericValue = value.replace(/[^0-9:]/g, "");
                      const [hours, minutes] = numericValue.split(":");

                      let formattedValue = "";
                      if (hours) {
                        formattedValue += hours.slice(0, 4);
                        if (minutes) {
                          const minutesValue = parseInt(minutes);
                          if (minutesValue > 59) {
                            formattedValue += ":59";
                          } else if (minutesValue >= 10) {
                            formattedValue += ":" + minutes.slice(0, 2);
                          } else {
                            formattedValue +=
                              ":" + minutes.slice(0, 2).padStart(2, "");
                          }
                        } else if (numericValue.includes(":")) {
                          formattedValue += ":";
                        }
                      }

                      field.onChange(formattedValue);
                    }}
                  />
                )}
              />
              {/* <Controller
                control={control}
                name={"expectedDurationRule"}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      size="small"
                      labelId="simple-select-label"
                      id="expectedDurationRule-select"
                      IconComponent={ExpandLessIcon}
                      sx={{ fontSize: 13, height: "100%", maxHeight: "40px" }}
                    >
                      {[
                        {
                          title: "Thời hạn không thể điều chỉnh",
                          value: "Thời hạn không thể điều chỉnh"
                        }
                      ].map((value, index) => (
                        <MenuItem
                          sx={{ fontSize: 13 }}
                          value={value.value}
                          key={index}
                        >
                          {value.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              /> */}
            </Box>
          </ControlForm>

          {/* <ControlForm title="Chèn vào" classname="brand-form">
            <Controller
              control={control}
              name={"insertAt"}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    labelId="simple-select-label"
                    id="insertAt-select"
                    IconComponent={ExpandLessIcon}
                    sx={{ fontSize: 13, height: "100%" }}
                    size="small"
                  >
                    {[
                      {
                        title: "Trước giai đoạn Done",
                        value: "Trước giai đoạn Done"
                      }
                    ].map((value, index) => (
                      <MenuItem
                        sx={{ fontSize: 13 }}
                        value={value.value}
                        key={index}
                      >
                        {value.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </ControlForm> */}

          <ControlForm
            title="Hướng dẫn hoàn thành các giai đoạn trong nhiệm vụ"
            classname="brand-form"
          >
            <Controller
              control={control}
              name={"guideline"}
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextEditorQuill
                    {...field}
                    placeholder="Giải thích cách hoàn thành giai đoạn"
                    toolbar={true}
                    editorStyle={{
                      border: "1px solid #D3DCDF",
                      borderRadius: "8px",
                      width: "100%",
                      height: "24vh"
                    }}
                  />
                </FormControl>
              )}
            />
          </ControlForm>
        </Wrapper>
      )}
    </Modal>
  );
};

export default CreateStageModal;
