import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Typography
} from "@mui/material";
import { ChangeEventHandler } from "react";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import FileImage from "./FileImage";
import TextEditorQuill from "../../../../../../components/TextEditorQuill";

type Props = {
  inputRef: any;
  value?: string;
  clear?: Function;
  onSubmit: Function;
  files?: File[] | null;
  onChange: (value: string) => void;
  isReply?: boolean;
  onDelete: Function;
  isLoading?: boolean;
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
};

const CommentInput = (props: Props) => {
  const {
    inputRef,
    value,
    onSubmit,
    files,
    onChange,
    handleFileChange,
    onDelete,
    isReply = false,
    isLoading
  } = props;

  const isQuillEmpty = (value: string) => {
    if (
      value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
      !value.includes("<img")
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box
      sx={{
        // border: "1px solid #DEDEDE",
        // borderRadius: "8px",
        margin: `10px 0 10px ${isReply ? 42 : 0}px`
        // padding: "1px"
      }}
    >
      {/* <TextField
        id="comment"
        size="small"
        multiline
        InputProps={{ disableUnderline: true }}
        variant="standard"
        // minRows={!isReply ? 2 : undefined}
        sx={{
          "& .MuiInputBase-root": {
            paddingLeft: 0,
            padding: 0,
            margin: 0
          }
        }}
        fullWidth
        placeholder="Viết thảo luận của bạn"
        onChange={onChange}
        value={value}
      /> */}

      <TextEditorQuill
        // {...field}
        placeholder="Nhập nội dung..."
        toolbar={true}
        editorStyle={{
          border: "1px solid #D3DCDF",
          borderRadius: "8px",
          width: "100%",
          minHeight: "80px"
        }}
        onChange={onChange}
        value={value ?? ""}
      />

      <Box display={"flex"} marginTop="8px">
        <Box display={"flex"} flexGrow={1}>
          <Typography variant="subtitle2" marginRight="12px">
            Tệp đính kèm:
          </Typography>
          <Box display={"flex"}>
            {files &&
              files.map((f, idx) => (
                <Chip
                  key={idx}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: "8px",
                    marginBottom: "6px",
                    borderRadius: "8px",
                    padding: "4px",
                    border: "1px solid #DEDEDE"
                  }}
                  label={f?.name}
                  onDelete={() => onDelete(f)}
                  avatar={<FileImage type={`.${f?.type.split("/")[1]}`} />}
                />
              ))}
          </Box>
          <Box textAlign="center">
            <input
              name="avatar"
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleFileChange}
              className="input-file-upload"
              hidden
            />
            <IconButton
              sx={{ width: "24px", height: "24px" }}
              onClick={() => inputRef.current.click()}
            >
              <AttachFileRoundedIcon sx={{ rotate: "45deg", fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
        {!isQuillEmpty(value ?? "") && (
          <Button
            variant="contained"
            size="small"
            color="info"
            sx={{ height: "32px", borderRadius: "8px" }}
            disabled={isLoading}
            onClick={() => onSubmit()}
            startIcon={
              isLoading && <CircularProgress size="18px" color="secondary" />
            }
          >
            {isLoading ? "Đang xử lý.." : "Gửi"}
          </Button>
        )}
      </Box>

      {/* <Box display="flex" margin="0 8px" alignItems="center">
        <Box flexGrow={1}>
          {files &&
            files.map((f, idx) => (
              <Chip
                key={idx}
                variant="outlined"
                size="small"
                sx={{
                  marginRight: "8px",
                  marginBottom: "6px",
                  borderRadius: "8px",
                  padding: "4px",
                  border: "1px solid #DEDEDE"
                }}
                label={f?.name}
                onDelete={() => onDelete(f)}
                avatar={<FileImage type={`.${f?.type.split("/")[1]}`} />}
              />
            ))}
        </Box>
        <Box textAlign="center">
          <input
            name="avatar"
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleFileChange}
            className="input-file-upload"
            hidden
          />
          <IconButton size="small" onClick={() => inputRef.current.click()}>
            <AttachFileRoundedIcon sx={{ rotate: "45deg", fontSize: 20 }} />
          </IconButton>
        </Box>
        <IconButton
          size="small"
          color="primary"
          disabled={isDisableAction}
          onClick={() => onSubmit()}
        >
          <SendRoundedIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Box> */}
    </Box>
  );
};

export default CommentInput;
