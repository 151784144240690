import vi from "date-fns/locale/vi";
import { useEffect, useMemo, useState } from "react";
import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import moment from "moment";
import { DATE_FORMAT } from "../../../config/constants";
import IconCalendar from "../../../assets/images/common/icon_calendar.svg";
import IconClose from "../../../assets/images/common/icon_close_line_black_background.svg";
import { ImageCustom } from "../../../components";
import { Box } from "@mui/material";
import { IBoardFilter } from "../../../models/Stage";

interface IProps {
  filter: IBoardFilter;
  onChange: (value: Range | undefined) => void;
}

/**
 * A component that provides a date range filter with a popup calendar interface.
 *
 * @component
 * @param {IProps} props - Component props
 * @param {Range} [props.SelectionRange] - The currently selected date range
 * @param {(range: Range | undefined) => void} props.onChange - Callback function triggered when date range selection changes
 *
 * @returns A button that opens a date range picker popup when clicked. The button displays either
 * the currently selected date range or a default "Tìm theo thời gian" text.
 * The popup contains a calendar interface for selecting dates and buttons to reset or confirm the selection.
 *
 * Features:
 * - Displays single date or date range in formatted string
 * - Allows resetting selection to undefined
 * - Provides visual feedback for selected state through button styling
 * - Supports Vietnamese locale
 * - Maintains temporary selection state until confirmed
 */
const DateTimeFilter = ({ filter, onChange }: IProps) => {
  const [currentRange, setCurrentRange] = useState<Range | undefined>(
    undefined
  );

  useEffect(() => {
    setCurrentRange(filter.Date);
  }, [filter]);

  const handleSelect = (ranges: RangeKeyDict) => {
    setCurrentRange(ranges.selection);
  };

  const isDefault = useMemo(() => {
    if (!filter.Date) return true;
    return false;
  }, [filter.Date]);

  const rangePreview = useMemo(() => {
    if (!filter.Date && isDefault) return "Tìm theo thời gian";
    if (
      filter.Date!.startDate?.toDateString() ===
      filter.Date!.endDate?.toDateString()
    )
      return moment(filter.Date!.startDate).format(DATE_FORMAT);
    return (
      "từ " +
      moment(filter.Date!.startDate).format(DATE_FORMAT) +
      " " +
      "đến " +
      moment(filter.Date!.endDate).format(DATE_FORMAT)
    );
  }, [isDefault, filter.Date]);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div className="inline-flex items-center">
          <div
            {...bindTrigger(popupState)}
            className="inline-flex items-center font-medium text-center focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            style={{
              padding: "8.5px 12px",
              inlineSize: "max-content",
              fontSize: "13px",
              borderRadius: "10px 0 0 10px",
              borderTop: "1px solid #d3dcdf",
              borderLeft: "1px solid #d3dcdf",
              borderBottom: "1px solid #d3dcdf",
              color:
                !filter.Date && isDefault && !currentRange
                  ? "#596970d8"
                  : "white",
              backgroundColor:
                !filter.Date && isDefault && !currentRange
                  ? "transparent"
                  : "#3091F3"
            }}
          >
            {rangePreview}
          </div>
          {!filter.Date && (
            <div
              {...bindTrigger(popupState)}
              style={{
                padding: "9px 12px",
                inlineSize: "max-content",
                fontSize: "13px",
                borderRadius: "0 10px 10px 0",
                borderTop: "1px solid #d3dcdf",
                borderRight: "1px solid #d3dcdf",
                borderBottom: "1px solid #d3dcdf",
                color:
                  !filter.Date && isDefault && !currentRange
                    ? "#596970d8"
                    : "white",
                backgroundColor:
                  !filter.Date && isDefault && !currentRange
                    ? "transparent"
                    : "#3091F3"
              }}
            >
              <ImageCustom src={IconCalendar} width="20px" height="20px" />
            </div>
          )}
          {filter.Date && (
            <div
              style={{
                padding: "9px 12px",
                inlineSize: "max-content",
                fontSize: "13px",
                borderRadius: "0 10px 10px 0",
                borderTop: "1px solid #d3dcdf",
                borderRight: "1px solid #d3dcdf",
                borderBottom: "1px solid #d3dcdf",
                color:
                  !filter.Date && isDefault && !currentRange
                    ? "#596970d8"
                    : "white",
                backgroundColor:
                  !filter.Date && isDefault && !currentRange
                    ? "transparent"
                    : "#3091F3"
              }}
            >
              <ImageCustom
                src={IconClose}
                onClick={() => {
                  onChange(undefined);
                }}
                width="20px"
                height="20px"
              />
            </div>
          )}

          <Popover
            {...bindPopover(popupState)}
            onClose={() => {
              bindPopover(popupState).onClose();
              setCurrentRange(filter.Date);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            sx={{
              marginTop: "4px",
              "& .rdrDateRangePickerWrapper": {
                display: "flex",
                flexDirection: "column-reverse",
                "& .rdrDefinedRangesWrapper": {
                  borderRight: "none",
                  width: "100%",
                  "& .rdrStaticRanges": {
                    display: "none"
                  },
                  "& .rdrInputRanges": {
                    display: "none"
                  }
                }
              }
            }}
          >
            <DateRangePicker
              editableDateInputs={false}
              direction={"horizontal"}
              startDatePlaceholder="Bắt đầu"
              endDatePlaceholder="Kết thúc"
              dateDisplayFormat="dd/MM/yyyy"
              footerContent={
                <div className="flex flex-row justify-end">
                  {currentRange && (
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          JSON.stringify(currentRange) !==
                          JSON.stringify(filter.Date)
                        )
                          onChange(currentRange);
                        popupState.close();
                      }}
                      className="inline-flex items-center font-medium text-center text-white bg-green-400 rounded-lg focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                      style={{
                        fontSize: 14,
                        padding: "4px 12px",
                        margin: "8px"
                      }}
                    >
                      Xác nhận
                    </button>
                  )}
                </div>
              }
              ranges={[
                {
                  startDate: currentRange?.startDate ?? new Date(),
                  endDate: currentRange?.endDate ?? new Date(),
                  key: "selection"
                }
              ]}
              onChange={handleSelect}
              locale={vi}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
export default DateTimeFilter;
