import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import { ITag } from "../../../models/user";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { IBoardFilter } from "../../../models/Stage";

interface DataForm {
  assigned: ITag[];
}
/**
 * A filter component for tracking assigned users in a workflow
 *
 * @component
 * @param {IProps} props - Component props
 * @param {string} props.workflowId - ID of the workflow to filter
 * @param {ITag[]} props.value - Current filter value
 * @param {(value: ITag[]) => void} props.setValue - Callback to update filter value
 *
 * @example
 * ```tsx
 * <TrackerFilter
 *   workflowId="123"
 *   value={currentValue}
 *   setValue={handleValueChange}
 * />
 * ```
 *
 * @returns A form containing a TagInput component for filtering assigned users
 */

interface IProps {
  workflowId: string;
  filter: IBoardFilter;
  setValue: (newValue: ITag[]) => void;
}

const TrackerFilter = ({ workflowId, filter, setValue }: IProps) => {
  const validationSchema = Yup.object().shape({}) as any;
  const {
    control,
    getValues,
    trigger,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    console.log(getValues("assigned"));
    setValue(getValues("assigned"));
  }, [getValues("assigned")]);

  return (
    <form style={{ minWidth: "220px" }}>
      <TagInput
        name={"assigned"}
        store={"workflow"}
        storeId={workflowId}
        trigger={trigger}
        errors={errors}
        defaultValues={filter.PersonAssigned ?? []}
        control={control}
        isError={false}
        placeholder={"Tìm kiếm người nhận nhiệm vụ"}
      />
    </form>
  );
};

export default TrackerFilter;
