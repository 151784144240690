import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import { useEffect, useMemo, useRef, useState } from "react";
import invariant from "tiny-invariant";

import { unsafeOverflowAutoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/unsafe-overflow/element";
import { bindAll } from "bind-event-listener";

import { CleanupFn } from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import {
  isCardData,
  isCardDropTargetData,
  isColumnData,
  isDraggingACard,
  isDraggingAColumn,
  TBoard
} from "./data";
import { IStage } from "../../../../../models/Stage";
import { Column } from "../StageColumn/column";
import { EStageType } from "../../../../../models/common/models.enum";
import { useBoardContext } from "./board_context";
import { ITaskCard } from "../../../../../models/Task";

/**
 * Board component for managing draggable columns and task cards in a project board.
 *
 * @component
 * @param {IProps} props - Component props
 * @param {TBoard} props.initial - Initial board data containing columns and task cards
 * @param {Function} props.setColumns - Callback function to update columns state
 *
 * @remarks
 * This component handles:
 * - Drag and drop functionality for tasks between columns
 * - Drag and drop functionality for reordering columns
 * - Auto-scrolling when dragging near edges
 * - Horizontal panning of the board
 * - Synchronization with initial board data
 * - Position updates for tasks and columns
 *
 * The component uses several monitor functions to handle different drag scenarios:
 * - Card dragging between and within columns
 * - Column reordering
 * - Auto-scrolling during drag operations
 *
 * State updates trigger appropriate callback handlers to persist changes.
 *
 * @returns {JSX.Element} A horizontally scrollable board with draggable columns and task cards
 */

interface IProps {
  initial: TBoard;
  setColumns: (columns: IStage[]) => void;
}

export function Board({ initial, setColumns }: IProps): JSX.Element {
  const [data, setData] = useState(initial);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const { getRoleBoard, handleUpdateTaskPosition, onUpdateColumnPosition } =
    useBoardContext();

  const role = useMemo(() => getRoleBoard(), [getRoleBoard]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(initial)) {
      setData(initial);
    }
  }, [initial]);

  useEffect(() => {
    const element = scrollableRef.current;
    invariant(element);
    return combine(
      monitorForElements({
        canMonitor: isDraggingACard,
        onDrop({ source, location }) {
          const dragging = source.data;

          if (!isCardData(dragging)) {
            return;
          }

          const innerMost = location.current.dropTargets[0];

          if (!innerMost) {
            return;
          }
          const dropTargetData = innerMost.data;
          const homeColumnIndex = data.columns.findIndex(
            column => column.id === dragging.columnId
          );
          const home: IStage | undefined = data.columns[homeColumnIndex];

          if (!home) {
            return;
          }
          const cardIndexInHome = home.taskCards.findIndex(
            card => card.id === dragging.card.id
          );

          // dropping on a card
          if (isCardDropTargetData(dropTargetData)) {
            const destinationColumnIndex = data.columns.findIndex(
              column => column.id === dropTargetData.columnId
            );
            const destination = data.columns[destinationColumnIndex];
            // reordering in home column
            if (home === destination) {
              const cardFinishIndex = home.taskCards.findIndex(
                card => card.id === dropTargetData.card.id
              );

              // could not find cards needed
              if (cardIndexInHome === -1 || cardFinishIndex === -1) {
                return;
              }

              // no change needed
              if (cardIndexInHome === cardFinishIndex) {
                return;
              }

              const closestEdge = extractClosestEdge(dropTargetData);

              const reordered = reorderWithEdge<ITaskCard>({
                axis: "vertical",
                list: home.taskCards,
                startIndex: cardIndexInHome,
                indexOfTarget: cardFinishIndex,
                closestEdgeOfTarget: closestEdge
              });

              const taskCards = reordered.map((card, index) => {
                return { ...card, position: index };
              });

              const updated: IStage = {
                ...home,
                taskCards: taskCards
              };

              const columns = Array.from(data.columns);
              columns[homeColumnIndex] = updated;
              const newData = { ...data, columns };
              setData(newData);

              // Update Card Position
              handleUpdateTaskPosition({
                target: dragging.card,
                homeIndex: homeColumnIndex,
                destinationWorkflowProcess: destination.reassignedWork,
                destinationCardIndex: cardFinishIndex,
                destinationIndex: destinationColumnIndex,
                newBoard: newData.columns
              });
              return;
            }

            // moving card from one column to another

            // unable to find destination

            if (!destination) {
              return;
            }

            const indexOfTarget = destination.taskCards.findIndex(
              card => card.id === dropTargetData.card.id
            );

            const closestEdge = extractClosestEdge(dropTargetData);
            const finalIndex =
              closestEdge === "bottom" ? indexOfTarget + 1 : indexOfTarget;

            // remove card from home list
            const homeCards = Array.from(home.taskCards);
            homeCards.splice(cardIndexInHome, 1);

            // insert into destination list
            const destinationCards = Array.from(destination.taskCards);
            destinationCards.splice(finalIndex, 0, {
              ...dragging.card,
              workflowId: destination.id,
              position: finalIndex
            });

            const columns = Array.from(data.columns);
            columns[homeColumnIndex] = {
              ...home,
              taskCards: homeCards
            };
            columns[destinationColumnIndex] = {
              ...destination,
              taskCards: destinationCards,
              roleWorkflowByUser: destination.roleWorkflowByUser
            };

            const newData = { ...data, columns };
            setData(newData);

            // Update Card Position
            handleUpdateTaskPosition({
              target: dragging.card,
              homeIndex: homeColumnIndex,
              destinationWorkflowProcess: destination.reassignedWork,
              destinationCardIndex: finalIndex,
              destinationIndex: destinationColumnIndex,
              newBoard: newData.columns
            });
            return;
          }

          // dropping onto a column, but not onto a card
          if (isColumnData(dropTargetData)) {
            const destinationColumnIndex = data.columns.findIndex(
              column => column.id === dropTargetData.column.id
            );
            const destination = data.columns[destinationColumnIndex];

            if (!destination) {
              return;
            }

            // dropping on home
            if (home === destination) {
              // console.log("moving card to home column");

              // // move to last position
              // const reordered = reorder({
              //   list: home.taskCards,
              //   startIndex: cardIndexInHome,
              //   finishIndex: home.taskCards.length - 1
              // });

              // const updated: IStage = {
              //   ...home,
              //   taskCards: reordered
              // };
              // const columns = Array.from(data.columns);
              // columns[homeColumnIndex] = updated;

              // const newData = { ...data, columns };
              // setData(newData);
              //
              // setColumns(newData.columns);
              // console.log("what is this?");
              // // Update Card Position
              // onUpdateColumnPosition({
              //   homeId: home.id,
              //   destinationIndex: homeColumnIndex,
              //   newBoard: newData.columns
              // });
              return;
            }

            // remove card from home list

            const homeCards = Array.from(home.taskCards);
            homeCards.splice(cardIndexInHome, 1);

            // insert into destination list
            const destinationCards = Array.from(destination.taskCards);
            destinationCards.splice(destination.taskCards.length, 0, {
              ...dragging.card,
              workflowId: destination.id,
              position: destination.taskCards.length
            });

            const columns = Array.from(data.columns);
            columns[homeColumnIndex] = {
              ...home,
              taskCards: homeCards
            };
            columns[destinationColumnIndex] = {
              ...destination,
              taskCards: destinationCards,
              roleWorkflowByUser: destination.roleWorkflowByUser
            };

            const newData = { ...data, columns };
            setData(newData);

            // Update Card Position
            handleUpdateTaskPosition({
              target: dragging.card,
              homeIndex: homeColumnIndex,
              destinationWorkflowProcess: destination.reassignedWork,
              destinationCardIndex: destination.taskCards.length,
              destinationIndex: destinationColumnIndex,
              newBoard: newData.columns
            });
            return;
          }
        }
      }),
      monitorForElements({
        canMonitor: isDraggingAColumn,
        onDrop({ source, location }) {
          const dragging = source.data;
          if (!isColumnData(dragging)) {
            return;
          }

          const innerMost = location.current.dropTargets[0];

          if (!innerMost) {
            return;
          }
          const dropTargetData = innerMost.data;

          if (!isColumnData(dropTargetData)) {
            return;
          }

          const homeIndex = data.columns.findIndex(
            column => column.id === dragging.column.id
          );
          const destinationIndex = data.columns.findIndex(
            column => column.id === dropTargetData.column.id
          );

          if (homeIndex === -1 || destinationIndex === -1) {
            return;
          }

          if (homeIndex === destinationIndex) {
            return;
          }

          const dataColumns = data.columns.map((column, index) => {
            if (index === homeIndex) {
              return { ...column, position: destinationIndex };
            }
            if (index === destinationIndex) {
              return { ...column, position: homeIndex };
            }
            return column;
          });

          const reordered = reorder({
            list: dataColumns,
            startIndex: homeIndex,
            finishIndex: destinationIndex
          });

          const newData = { ...data, reordered };
          setData(newData);

          setColumns(newData.reordered);

          onUpdateColumnPosition({
            homeId: dragging.column.id,
            destinationIndex: destinationIndex + 1,
            newBoard: newData.reordered
          });
        }
      }),
      autoScrollForElements({
        canScroll({ source }) {
          return isDraggingACard({ source }) || isDraggingAColumn({ source });
        },
        getConfiguration: () => ({ maxScrollSpeed: "fast" }),
        element
      }),
      unsafeOverflowAutoScrollForElements({
        element,
        getConfiguration: () => ({ maxScrollSpeed: "fast" }),
        canScroll({ source }) {
          return isDraggingACard({ source }) || isDraggingAColumn({ source });
        },
        getOverflow() {
          return {
            forLeftEdge: {
              top: 1000,
              left: 1000,
              bottom: 1000
            },
            forRightEdge: {
              top: 1000,
              right: 1000,
              bottom: 1000
            }
          };
        }
      })
    );
  }, [data]);

  // Panning the board
  useEffect(() => {
    let cleanupActive: CleanupFn | null = null;
    const scrollable = scrollableRef.current;
    invariant(scrollable);

    function begin({ startX }: { startX: number }) {
      let lastX = startX;

      const cleanupEvents: CleanupFn = bindAll(
        window,
        [
          {
            type: "pointermove",
            listener(event) {
              const currentX = event.clientX;
              const diffX = lastX - currentX;

              lastX = currentX;
              scrollable?.scrollBy({ left: diffX });
            }
          },
          // stop panning if we see any of these events
          ...(
            [
              "pointercancel",
              "pointerup",
              "pointerdown",
              "keydown",
              "resize",
              "click",
              "visibilitychange"
            ] as const
          ).map(eventName => ({
            type: eventName,
            listener: () => cleanupEvents()
          }))
        ],
        // need to make sure we are not after the "pointerdown" on the scrollable
        // Also this is helpful to make sure we always hear about events from this point
        { capture: true }
      );

      cleanupActive = cleanupEvents;
    }

    const cleanupStart: CleanupFn = bindAll(scrollable, [
      {
        type: "pointerdown",
        listener(event) {
          if (!(event.target instanceof HTMLElement)) {
            return;
          }
          if (event.target.closest("data-block-board-panning")) {
            return;
          }

          begin({ startX: event.clientX });
        }
      }
    ]);

    return function cleanupAll() {
      cleanupStart();
      cleanupActive?.();
    };
  }, []);

  return (
    <div
      className={`flex  flex-col pt-1`}
      style={{
        height: "calc(100vh - 188px)"
      }}
    >
      <div
        className={`flex h-full flex-row overflow-x-auto [scrollbar-color:theme(colors.sky.600)_theme(colors.sky.800)] [scrollbar-width:thin]`}
        ref={scrollableRef}
      >
        {data.columns.map((column, index) => (
          <Column
            key={column.id}
            name={column.name}
            workflowId={`${role.boardId}`}
            column={column}
            isShowMenu={column.type === EStageType.Step}
            firstStage={index === 0}
          />
        ))}
      </div>
    </div>
  );
}
