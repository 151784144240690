import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  PlanPopoverWrapper,
  SidebarGroupPanelWrapper,
  SidebarWrapper
} from "./styles";
import { ImageCustom, PlanBox } from "..";
import AvatarImage from "../../assets/images/temp/temp_avatar.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Tooltip,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ArrowLeft from "../../assets/images/common/icon_arrow_left_line_black.svg";
import ExpandIcon from "../../assets/images/common/map_arrow_right.svg";
import { sidebarItems } from "../../routes";
import { authTokens } from "../../services/services";
import RoleService from "../../services/api/role.service";
import { IUser } from "../../models/user";
import { IFeaturePermission } from "../../models/user/models.roles";
import { useApi } from "../../hooks/useApi.hook";
import useAxios from "../UseAxios/useAxios";
import WorkflowService from "../../services/api/workflow.service";
import { IWorkflowByTypeResponse } from "../../models/workflow";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import useNavigateSearch from "../NavigateSearch";
import HomeIcon from "../../assets/images/sidebar/icon_myproject_line_grey.svg";
import HomeIconActive from "../../assets/images/sidebar/icon_myproject_active.svg";
import { EAlphabetColor, enumFromValue } from "../../models/common/models.enum";
import { AddRounded, ArrowDropDownRounded } from "@mui/icons-material";
import CreateWorkflowDialog from "../../pages/WorkflowManagement/components/CreateWorkflowDialog/create_workflow_dialog";

type Props = {
  onMobileClose: () => void;
  openMobile: boolean | undefined;
};

const DashboardSidebar = ({ onMobileClose, openMobile }: Props) => {
  const location = useLocation();
  const [notExpanded, setNotExpanded] = useState<boolean>(false);
  // const [openCreatePJ, setOpenCreatePJ] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [anchorElProject, setAnchorElProject] =
  //   useState<HTMLButtonElement | null>(null);
  // const [isHasPJ, setIsHasPJ] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [sideBarExpanded, setSideBarExpanded] = useState<boolean>(false);
  const [openWorkflowForm, setOpenWorkflowForm] = useState(false);

  const role = useApi<IFeaturePermission[]>({ isFetch: true });
  const projects = useAxios<IWorkflowByTypeResponse[]>({
    loading: "OnRequest"
  });

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
    projects.request(WorkflowService.getAllWorkflows());
  }, []);

  useEffect(() => {
    if (userInfo) {
      role.request(RoleService.getFeaturePemissionByUserId(userInfo.id));
    }
  }, [userInfo]);

  const sidebarData = sidebarItems(role.data, projects.data).find(
    s => s.code === "WORK"
  );

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(`${"Please enter username"}`),
    password: Yup.string().required(`${"Please enter password"}`)
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      username: null,
      password: null
    },
    resolver: yupResolver(validationSchema)
  });

  const projectData: {
    name: string;
    domain: string;
    href: string;
    avatar: string;
  }[] = [
    {
      name: "Project Name fix-one -line fix-one-line fix-one-line fix-one-line fix-one-line",
      domain:
        "mikotech.vn fix-one -line fix-one-linef ix-one-linef ix-one-li nefix-one-line",
      href: "/",
      avatar: AvatarImage
    },
    {
      name: "Project Name",
      domain: "mikotech.vn",
      href: "/",
      avatar: AvatarImage
    },
    {
      name: "Project Name",
      domain: "mikotech.vn",
      href: "/",
      avatar: AvatarImage
    }
  ];

  const navigateSearch = useNavigateSearch();

  const handleCloseCreateWorkflow = () => {
    setOpenWorkflowForm(false);
  };

  const handleCreateWorkflowSuccess = () => {
    // reload();
    // setTabIndex(2);
    window.location.reload();
  };

  return (
    <>
      <PlanPopoverWrapper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <PlanBox />
      </PlanPopoverWrapper>

      {/* <ProjectPopoverWrapper
        open={Boolean(anchorElProject)}
        anchorEl={anchorElProject}
        onClose={() => setAnchorElProject(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {projectData.length > 0 && (
          <>
            <ul className="project-list">
              {projectData.map(pj => (
                <li key={pj.domain}>
                  <Link to={pj.href}>
                    <div className="infor-box">
                      <Avatar
                        src={pj.avatar}
                        alt="Avatar-Image"
                        width={"36px"}
                        height={"36px"}
                        className="project-avatar"
                      />
                      <div className="project-name">
                        <p className="name-project fit-one-line">{pj.name}</p>
                        <p className="name-domain fit-one-line">{pj.domain}</p>
                      </div>
                    </div>
                    <ImageCustom
                      src={IconCheck}
                      alt="Icon-Check"
                      width={"24px"}
                      height={"24px"}
                      className="icon-check"
                    />
                  </Link>
                </li>
              ))}
            </ul>
            <Divider />
          </>
        )}
        <Button
          size="xsmall"
          variant="contained"
          className="create-button"
          onClick={_ => setOpenCreatePJ(true)}
        >
          <ImageCustom
            src={IconPlus}
            alt="Plus-Icon"
            width={"18px"}
            height={"18px"}
          />
          <p>Tạo dự án mới</p>
        </Button>
      </ProjectPopoverWrapper> */}

      {/* <Modal
        title="Create new Project"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
        open={openCreatePJ}
        onSubmit={() => {}}
        onClose={() => setOpenCreatePJ(false)}
      >
        <form style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <ControlForm
            title="Name"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            classname="brand-form"
          >
            <InputForm
              placeholder="Your project name "
              required
              name="username"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title="Your Domain"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            classname="brand-form"
          >
            <InputForm
              placeholder="Your Project Domain"
              required
              name="username"
              errors={errors}
              control={control}
              size="small"
              startIcon={IconLink}
              startText="https://"
            />
          </ControlForm>
        </form>
      </Modal> */}

      <SidebarWrapper notexpanded={notExpanded}>
        <Button
          className={`expanded-button ${notExpanded ? "notExpanded" : ""}`}
          size="large"
          variant="text"
          onClick={() => setNotExpanded(!notExpanded)}
        >
          <ImageCustom
            src={ArrowLeft}
            alt="Arrow-Left"
            width={"24px"}
            height={"24px"}
          />
        </Button>
        <div className="sidebar-box py-4 ">
          <Link
            to={{
              pathname: sidebarData?.children && sidebarData?.children[0].href
            }}
          >
            <div
              style={{
                display: "flex",
                // padding: "8px 12px",
                padding: "8px",
                // backgroundColor: "white",
                // width: "100%",
                borderRadius: "8px",
                marginLeft: "10px",
                marginBottom: "0px",
                boxShadow: window.location.pathname.includes(
                  ROUTE_PATH.WORKFLOWS_MANAGEMENT
                )
                  ? "0px 0px 12px 4px #0000000A"
                  : undefined,
                backgroundColor: window.location.pathname.includes(
                  ROUTE_PATH.WORKFLOWS_MANAGEMENT
                )
                  ? "white"
                  : undefined
              }}
            >
              <ImageCustom
                src={
                  window.location.pathname.includes(
                    ROUTE_PATH.WORKFLOWS_MANAGEMENT
                  )
                    ? HomeIconActive
                    : HomeIcon
                }
                width="20px"
                height="20px"
              />
              {!notExpanded && (
                <Typography
                  variant="subtitle2"
                  marginLeft="10px"
                  sx={{
                    ":hover": {
                      color: "#3091F3"
                    }
                  }}
                >
                  Trang chủ
                </Typography>
              )}
            </div>
          </Link>
          {/* <div className="project-infor-wrapper">
            {isHasPJ ? (
              <Button
                className={`project-infor ${notExpanded ? "notExpanded" : ""} ${!!anchorElProject ? "active" : ""}`}
                size="large"
                variant="text"
                onClick={event => setAnchorElProject(event.currentTarget)}
              >
                <div className="infor-box">
                  <Avatar
                    src={AvatarImage}
                    alt="Avatar-Image"
                    width={"36px"}
                    height={"36px"}
                    className="project-avatar"
                  />
                  <div className="project-name">
                    <p className="name-project fit-one-line">
                      Project Name fix-one -line fix-one-line fix-one-line
                      fix-one-line fix-one-line
                    </p>
                    <p className="name-domain fit-one-line">
                      mikotech.vn Project Name fix-one -line fix-one-line
                      fix-one-line fix-one-line fix-one-line
                    </p>
                  </div>
                </div>
                <ImageCustom
                  src={ArrowLeft}
                  alt="Arrow-Left"
                  width={"24px"}
                  height={"24px"}
                  className="arrow-right"
                />
              </Button>
            ) : (
              <></>
              // <Button
              //   size="xsmall"
              //   variant="contained"
              //   className={`create-project-button ${notExpanded ? "notExpanded" : ""}`}
              //   onClick={_ => setOpenCreatePJ(true)}
              // >
              //   <ImageCustom
              //     src={IconPlus}
              //     alt="Plus-Icon"
              //     width={"18px"}
              //     height={"18px"}
              //   />
              //   <p>Tạo dự án mới</p>
              // </Button>
            )}
          </div> */}

          {/* <Accordion
            defaultExpanded
            disableGutters
            sx={{
              backgroundColor: "transparent",
              "& .Mui-expanded": { margin: 0, padding: 0 },
              "& .MuiAccordionSummary-root": {
                padding: 0,
                margin: 0,
                flexDirection: "row-reverse",
                "& MuiAccordionSummary-content": {
                  margin: 0
                }
              },
              "& .MuiAccordionDetails-root": {
                padding: 0,
                "& .workflow-item": {
                  minWidth: 280,
                  height: 184
                }
              },
              "& .group-panel-title": {
                alignSelf: "center",
                fontWeight: 600,
                paddingLeft: ".5em",
                color: "#596970"
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                <Box sx={{ margin: "0 12px" }}>
                  <ImageCustom src={ExpandIcon} height="14px" width="14px" />
                </Box>
              }
              aria-controls="panel1-content"
            >
              <Box display={"flex"} flexDirection={"row"} gap="8px">
                <Typography variant="subtitle2">Project 1</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion> */}

          <Button
            variant="contained"
            fullWidth
            size="small"
            sx={{
              margin: "12px",
              width: notExpanded ? "1px" : "210px",
              padding: 0
            }}
            startIcon={notExpanded && <AddRounded />}
            onClick={() => setOpenWorkflowForm(true)}
          >
            {!notExpanded && `Tạo Workflow`}
          </Button>

          <CreateWorkflowDialog
            open={openWorkflowForm}
            handleClose={handleCloseCreateWorkflow}
            handleSuccess={handleCreateWorkflowSuccess}
          />

          <SidebarGroupPanelWrapper>
            <ul
              className={`sidebar-menu  ${notExpanded ? "notExpanded" : ""} scrollbar-small`}
            >
              {sidebarData?.children &&
                sidebarData.children[0].children?.map(sidebar => (
                  <Accordion key={sidebar.code} defaultExpanded>
                    {notExpanded ? (
                      <Divider sx={{ margin: "10px 0px" }} />
                    ) : (
                      <AccordionSummary
                        sx={{
                          backgroundColor: "rgba(126,150,159,0.1)",
                          height: "28px",
                          fontSize: "12px",
                          fontWeight: 700,
                          color: "#2D393E"
                        }}
                        expandIcon={
                          !notExpanded && (
                            <ArrowDropDownRounded
                              sx={{ fontSize: "28px", color: "black" }}
                            />
                          )
                        }
                      >
                        <p
                          className={`sidebar-title ${notExpanded ? "notExpanded" : ""}`}
                        >
                          {!notExpanded && sidebar.title}
                        </p>
                      </AccordionSummary>
                    )}
                    <AccordionDetails>
                      <ul className="sidebar-children">
                        {sidebar.children &&
                          sidebar.children.map((child, idx) => {
                            let isActive =
                              sidebar.code === "project"
                                ? location.search.includes(child.code!)
                                : location.pathname
                                    .split("/")
                                    .splice(1)
                                    .includes(child.code!.replace("/", ""));
                            // if (child.code === "project") {
                            //   return (
                            //     <DashboardSidebarCollapse
                            //       parrent={sidebar}
                            //       item={child}
                            //       isNavNotExpanded={notExpanded}
                            //     />
                            //   );
                            // }
                            return (
                              <li
                                key={idx}
                                className={`children-item ${isActive ? "active" : ""}`}
                              >
                                <Tooltip
                                  title={!notExpanded ? "" : child?.title}
                                  placement="right"
                                >
                                  <Button
                                    onClick={() =>
                                      navigateSearch(child.href || "/", {
                                        id: child.search!
                                      })
                                    }
                                    startIcon={
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          backgroundColor: enumFromValue(
                                            child?.title
                                              .split(" ")
                                              .splice(0, 1)
                                              .map(e =>
                                                e.charAt(0).toUpperCase()
                                              ),
                                            EAlphabetColor
                                          ),
                                          width: "26px",
                                          height: "26px",
                                          borderRadius: "8px",
                                          alignContent: "center",
                                          fontSize: "10px"
                                        }}
                                      >
                                        {child?.title
                                          .split(" ")
                                          .splice(0, 2)
                                          .map(e => e.charAt(0))
                                          .join("")}
                                      </Typography>
                                    }
                                  >
                                    <p
                                      className={`${notExpanded ? "notExpanded" : ""} `}
                                    >
                                      {notExpanded ? "" : child?.title}
                                    </p>
                                  </Button>

                                  {/* <Link
                                to={{
                                  pathname: child.href || "/",
                                  search: child.search
                                }}
                                
                              >
                                <div className="icon-box">
                                  <ImageCustom
                                    src={child?.icon?.default}
                                    alt="Sidebar-Icon"
                                    width={"20px"}
                                    height={"20px"}
                                    className={`box-default ${isActive ? "active" : ""}`}
                                  />
                                  <ImageCustom
                                    src={child?.icon?.active}
                                    alt="Sidebar-Icon"
                                    width={"20px"}
                                    height={"20px"}
                                    className={`box-active ${isActive ? "active" : ""}`}
                                  />
                                </div>
                                <p
                                  className={`${notExpanded ? "notExpanded" : ""} `}
                                  style={{ color: "#596970" }}
                                >
                                  {child?.title}
                                </p>
                                {child.children && !notExpanded && (
                                  <Button
                                    onClick={() =>
                                      setSideBarExpanded(!sideBarExpanded)
                                    }
                                  >
                                    {sideBarExpanded ? (
                                      <KeyboardArrowUpIcon
                                        sx={{
                                          color: isActive ? "black" : "white",
                                          fontSize: 24
                                        }}
                                      />
                                    ) : (
                                      <KeyboardArrowDownRoundedIcon
                                        sx={{
                                          color: isActive ? "black" : "white",
                                          fontSize: 24
                                        }}
                                      />
                                    )}
                                  </Button>
                                )}
                              </Link> */}
                                </Tooltip>

                                {/* {child.children && !notExpanded && (
                            <Collapse in={sideBarExpanded}>
                              <DashboardSidebarChildren item={child.children} />
                            </Collapse>
                          )} */}
                              </li>
                            );
                          })}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </ul>
          </SidebarGroupPanelWrapper>

          {/* <div
            className={`plan-box-wrapper ${notExpanded ? "notExpanded" : ""}`}
          >
            <PlanBox className="plan-box" />
            <Button
              size="xsmall"
              variant="contained"
              className="not-expanded-button"
              onClick={event => setAnchorEl(event.currentTarget)}
              color="secondary"
            >
              <ImageCustom
                src={IconPlan}
                alt="Plan-Icon"
                width={"18px"}
                height={"18px"}
              />
            </Button>
          </div> */}
        </div>
      </SidebarWrapper>
    </>
  );
};

export default DashboardSidebar;
