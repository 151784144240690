import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/auth/auth.duck";
import {
  ActionPopoverWrapper,
  NavbarWrapper,
  NotificationWrapper
} from "./styles";
import { Avatar, ImageCustom } from "..";
import {
  AppBar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Popover,
  Popper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import LogoImage from "../../assets/images/login/login_logo_img_line_green.png";
import NotificationIcon from "../../assets/images/common/icon_notification_active_grey.svg";
import ProfileIcon from "../../assets/images/common/icon_user_line_grey.svg";
import SettingIcon from "../../assets/images/common/icon_setting_line_grey.svg";
import SupportIcon from "../../assets/images/common/icon_question_line_grey.svg";
import LogoutIcon from "../../assets/images/common/icon_logout_line_red.svg";
import { useEffect, useRef, useState } from "react";
import { toggleConfirmModal } from "../ConfirmModal/ConfirmModal";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { authTokens } from "../../services/services";
import { IUser } from "../../models/user";
import { useApi } from "../../hooks/useApi.hook";
import {
  IGroupNotification,
  INotification,
  INotificationCard
} from "../../models/notification";
import NotificationService from "../../services/api/notification.service";
import { TIME_FORMAT } from "../../config/constants";
import moment from "moment";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import useNavigateSearch from "../NavigateSearch";
import { toggleMessage } from "../Toast/Toast";
import {
  eFireBaseComponent,
  EFireBaseType
} from "../../models/common/models.enum";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import variableStyles from "../../theme/variable-styles";
import Empty from "../../assets/images/sidebar/empty_notification.png";
import { theme } from "../../theme/theme";
import { sidebarItems } from "../../routes";
import { INavItem } from "../../models/app";
import { ArrowDropDownRounded } from "@mui/icons-material";
import RoleService from "../../services/api/role.service";
import { IFeaturePermission } from "../../models/user/models.roles";

type Props = {
  onMobileNavOpen: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const DashboardNavbar = ({ onMobileNavOpen }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [action, setAction] = useState<"ACCOUNT" | "NOTIFICATION" | null>(null);
  const [notifications, setNotifications] = useState<IGroupNotification[]>([]);
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [totalNotification, setTotalNotification] = useState<number | null>(
    null
  );
  const [isUpdate, setIsUpdate] = useState(false);
  const [openRules, setOpenRules] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const role = useApi<IFeaturePermission[]>({ isFetch: true });

  const getNotifications = useApi<INotificationCard>({
    isFetch: true,
    isPaging: true
  });
  const updateNotification = useApi<INotificationCard>({});
  const readAll = useApi<INotificationCard>({});

  const counter = useSelector((state: any) => state.users.notification);

  useEffect(() => {
    setTotalNotification((totalNotification ?? 0) + 1);
    setIsUpdate(true);
  }, [counter]);

  useEffect(() => {
    if (getNotifications.data) {
      let list = notifications.concat(
        getNotifications.data.groupNotification ?? []
      );
      const result = Array.from(new Set(list.map(list => list.created))).map(
        date => {
          return {
            created: date,
            notifications: list
              .filter(notification => notification.created === date)
              .reduce((total, value) => {
                return total.concat(value.notifications as []);
              }, [])
          };
        }
      );
      setNotifications(result);
      setTotalNotification(getNotifications.data?.totalNotSeen);
    }
  }, [getNotifications.data]);

  function fetchData() {
    getNotifications.request(
      NotificationService.getPage({
        CurrentPage: currentPage ?? 1,
        PageSize: 6
      })
    );
  }

  useEffect(() => {
    // if (!currentPage) {
    //   setNotifications([]);
    // }
    if (!isUpdate) {
      fetchData();
    }
  }, [currentPage]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  const onLogout = (event: any) => {
    event.preventDefault();
    const asyncFuncLogout: () => Promise<void> = async () => {
      await Promise.resolve(dispatch(logoutUser(navigate)));
    };

    toggleConfirmModal({
      title: `Bạn có muốn đăng xuất`,
      content: `Bạn có chắc chắc muốn đăng xuất khỏi tài khoản này?`,
      onSubmit: () => asyncFuncLogout(),
      submitText: "Đăng xuất",
      type: "warning-red"
    });
  };

  const menuDataProfile: { icon: string; text: string; link: string }[] = [
    { icon: ProfileIcon, text: "Tài khoản", link: ROUTE_PATH.ACCOUNT },
    { icon: SettingIcon, text: "Cài đặt", link: "/" },
    { icon: SupportIcon, text: "Hỗ trợ", link: "/" }
  ];

  const UserPopup = () => {
    return (
      <>
        <div className="profile-infor">
          <Avatar
            src={userInfo?.avatar}
            alt="Avatar-Image"
            width={"32px"}
            height={"32px"}
          />
          <div className="infor-detail">
            <p className="detail-name">{userInfo?.fullName}</p>
            <p className="detail-email">{userInfo?.email}</p>
          </div>
        </div>
        <Divider />
        <ul className="profile-actions">
          {menuDataProfile.map((action, i) => (
            <li key={action.text}>
              <Link to={action.link}>
                <ImageCustom
                  src={action.icon}
                  alt="HEADER_ACTION"
                  width={"18px"}
                  height={"18px"}
                />
                <p>{action.text}</p>
              </Link>
            </li>
          ))}
        </ul>
        <Divider />
        <Button
          size="large"
          variant="text"
          className="profile-logout"
          onClick={onLogout}
        >
          <ImageCustom
            src={LogoutIcon}
            alt="HEADER_LOGOUT"
            width={"20px"}
            height={"20px"}
          />
          <p>Đăng xuất</p>
        </Button>
      </>
    );
  };

  const NotificationPopup = () => {
    useEffect(() => {
      if (isUpdate) {
        setNotifications([]);
        fetchData();
        setIsUpdate(false);
      }
    }, []);

    const navigateSearch = useNavigateSearch();

    function checkPath(type: number, id?: string) {
      switch (type) {
        case EFireBaseType.AssignTask:
        case EFireBaseType.UpdateTask:
          if (id) {
            navigateSearch(ROUTE_PATH.TASK, {
              id: id
            });
          } else {
          }
          break;

        case EFireBaseType.RegisterTimeOff:
        case EFireBaseType.AprroveTimeOff:
          navigateSearch(ROUTE_PATH.TIMEOFF, {});
          break;

        case EFireBaseType.RegisterMeetingRoomSchedule:
        case EFireBaseType.AwaitCancelMeetingRoomSchedule:
        case EFireBaseType.AprroveMeetingRoomSchedule:
        case EFireBaseType.CancelMeetingRoomSchedule:
          navigateSearch(ROUTE_PATH.BOOKING, {});
          break;

        case EFireBaseType.ReleasedDocument:
          navigateSearch(ROUTE_PATH.DOCS, {});
          break;

        default:
          break;
      }
    }

    function handleCick(notification: INotification) {
      if (notification.viewed) {
        checkPath(
          notification.type,
          JSON.parse(notification.data).TaskId as string
        );
        setAnchorEl(null);
      } else {
        updateNotification.request(
          NotificationService.updateViewed({
            id: notification.id,
            Viewed: true
          }).then(response => {
            if (response.success) {
              if (totalNotification) {
                setTotalNotification(totalNotification - 1);
                setCurrentPage(null);
                setIsUpdate(true);
              }
              // navigate(
              //   `${ROUTE_PATH.TASK}?id=${JSON.parse(notification.data).TaskId}`
              // );
              checkPath(
                notification.type,
                JSON.parse(notification.data).TaskId as string
              );
              setAnchorEl(null);
            } else {
              toggleMessage({
                type: "error",
                message: response?.error ?? "Đã xảy ra lỗi"
              });
            }
          })
        );
      }
    }
    return (
      <NotificationWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography className="title" variant="subtitle2" color={"#596970"}>
            Thông báo ({getNotifications.data?.totalNotSeen ?? 0})
          </Typography>
          {notifications.length > 0 && (
            <Tooltip title={"Đọc tất cả thông báo"}>
              <Button
                onClick={() => {
                  toggleConfirmModal({
                    title: `Bạn muốn đọc hết tất cả thông báo?`,
                    content: `Bạn chắc chắn muốn đọc hết các thông báo này?`,
                    onSubmit: async () =>
                      readAll.request(
                        NotificationService.readAll().then(res => {
                          if (res.success) {
                            toggleMessage({
                              type: "success",
                              message: "Cập nhật thành công"
                            });
                          } else {
                            toggleMessage({
                              type: "error",
                              message: res?.error ?? "Đã xảy ra lỗi"
                            });
                          }
                        })
                      ),
                    submitText: "Xác nhận",
                    type: "confirm"
                  });
                }}
              >
                <DoneAllRoundedIcon className="check-svg" />
              </Button>
            </Tooltip>
          )}
        </div>
        <Divider />
        {notifications.length === 0 ? (
          <div style={{ justifyItems: "center" }}>
            <ImageCustom width="100px" height="100px" src={Empty} />
            <Typography variant="caption" color="#7E969F">
              Chưa có thông báo nào
            </Typography>
          </div>
        ) : (
          notifications.map((item, idx) => (
            <Box key={idx} marginBottom="4px">
              {
                <Box
                  display="flex"
                  marginBottom="8px"
                  justifyContent="space-between"
                  marginTop="4px"
                >
                  <Typography
                    className="date-time"
                    variant="caption"
                    color={"#7E969F"}
                    fontWeight="600"
                  >
                    {item.created}
                  </Typography>
                  <Typography
                    className="date-time"
                    variant="caption"
                    fontSize={10}
                    color="#7E969F"
                  >
                    {moment(moment(item?.created, "DD/MM/YYYY")).fromNow()}
                  </Typography>
                </Box>
              }
              {item.notifications.map(notification => (
                <Box
                  key={notification.id}
                  display="flex"
                  padding="8px"
                  onClick={() => handleCick(notification)}
                  sx={{
                    backgroundColor: notification.viewed
                      ? "transparent"
                      : variableStyles.Info50
                  }}
                >
                  <Badge
                    invisible={true}
                    color="primary"
                    overlap="circular"
                    variant="dot"
                  >
                    <Avatar src={notification?.imageUrl} />
                  </Badge>
                  <Stack
                    direction="column"
                    spacing={0.2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "flex-start",
                      marginLeft: "12px"
                    }}
                  >
                    {/* <Typography variant="subtitle2">{notification.title}</Typography> */}
                    <div
                      style={{ fontSize: "12px" }}
                      dangerouslySetInnerHTML={{
                        __html: notification.title
                      }}
                    />
                    <Box
                      display="flex"
                      justifyItems="space-between"
                      alignItems="center"
                      width="100%"
                      gap="8px"
                    >
                      {!notification.viewed && (
                        <CircleRoundedIcon
                          sx={{
                            fontSize: 8,
                            color: variableStyles.blueSecondary500
                          }}
                        />
                      )}
                      <Typography
                        variant="caption"
                        fontSize={12}
                        color="#7E969F"
                        flexGrow={1}
                      >
                        {moment(notification.created).format(TIME_FORMAT)}
                      </Typography>
                      <Box />
                      <Typography
                        variant="caption"
                        fontSize={"11px"}
                        color="#7E969F"
                      >
                        {eFireBaseComponent(notification.type).text}
                      </Typography>
                      <ImageCustom
                        width="20px"
                        height="20px"
                        src={eFireBaseComponent(notification.type).icon}
                      />
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Box>
          ))
        )}
        {getNotifications.pagging?.totalCount! > 6 &&
          currentPage !== getNotifications.pagging?.totalPages && (
            <Button
              size="small"
              variant="text"
              color="info"
              onClick={() => {
                setCurrentPage((currentPage ?? 1) + 1);
              }}
              style={{ textAlign: "center", width: "100%" }}
              endIcon={
                getNotifications.loading ? undefined : (
                  <KeyboardArrowDownRoundedIcon />
                )
              }
            >
              {getNotifications.loading ? (
                <CircularProgress />
              ) : (
                <Typography variant="subtitle2" fontSize={12}>
                  Hiển thị thông báo cũ hơn
                </Typography>
              )}
            </Button>
          )}
      </NotificationWrapper>
    );
  };

  const sidebarData = sidebarItems(role.data);
  const [menuAnchorEl, setMenuAnchorEl] = useState<{
    left: any;
    sub?: INavItem[];
  } | null>(null);
  useEffect(() => {
    if (userInfo) {
      role.request(RoleService.getFeaturePemissionByUserId(userInfo.id));
    }
  }, [userInfo]);

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const buttonRef = useRef<(HTMLButtonElement | null)[]>([]);

  return (
    <div>
      <ActionPopoverWrapper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {action === "ACCOUNT" ? <UserPopup /> : <NotificationPopup />}
      </ActionPopoverWrapper>
      {/* <NavbarWrapper>
        <div className="header-navigate">
          <Link to={ROUTE_PATH.WORKFLOWS_MANAGEMENT}>
            <ImageCustom
              src={LogoImage}
              alt="HEADER_LOGO"
              width={"auto"}
              height={"20px"}
            />
          </Link>
        </div>

        <nav
          className="nav-bar"
          style={{
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            gap: "44px"
          }}
        >
          <ul className="nav-menu" style={{ display: "flex", gap: "44px" }}>
            {sidebarData.map(item => (
              <li
                className="nav-item"
                style={
                  {
                    // ":hover":{
                    //   backgroundColor: "#3934ba"
                    // }
                  }
                }
                onMouseOver={e =>
                  setMenuAnchorEl({
                    anchol: e.currentTarget,
                    sub: item.children
                  })
                }
                // onMouseLeave={e => setMenuAnchorEl(null)}
              >
                <Link className="nav-links" to={item?.href ?? "/"}>
                  {item.title}
                  {item?.children && item.children.length > 1 && (
                    <ArrowDropDownRounded />
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Menu
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl?.anchol}
          sx={{
            marginTop: "1.5%",
            borderRadius: "12px"
          }}
          onClose={handleClose}
          // onMouseLeave={() => console.log("first")}
          MenuListProps={{ onMouseLeave: handleClose }}
        >
          <Box
          // onMouseLeave={() => console.log("first")}
          >
            {menuAnchorEl?.sub?.map(sub => (
              <MenuItem sx={{ padding: "4px 12px" }} onClick={handleClose}>
                {sub.title}
              </MenuItem>
            ))}
          </Box>
        </Menu>

        <div className="header-action">
          <div className="actice-group">
            <Button
              size="large"
              variant="text"
              className="action-button"
              onClick={event => {
                setAction("NOTIFICATION");
                setAnchorEl(event.currentTarget);
              }}
            >
              <Badge
                color="error"
                badgeContent={totalNotification}
                max={99}
                sx={{
                  "& .MuiBadge-standard": {
                    fontSize: matches ? "10px" : "12px"
                  }
                }}
              >
                <ImageCustom
                  src={NotificationIcon}
                  alt="Notification-Icon"
                  width={"24px"}
                  height={"24px"}
                />
              </Badge>
            </Button>
          </div>
          <Button
            size="large"
            variant="text"
            className="action-profile"
            onClick={event => {
              setAction("ACCOUNT");
              setAnchorEl(event.currentTarget);
            }}
          >
            <Avatar
              src={userInfo?.avatar}
              alt="Avatar-Image"
              width={"32px"}
              height={"32px"}
            />
            {!matches && (
              <Typography color="white" marginLeft="12px" fontSize={13}>
                Xin chào, {userInfo?.fullName}
              </Typography>
            )}
          </Button>
        </div>
      </NavbarWrapper> */}
      {/* <nav
        style={{
          position: "absolute",
          backgroundColor: "black",
          width: "100vw",
          height: "52px"
        }}
      >
        <p>navbar</p>
      </nav> */}
      <AppBar
        sx={{
          backgroundColor: "#0A251F"
        }}
      >
        <Toolbar>
          <Link to={ROUTE_PATH.WORKFLOWS_MANAGEMENT}>
            <ImageCustom
              src={LogoImage}
              alt="HEADER_LOGO"
              width={"auto"}
              height={"20px"}
            />
          </Link>
          <Box
            className="nav-menu"
            sx={{
              display: "flex",
              flexGrow: 1,
              // justifyContent: "center",
              gap: "10px",
              marginLeft: "20px"
            }}
          >
            <Divider
              orientation="vertical"
              sx={{
                color: "white",
                height: "24px",
                margin: "auto 12px",
                opacity: 0.5
              }}
            />
            {sidebarData.map((nav, i) => (
              <Button
                // onMouseOver={e => setMenuAnchorEl(e.currentTarget)}
                // onMouseLeave={handleClose}
                ref={el => (buttonRef.current[i] = el)}
                key={nav.code}
                variant="contained"
                size="small"
                sx={{
                  // width: "100px",
                  color: "white",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#ffffff26",
                    color: "white",
                    borderRadius: "8px"
                  }
                }}
                endIcon={
                  nav.children && nav.children.length > 1 ? (
                    <KeyboardArrowDownRoundedIcon />
                  ) : undefined
                }
                onMouseEnter={e => {
                  const rect = buttonRef.current[i]?.offsetLeft;
                  if (nav.children && nav.children?.length > 1) {
                    setMenuAnchorEl({
                      left: rect,
                      sub: nav.children
                    });
                  }
                }}
                onClick={
                  nav.children && nav.children.length !== 1
                    ? undefined
                    : () =>
                        navigate(nav.children![0].href ?? ROUTE_PATH.NOTFOUND)
                }
              >
                <p>{nav.title}</p>
              </Button>
            ))}
          </Box>

          {menuAnchorEl?.sub && (
            // <div
            //   style={{ position: "absolute", top: "64px", left: "10%" }}
            // >

            // </div>
            <div
              // anchorOrigin={{
              //   vertical: "top",
              //   horizontal: "left"
              // }}
              // transformOrigin={{
              //   vertical: "top",
              //   horizontal: "left"
              // }}
              // open={Boolean(menuAnchorEl)}
              // anchorEl={menuAnchorEl.anchol}
              // onClose={handleClose}
              style={{
                position: "absolute",
                top: "58px",
                left: menuAnchorEl.left,
                backgroundColor: "white",
                color: "#1D1D1D",
                boxShadow: "1px 1px 21px 1px rgba(18,19,19,0.24)",
                borderRadius: "8px"
              }}
            >
              <Box
                display={"flex"}
                flexDirection="column"
                onMouseLeave={handleClose}
                sx={{
                  width: "180px",
                  padding: "8px"
                  // ":hover": { backgroundColor: "red", color: "#3091F3" }
                }}
              >
                {menuAnchorEl.sub.map((sub, i) => (
                  <Link
                    key={`sub-${sub.code}`}
                    to={{
                      pathname: sub.href || "/",
                      search: sub.search
                    }}
                    onClick={handleClose}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        padding: "8px",
                        cursor: "pointer",
                        color:
                          window.location.pathname === sub.href
                            ? "#3091F3"
                            : "#1D1D1D",
                        ":hover": {
                          backgroundColor: "#00000011",
                          borderRadius: "4px"
                        }
                      }}
                    >
                      {sub.title}
                    </Typography>
                  </Link>
                ))}
              </Box>
            </div>
          )}

          <div
            className="header-action"
            style={{ display: "flex", gap: "12px" }}
          >
            <div className="actice-group">
              <Button
                size="large"
                variant="text"
                className="action-button"
                sx={{ marginTop: "8px", marginRight: "12px" }}
                onClick={event => {
                  setAction("NOTIFICATION");
                  setAnchorEl(event.currentTarget);
                }}
              >
                <Badge
                  color="error"
                  badgeContent={totalNotification}
                  max={99}
                  sx={{
                    "& .MuiBadge-standard": {
                      fontSize: matches ? "10px" : "12px"
                    }
                  }}
                >
                  <ImageCustom
                    src={NotificationIcon}
                    alt="Notification-Icon"
                    width={"24px"}
                    height={"24px"}
                  />
                </Badge>
              </Button>
            </div>
            <Divider
              sx={{
                color: "white",
                height: "24px",
                margin: "auto 0",
                opacity: 0.5
              }}
              orientation="vertical"
            />
            <Button
              size="large"
              variant="text"
              className="action-profile"
              onClick={event => {
                setAction("ACCOUNT");
                setAnchorEl(event.currentTarget);
              }}
            >
              <Avatar
                src={userInfo?.avatar}
                alt="Avatar-Image"
                width={"32px"}
                height={"32px"}
              />
              {!matches && (
                <Typography color="white" marginLeft="12px" fontSize={13}>
                  Xin chào,{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {userInfo?.fullName}
                  </span>
                </Typography>
              )}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
