import { useCallback, useEffect, useState } from "react";
import {
  IBasePaging,
  IBasePagingRes,
  IBaseResponse,
  IBaseResponseModel
} from "../../models/common/models.type";

export interface Props {
  loading: "onCreate" | "OnRequest" | null;
}

const useAxios = <T,>({ loading }: Props) => {
  const loadingOnCreate = loading === "onCreate";
  const loadingOnRequest = loading === "OnRequest";
  const [promise, request] = useState<Promise<IBaseResponse<any>> | null>();
  const [data, setData] = useState<T | undefined>(undefined);
  const [paging, setPaging] = useState<IBasePaging | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(loadingOnCreate);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState<Error | null>(null);
  const [retryNumber, setRetryNumber] = useState(0);

  const reset = useCallback(() => {
    setData(undefined);
    setPaging(undefined);
    setIsLoading(false);
    setIsSuccess(false);
    setError(null);
    setMessage("");
  }, []);

  const retry = useCallback(() => {
    setRetryNumber(retryNumber + 1);
  }, [retryNumber]);

  const isIBaseResponseModel = useCallback(
    (res: any): res is IBaseResponseModel<T> => {
      return "data" in res;
    },
    []
  );

  const isIBasePagingRes = useCallback((res: any): res is IBasePagingRes<T> => {
    return "items" in res;
  }, []);

  const handleRequest = useCallback(
    async (promise: Promise<IBaseResponse<typeof data>>) => {
      if (promise)
        try {
          reset();
          setIsLoading(loadingOnRequest);
          const res = await promise;
          if (res) {
            if (res.success) {
              setIsLoading(false);
              if (isIBaseResponseModel(res)) setData(res.data as T);
              if (isIBasePagingRes(res)) {
                setData(res.items as T);
                setPaging({
                  currentPage: res.currentPage,
                  pageSize: res.pageSize,
                  totalCount: res.totalCount,
                  totalPages: res.totalPages
                });
              }

              setIsSuccess(true);
              setMessage(res.message ?? "");
            } else {
              setError({
                name: res.error ?? "",
                message: res.message ?? "",
                stack: ""
              });
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        } catch (e) {
          setError(e as Error);
          setIsLoading(false);
        }
    },
    [isIBaseResponseModel, isIBasePagingRes, reset]
  );

  useEffect(() => {
    if (promise) handleRequest(promise);
  }, [promise, retryNumber, handleRequest]);

  return {
    request: request,
    data: data,
    paging: paging,
    isSuccess: isSuccess,
    message: message,
    isLoading: isLoading,
    error: error,
    reset: reset,
    retry: retry
  };
};

export default useAxios;
