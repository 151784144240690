import { styled } from "@mui/material";
import { responsiveFontSizes } from "../../../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  height: "calc(100vh - 64px)",
  display: "flex",
  flexDirection: "column",
  "& .create-component": {
    flex: "0 0 auto",
    "& .action-button": {
      width: "calc(100% - 48px)",
      fontSize: "12px",
      margin: "24px",
      height: "40px",
      borderRadius: "4px"
    }
  },

  "& .my-schedule-list": {
    backgroundColor: "lavender",
    padding: "0 24px",
    flex: "1 1 auto"
    // height: "100%"
  },

  "@media (max-width: 868px)": {
    "& .detail-color": { display: "none" },
    "& .my-schedule-list": { display: "flex" },
    "& .subtitle2-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 14,
          sm: 14,
          md: 16,
          lg: 16,
          xl: 16
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    }
  }
}));
