import styled from "@emotion/styled";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

export const Wrapper = styled(LoadingOverlayWrapper)(() => ({
  fontSize: "12px",
  display: "flex",
  height: "auto",
  minHeight: "100%",
  backgroundColor: "#dfdfdf",
  "& .content-container": {
    flexGrow: 1,
    minWidth: 500,
    backgroundColor: "white",
    padding: "12px 24px",
    marginRight: "12px",

    "& .content-header": {
      display: "flex",
      "& .header-title": {
        flexGrow: 1,
        margin: "0 12px"
      }
    }
  },
  "& .container-progress": {
    width: "33%",
    minWidth: 310,
    margin: "10px 0px 0px 8px"
  }
}));
