import { Box, styled } from "@mui/material";

export const BoardProjectWrapper = styled(Box)(() => ({
  maxWidth: "100%",
  overflow: "auto",
  height: "calc(100vh - 102px)",
  position: "relative",
  backgroundColor: "white",
  marginTop: "10px"
}));
