
import { envConfig } from "../../config/env.config";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import {
  ICreateWorkflowRequest,
  IGetWorkflowsRequest,
  IWorkflowByTypeResponse,
  IWorkflow,
  ITrackersManagements,
  IWorkflowSearchReq,
  ICopyWorkflowRequest
} from "../../models/workflow";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_WORKFLOW = ApiEndpoints.WORKFLOW;
const baseURL = envConfig.API_ENDPOINT;

const getWorkflows = (
  params: IGetWorkflowsRequest
): Promise<IBasePagingRes<IWorkflowByTypeResponse>> => {
  const workflows = api.get(baseURL, API_WORKFLOW.GET_PAGE.url, {
    params: params
  });

  return workflows;
};

const getAllWorkflows = (
): Promise<IBasePagingRes<IWorkflowByTypeResponse>> => {
  const workflows = api.get(baseURL, API_WORKFLOW.GET_ALL.url);

  return workflows;
};

const searchWorkflows = (
  params: IWorkflowSearchReq
): Promise<IBasePagingRes<IWorkflowByTypeResponse>> => {
  const workflows = api.get(baseURL, API_WORKFLOW.GET_PAGE_ALL.url, {
    params: params
  });

  return workflows;
};

const getWorkflow = (id: string): Promise<IBaseResponseModel<IWorkflow>> => {
  const workflow = api.get(baseURL, API_WORKFLOW.GET_SINGLE.url + `/${id}`);
  return workflow;
};

const createWorkflow = (
  params: ICreateWorkflowRequest
): Promise<IBaseResponseModel<string>> => {
  return api.post(baseURL, API_WORKFLOW.CREATE.url, params);
};

const updateWorkflow = (
  id: string,
  params: ICreateWorkflowRequest
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_WORKFLOW.UPDATE.url, { id, ...params });
};

const closeWorkflow = (
  id: string
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_WORKFLOW.UPDATE_STATUS.url, { Id: id, Status: true });
};

const deleteWorkflow = (id: string): Promise<IBaseResponseModel<string>> => {
  return api.delete(baseURL, API_WORKFLOW.DELETE.url + `/${id}`);
};

const getTrackerManagement = (
  id: string
): Promise<IBaseResponseModel<ITrackersManagements>> => {
  const workflow = api.get(
    baseURL,
    API_WORKFLOW.GET_TRACKER_MANAGEMENT.url + `/${id}`
  );
  return workflow;
};

const copyWorkflow = (
  params: ICopyWorkflowRequest
): Promise<IBaseResponseModel<string>> => {
  return api.post(
    baseURL,
    API_WORKFLOW.COPY_WORKFLOW.url, params
  );

};



const WorkflowService = {
  getWorkflows,
  getAllWorkflows,
  searchWorkflows,
  getWorkflow,
  createWorkflow,
  deleteWorkflow,
  updateWorkflow,
  closeWorkflow,
  getTrackerManagement,
  copyWorkflow
};

export default WorkflowService;
