import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";
import variableStyles from "../../theme/variable-styles";

interface ModalCustomProps {
  width?: string;
}

export const ModalCustomWrapper = styled(Dialog)<ModalCustomProps>(
  ({ theme, width }) => ({
    "& .MuiPaper-root": {
      borderRadius: "12px",
      minWidth: "407px",
      width: !!width ? width : "407px",
      maxHeight: "calc(100vh - 100px)",
      maxWidth: "unset",
      padding: "20px 12px 16px",
      backgroundColor: variableStyles.NaturalColor100,
      "& .modal-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginBottom: "16px",
        "& .header-content": {
          "& .modal-title": {
            color: variableStyles.NaturalColor1000,
            fontWeight: variableStyles.fwSemiBold,
            fontSize: "20px",
            lineHeight: "20px",
            marginBottom: "8px"
          },
          "& .modal-description": {
            color: variableStyles.NaturalColor800,
            fontWeight: variableStyles.fwMedium,
            fontSize: "12px",
            lineHeight: "16.8px"
          }
        }
      },
      "& .infor-box-wrapper": {
        borderRadius: "12px",
        padding: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: variableStyles.NaturalColor0,
        marginBottom: "16px",
        "& .infor-box": {
          display: "flex",
          gap: "12px",
          alignItems: "center",
          flex: "1 1 auto",
          "& .project-avatar": {
            flex: "0 0 auto"
          },
          "& .project-name": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "4px",
            flex: "1 1 auto",
            "& .name-project": {
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwSemiBold,
              fontSize: "14px",
              lineHeight: "14px"
            },
            "& .name-domain": {
              color: variableStyles.NaturalColor900,
              fontWeight: variableStyles.fwRegular,
              fontSize: "12px",
              lineHeight: "12px"
            }
          }
        }
      },
      "& .modal-content": {
        backgroundColor: variableStyles.NaturalColor0,
        padding: "16px 12px",
        marginBottom: "16px",
        borderRadius: "12px",
        overflow: "auto",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none"
        }
      },
      "& .modal-action": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "9px",
        "& .warning-red": {
          backgroundColor: variableStyles.Error400,
          "&:hover": {
            backgroundColor: variableStyles.Error200,
            color: variableStyles.Error400
          }
        },
        "& .button-cancel": {
          border: `1px solid ${variableStyles.NaturalColor400}`,
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "14px",
          lineHeight: "14px",
          "&:hover": { backgroundColor: variableStyles.NaturalColor50 }
        }
      }
    }
  })
);
