import { Box, Button, Divider, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ImageCustom } from "../../../components";
import IconSearch from "../../../assets/images/common/icon_rounded_magnifer.svg";
import { IBoardFilter } from "../../../models/Stage";

/**
 * A text field component with search functionality
 *
 * @component
 * @param {IProps} props - Component props
 * @param {string} props.placeholder - Placeholder text for the text field
 * @param {string} props.value - Current value of the text field
 * @param {(value: string) => void} props.setValue - Function to update the text field value
 * @param {() => void} props.onSearch - Callback function triggered when search button is clicked
 * @returns {JSX.Element} A styled TextField component with search button
 *
 * @example
 * ```tsx
 * <NameFilter
 *   placeholder="Search..."
 *   value={searchValue}
 *   setValue={setSearchValue}
 *   onSearch={handleSearch}
 * />
 * ```
 */
interface IProps {
  placeholder: string;
  filter: IBoardFilter;
  onChange: (value: string | undefined) => void;
  onSearch: () => void;
}

const NameFilter = ({
  placeholder,
  filter,
  onSearch,
  onChange
}: IProps): JSX.Element => {
  return (
    <TextField
      className="rounded-full"
      placeholder={placeholder}
      size="small"
      variant="outlined"
      type="search"
      sx={{ minWidth: { xs: "220px" } }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={onSearch}>
              <ImageCustom src={IconSearch} width="20px" height="16px" />
            </Button>
          </InputAdornment>
        )
      }}
      value={filter.KeySearch}
      onChange={value =>
        onChange(value.target.value === "" ? undefined : value.target.value)
      }
    />
  );
};
export default NameFilter;
