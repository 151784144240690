import { styled } from "@mui/material/styles";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

export const WorkflowManagementWrapper = styled(LoadingOverlayWrapper)(() => ({
  height: "100%",
  "& .workflow-header": {
    backgroundColor: "white",
    height: 80,
    padding: "10px 15px 0px 0px",
    position: "sticky",
    top: 0,
    "z-index": 1,
    boxSizing: "border-box",
    "& .workflow-header-info": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    "& .workflow-icon": {
      width: "50px",
      textAlignLast: "center"
    },
    "& .workflow-title": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: 2
        }
      }
    },
    "& .name": {
      fontSize: "22px",
      inlineSize: "max-content"
    }
  },
  "& .workflow-tabs": {
    height: "calc(50% - 4px)",
    " & .tab-list": {
      li: {
        display: "inline",
        padding: "8px 0px",
        marginRight: "15px",
        " fontSize": "13px",
        cursor: "pointer",

        "&.active": {
          a: {
            color: "black"
          },
          color: "#3091F3",
          fontWeight: 700,
          borderBottom: "solid 2px #267CDE"
        },
        a: {
          color: "#888"
        }
      }
    }
  }
}));
