import {
  draggable,
  dropTargetForElements
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { preserveOffsetOnSource } from "@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import IconWarning from "../../../../../assets/images/common/icon_danger_circle.svg";
import IconClock from "../../../../../assets/images/common/icon_clock_circle.svg";
import IconCheck from "../../../../../assets/images/common/icon_check_circle.svg";
import IconCancel from "../../../../../assets/images/common/icon_close_danger_circle.svg";
import UndoIcon from "@mui/icons-material/Undo";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { createPortal } from "react-dom";
import invariant from "tiny-invariant";

import {
  type Edge,
  attachClosestEdge,
  extractClosestEdge
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { isSafari } from "../DragAndDrop/is-safari";
import { isShallowEqual } from "../DragAndDrop/is-shallow-equal";
import { ITaskCard } from "../../../../../models/Task";
import {
  getCardData,
  getCardDropTargetData,
  isCardData,
  isDraggingACard
} from "../DragAndDrop/data";
import { ETaskStatus } from "../../../../../models/common/models.enum";
import { Box, Button, Divider, Typography } from "@mui/material";
import { MoreVertRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import { Avatar, ImageCustom } from "../../../../../components";
import BasicMenu from "../../../../../components/Menu/BasicMenu";
import { TaskCardWrapper } from "./styles";
import { min } from "lodash";
import { useBoardContext } from "../DragAndDrop/board_context";

interface IProps {
  card: ITaskCard;
  state: TCardState;
  outerRef?: React.MutableRefObject<HTMLDivElement | null>;
  innerRef?: MutableRefObject<HTMLDivElement | null>;
  canReassignTask?: boolean;
}
type TCardState =
  | {
      type: "idle";
    }
  | {
      type: "is-dragging";
    }
  | {
      type: "is-dragging-and-left-self";
    }
  | {
      type: "is-over";
      dragging: DOMRect;
      closestEdge: Edge;
    }
  | {
      type: "preview";
      container: HTMLElement;
      dragging: DOMRect;
    };

const idle: TCardState = { type: "idle" };

const innerStyles: { [Key in TCardState["type"]]?: string } = {
  idle: "hover:cursor-grab",
  "is-dragging": "opacity-40"
};

const outerStyles: { [Key in TCardState["type"]]?: string } = {
  // We no longer render the draggable item after we have left it
  // as it's space will be taken up by a shadow on adjacent items.
  // Using `display:none` rather than returning `null` so we can always
  // return refs from this component.
  // Keeping the refs allows us to continue to receive events during the drag.
  "is-dragging-and-left-self": "hidden"
};

export function CardShadow({ dragging }: { dragging: DOMRect }) {
  return (
    <div
      className="flex-shrink-0 bg-slate-300"
      style={{
        margin: "4px 0px",
        height: min([dragging.height, 100]),
        borderRadius: "12px"
      }}
    />
  );
}

/**
 * Displays a task card component with various interactive features and status indicators
 *
 * @component
 * @param {IProps} props - Component props
 * @param {ITaskCard} props.card - Task card data containing title, description, status, assignee etc.
 * @param {TCardState} props.state - Current drag state of the card including type and position
 * @param {React.Ref} props.outerRef - Ref for the outer container div
 * @param {React.Ref} props.innerRef - Ref for the inner card wrapper
 * @param {boolean} props.canReassignTask - Whether current user can reassign this task
 *
 * @returns {JSX.Element} A card component that displays task information and provides
 * actions like edit, delete, reassign based on user permissions. The card shows:
 * - Task title and description
 * - Assigned person's details
 * - Task deadline
 * - Task status (Complete/Cancelled/In Progress)
 * - Action menu with contextual options
 * - Visual indicators for drag-and-drop functionality
 *
 * The card's appearance and available actions change based on:
 * - Task status
 * - User permissions (roleBoard, roleWorkflow)
 * - Whether task is assigned
 * - Current drag state
 */
export function CardDisplay({
  card,
  state,
  outerRef,
  innerRef,
  canReassignTask
}: IProps): JSX.Element {
  const {
    getRoleBoard,
    getRoleWorkflow,
    viewCardDetail,
    openCardNewTab,
    openDeleteCardDialog,
    openUpdateAssignCardDialog,
    openUpdateCardDialog,
    handleRollBackTask
  } = useBoardContext();
  const roleBoard = useMemo(() => getRoleBoard(), [getRoleBoard]);
  const roleWorkflow = useMemo(
    () => getRoleWorkflow(card.workflowId),
    [getRoleWorkflow]
  );
  const canAssignTask = useMemo(() => {
    return (
      roleWorkflow?.isStageManagement ||
      roleBoard?.isProcessManagement ||
      roleBoard?.isCreator ||
      roleBoard?.isAssignTask ||
      canReassignTask
    );
  }, [roleBoard, roleWorkflow, canReassignTask]);

  const canManageTask = useMemo(() => {
    return (
      roleWorkflow?.isStageManagement ||
      roleBoard?.isProcessManagement ||
      roleBoard?.isCreator ||
      roleBoard?.isAssignTask
    );
  }, [roleBoard, roleWorkflow]);

  const MenuLabel = ({
    isGravity,
    onClick,
    name,
    icon
  }: {
    isGravity?: boolean;
    icon: ReactNode;
    name: string;
    onClick: () => void;
  }) => {
    return (
      <Box
        sx={{
          width: "100%",
          padding: "11px 12px",
          display: "flex",
          color: "#585656c9"
        }}
        onClick={onClick}
      >
        {icon}
        <Typography
          variant="caption"
          fontWeight={500}
          fontSize={13}
          color={isGravity ? "#aa0000" : "585656c9"}
        >
          {name}
        </Typography>
      </Box>
    );
  };

  const menuOptions = [
    {
      value: "blank",
      label: MenuLabel({
        onClick: () => openCardNewTab(card),
        icon: (
          <OpenInBrowserIcon
            sx={{ width: "20px", height: "20px", marginRight: 1 }}
          />
        ),
        name: "Xem ở trong tab mới"
      })
    },
    ...(card.status === ETaskStatus.Failed
      ? [
          {
            value: "assign",
            label: MenuLabel({
              onClick: () => handleRollBackTask(card),
              icon: (
                <UndoIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginRight: 1
                  }}
                />
              ),
              name: "Quay lại giai đoạn trước đó"
            })
          }
        ]
      : []),
    ...(canManageTask && card.status === ETaskStatus.Inprogress
      ? [
          {
            value: "edit",
            label: MenuLabel({
              onClick: () => openUpdateCardDialog(card),
              icon: (
                <ModeEditOutlineOutlinedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginRight: 1
                  }}
                />
              ),
              name: "Chỉnh sửa nhiệm vụ"
            })
          }
        ]
      : []),

    ...(canAssignTask && card.status === ETaskStatus.Inprogress
      ? [
          {
            value: "assign",
            label: MenuLabel({
              onClick: () => openUpdateAssignCardDialog(card),
              icon: (
                <AddBoxRoundedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginRight: 1
                  }}
                />
              ),
              name: "Giao lại cho người khác"
            })
          }
        ]
      : []),
    ...(canManageTask
      ? [
          {
            value: "divider",
            label: (
              <Divider
                sx={{
                  height: "1px",
                  width: "90%",
                  marginInline: 1
                }}
              />
            )
          },
          {
            value: "delete",
            label: MenuLabel({
              isGravity: true,
              onClick: () => openDeleteCardDialog(card),
              icon: (
                <DeleteOutlinedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginRight: 1
                  }}
                />
              ),
              name: "Xoá Nhiệm vụ"
            })
          }
        ]
      : [])
  ];

  return (
    <div
      ref={outerRef}
      className={`flex flex-shrink-0 flex-col ${outerStyles[state.type]}`}
      style={{
        padding: "4px 0"
      }}
    >
      {/* Put a shadow before the item if closer to the top edge */}
      {state.type === "is-over" && state.closestEdge === "top" ? (
        <CardShadow dragging={state.dragging} />
      ) : null}

      <TaskCardWrapper
        className={`${innerStyles[state.type]}`}
        ref={innerRef}
        type={`${card.status}`}
        bg={card.personAssigned && card.color ? card?.color : "white"}
        style={
          state.type === "preview"
            ? {
                width: state.dragging.width,
                height: state.dragging.height,
                transform: !isSafari() ? "rotate(4deg)" : undefined
              }
            : undefined
        }
      >
        {card.personAssigned && card.color && (
          <Box
            display={"flex"}
            sx={{
              height: "30px",
              margin: "0 12px"
            }}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            onClick={() => viewCardDetail(card)}
          >
            <Box display={"flex"} gap={0.5}>
              <Avatar
                src={card.personAssigned.avatar}
                alt="Avatar-Image"
                width={20}
                height={20}
              />
              <Typography
                variant="subtitle2"
                fontSize={12}
                lineHeight={"unset"}
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  webkitLineClamp: "1",
                  webkitBoxOrient: "vertical"
                }}
              >
                {card.personAssigned.fullName}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "8px"
          }}
        >
          {card.status === ETaskStatus.Complete ||
          card.status === ETaskStatus.Cancel ? (
            <Box
              display="flex"
              width={card.status === ETaskStatus.Complete ? "40%" : "30%"}
              borderRadius="12px"
              marginBottom="10px"
              sx={{
                backgroundColor:
                  card.status === ETaskStatus.Complete ? "#DDF2EA" : "#FDECED"
              }}
            >
              <ImageCustom
                src={
                  card.status === ETaskStatus.Complete ? IconCheck : IconCancel
                }
                width="16px"
                height="16px"
              />
              <Typography
                fontSize="10px"
                fontWeight={"600"}
                marginLeft="4px"
                color={
                  card.status === ETaskStatus.Complete ? "#337E60" : "#ED3E47"
                }
              >
                {card.status === ETaskStatus.Complete ? "Hoàn thành" : "Đã huỷ"}
              </Typography>
            </Box>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignSelf={"stretch"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <ImageCustom src={IconClock} width="16px" height="16px" />
              <Typography
                className="flex-1  Overflow2Line prevent-select"
                fontSize="12px"
                fontWeight="500"
                marginLeft="8px"
                variant="caption"
                sx={{
                  // textDecoration: "underline",
                  marginY: "4px",
                  color: "#596970"
                }}
                onClick={() => viewCardDetail(card)}
              >
                {card.deadLine
                  ? dayjs(card.deadLine).format("HH:mm DD/MM/YYYY")
                  : "Không thời hạn"}
              </Typography>
              <BasicMenu
                onChange={() => {}}
                label={
                  <MoreVertRounded sx={{ fontSize: 16, color: "#596970" }} />
                }
                padding={0}
                options={menuOptions}
              />
            </Box>
          )}
          <Typography
            className="flex-1  Overflow2Line prevent-select"
            fontSize={14}
            fontWeight={600}
            sx={{
              // textDecoration: "underline",
              marginY: "4px",
              color: "#596970"
            }}
            onClick={() => viewCardDetail(card)}
          >
            {card.title}
          </Typography>
          {card?.description ? (
            <div
              className="TaskShortDesc Overflow2Line"
              style={{
                fontSize: "12px",
                color: "#596970",
                fontWeight: "400"
              }}
              dangerouslySetInnerHTML={{
                __html: card.description
              }}
              onClick={() => viewCardDetail(card)}
            />
          ) : (
            <Typography
              className="flex-1  Overflow2Line prevent-select"
              fontSize={12}
              fontWeight={400}
              sx={{
                // textDecoration: "underline",
                fontStyle: "italic",
                marginY: "4px",
                color: "#596970"
              }}
              onClick={() => viewCardDetail(card)}
            >
              Không có mô tả
            </Typography>
          )}
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            gap={0.5}
            marginTop={"auto"}
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              webkitLineClamp: "1",
              webkitBoxOrient: "vertical"
            }}
          >
            {/* <Tag name="backend" /> */}
          </Box>
          {!card.personAssigned && canAssignTask && (
            <Box
              display={"flex"}
              sx={{ width: "100%" }}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box
                className="TaskStatus flex-1"
                display={"flex"}
                flexDirection={"row"}
                onClick={() => viewCardDetail(card)}
              >
                <ImageCustom src={IconWarning} width="18px" height="18px" />
                <Typography fontSize="12px" fontWeight="600" color={"#ED3E47"}>
                  Chưa được giao
                </Typography>
              </Box>

              <Button
                sx={{
                  minWidth: "32px",
                  width: "52px",
                  height: "24px",
                  borderRadius: "6px",
                  marginTop: "8px"
                }}
                onClick={() => openUpdateAssignCardDialog(card)}
                variant="contained"
              >
                Giao
              </Button>
            </Box>
          )}
          {card.personAssigned && !card.color && (
            <Box
              display={"flex"}
              sx={{ width: "100%", height: "28px" }}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              onClick={() => viewCardDetail(card)}
            >
              <Box display={"flex"} flexDirection={"row"} gap={0.5}>
                <Avatar
                  src={card.personAssigned.avatar}
                  alt="Avatar-Image"
                  width={20}
                  height={20}
                />
                <Typography
                  variant="caption"
                  fontSize={12}
                  lineHeight={"unset"}
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    webkitLineClamp: "1",
                    webkitBoxOrient: "vertical"
                  }}
                >
                  {card.personAssigned.fullName}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </TaskCardWrapper>

      {state.type === "is-over" && state.closestEdge === "bottom" ? (
        <CardShadow dragging={state.dragging} />
      ) : null}
    </div>
  );
}

export function Card({
  card,
  columnId,
  canReassignTask
}: {
  card: ITaskCard;
  columnId: string;
  canReassignTask?: boolean;
}) {
  const outerRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<TCardState>(idle);
  const { userInfo, getRoleBoard } = useBoardContext();

  const roleBoard = useMemo(() => getRoleBoard(), [getRoleBoard]);

  useEffect(() => {
    const outer = outerRef.current;
    const inner = innerRef.current;
    invariant(outer && inner);

    return combine(
      draggable({
        element: inner,
        canDrag: () =>
          !(
            !roleBoard.isCreator &&
            !roleBoard.isProcessManagement &&
            !roleBoard.isAssignTask &&
            userInfo?.id !== card.personAssignedId
          ),
        getInitialData: ({ element }) =>
          getCardData({
            card: card,
            columnId: columnId,
            rect: element.getBoundingClientRect()
          }),
        onGenerateDragPreview({ nativeSetDragImage, location, source }) {
          const data = source.data;
          invariant(isCardData(data));
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: preserveOffsetOnSource({
              element: inner,
              input: location.current.input
            }),
            render({ container }) {
              // Demonstrating using a react portal to generate a preview
              setState({
                type: "preview",
                container,
                dragging: inner.getBoundingClientRect()
              });
            }
          });
        },
        onDragStart() {
          setState({ type: "is-dragging" });
        },
        onDrop() {
          setState(idle);
        }
      }),
      dropTargetForElements({
        element: outer,
        getIsSticky: () => true,
        canDrop: isDraggingACard,
        getData: ({ element, input }) => {
          const data = getCardDropTargetData({
            card: card,
            columnId: columnId
          });
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"]
          });
        },
        onDragEnter({ source, self }) {
          if (!isCardData(source.data)) {
            return;
          }
          if (source.data.card.id === card.id) {
            return;
          }
          const closestEdge = extractClosestEdge(self.data);
          if (!closestEdge) {
            return;
          }

          setState({
            type: "is-over",
            dragging: source.data.rect,
            closestEdge
          });
        },
        onDrag({ source, self }) {
          if (!isCardData(source.data)) {
            return;
          }
          if (source.data.card.id === card.id) {
            return;
          }
          const closestEdge = extractClosestEdge(self.data);
          if (!closestEdge) {
            return;
          }
          // optimization - Don't update react state if we don't need to.
          const proposed: TCardState = {
            type: "is-over",
            dragging: source.data.rect,
            closestEdge
          };
          setState(current => {
            if (isShallowEqual(proposed, current)) {
              return current;
            }
            return proposed;
          });
        },
        onDragLeave({ source }) {
          if (!isCardData(source.data)) {
            return;
          }
          if (source.data.card.id === card.id) {
            setState({ type: "is-dragging-and-left-self" });
            return;
          }
          setState(idle);
        },
        onDrop() {
          setState(idle);
        }
      })
    );
  }, [card, userInfo, columnId]);
  return (
    <>
      <CardDisplay
        outerRef={outerRef}
        innerRef={innerRef}
        state={state}
        card={card}
        canReassignTask={canReassignTask}
      />
      {state.type === "preview"
        ? createPortal(
            <CardDisplay
              state={state}
              card={card}
              canReassignTask={canReassignTask}
            />,
            state.container
          )
        : null}
    </>
  );
}
