import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export const WorkflowItemWrapper = styled(Box)(() => ({
  backgroundColor: "#f1f5f6",
  borderRadius: "8px",
  maxWidth: 280,
  cursor: "pointer",
  "& .workflow-item-name": {
    fontSize: 15,
    fontWeight: 500
  },
  "& .workflow-item-description": {
    fontSize: 12,
    fontWeight: 400,
    color: "#838383",
    overflow: "hidden",
    display: "-webkit-box",
    webkitLineClamp: "3",
    webkitBoxOrient: "vertical"
  },
  "& .workflow-item-span": {
    fontSize: 11,
    fontWeight: 300,
    color: "#838383"
  }
}));
