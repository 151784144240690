import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  TextField
} from "@mui/material";
import { ControlForm, DragAndDropFile, Modal } from "../../../components";
import InputForm from "../../../components/controls/InputForm";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import TextEditorQuill from "../../../components/TextEditorQuill";
import { ITag } from "../../../models/user";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import "dayjs/locale/vi";
import {
  IAttachmentFile,
  ITaskCard,
  ITaskCardTransitionRequest
} from "../../../models/Task";
import TaskService from "../../../services/api/task.service";
import { toggleMessage } from "../../../components/Toast/Toast";
import useAxios from "../../../components/UseAxios/useAxios";
import UploadFileService from "../../../services/api/uploadFile.service";

import ReactQuill from "react-quill";
import FileImage from "../Task/components/CommentTask/components/FileImage";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import { Wrapper } from "./styles";
import { IStage } from "../../../models/Stage";

interface IProps {
  open: boolean;
  workflowId: string;
  task: ITaskCard;
  newStages: IStage[];
  onUpdateSuccess: () => void;
  handleClose: () => void;
}
interface DataForm {
  taskName: string;
  description: string;
  comment: string;
  assign: ITag[];
  files: File[];
  commentFiles: File[];
}

const UpdateTaskTransitionModal = ({
  workflowId,
  open,
  task,
  handleClose,
  onUpdateSuccess
}: IProps) => {
  const quillRef = useRef<ReactQuill>(null);
  const inputRef = useRef<any>(null);
  const updateTaskAxios = useAxios<ITaskCard>({ loading: "OnRequest" });
  const uploadFileAxios = useAxios<IAttachmentFile[]>({ loading: "OnRequest" });
  const uploadCommentFileAxios = useAxios<IAttachmentFile[]>({
    loading: "OnRequest"
  });
  const deleteFileAxios = useAxios<string>({ loading: "OnRequest" });
  const [files, setFiles] = useState<IAttachmentFile[]>([]);
  const [filesDeleted, setFilesDeleted] = useState<File[]>([]);

  useEffect(() => {
    if (open === false) {
      reset();
      updateTaskAxios.reset();
      uploadFileAxios.reset();
      deleteFileAxios.reset();
      uploadCommentFileAxios.reset();
    }
    if (open === true) {
      setFiles([]);
      setFilesDeleted([]);

      reset({
        taskName: task.title,
        assign: task.personAssigned
          ? [
              {
                id: task.personAssigned.id,
                username: task.personAssigned.userName,
                fullName: task.personAssigned.fullName
              }
            ]
          : [],
        description: task.description,
        files: task.attachmentFiles.map(
          e => new File([], e.fileName, { type: e.path })
        )
      });
    }
  }, [open]);

  useEffect(() => {
    if (uploadFileAxios.error) {
      toggleMessage({
        type: "error",
        message: uploadFileAxios.error?.message ?? ""
      });
    } else if (uploadCommentFileAxios.error) {
      toggleMessage({
        type: "error",
        message: uploadCommentFileAxios.error?.message ?? ""
      });
    }

    if (
      open &&
      uploadFileAxios.data &&
      !uploadFileAxios.isLoading &&
      uploadCommentFileAxios.data &&
      !uploadCommentFileAxios.isLoading
    ) {
      const newFiles = [...files, ...(uploadFileAxios.data ?? [])];

      const values = getValues();

      handleUpdateTask(values, newFiles, uploadCommentFileAxios.data ?? []);
    }
  }, [
    uploadFileAxios.error,
    uploadFileAxios.isSuccess,
    uploadCommentFileAxios.error,
    uploadCommentFileAxios.isSuccess
  ]);

  useEffect(() => {
    if (updateTaskAxios.isSuccess) {
      onUpdateSuccess();
      toggleMessage({
        type: "success",
        message: updateTaskAxios.message ?? "Cập nhật nhiệm vụ thành công"
      });
    } else if (updateTaskAxios.error) {
      toggleMessage({
        type: "error",
        message: updateTaskAxios.error?.message ?? ""
      });
      if (uploadFileAxios.data) {
        deleteFileAxios.request(
          UploadFileService.deleteFiles(uploadFileAxios.data!)
        );
      }
      if (uploadCommentFileAxios.data) {
        deleteFileAxios.request(
          UploadFileService.deleteFiles(uploadCommentFileAxios.data!)
        );
      }
    }
  }, [updateTaskAxios.error, updateTaskAxios.isSuccess]);

  const onSubmit = useCallback(
    (data: DataForm) => {
      //delete server files Deleted
      const serverFilesDeleted = [...files].filter(file =>
        filesDeleted
          .filter(e => e.size === 0)
          .map(e => e.type)
          .includes(file.path.toLowerCase())
      );
      if (filesDeleted.length > 0 && serverFilesDeleted.length > 0) {
        let newFiles = [...files];
        newFiles = newFiles.filter(e => !serverFilesDeleted.includes(e));
        setFiles(newFiles);

        deleteFileAxios.request(
          UploadFileService.deleteFiles(serverFilesDeleted)
        );
      }
      if (data.commentFiles && data.commentFiles.length > 0) {
        const filesData = new FormData();
        filesData.append("FolderName", "Comment");

        data.commentFiles
          .filter(e => e.size > 0)
          .forEach(file => filesData.append("Files", file));
        uploadCommentFileAxios.request(UploadFileService.uploadFile(filesData));
      }
      // upload file
      if (data.files.filter(e => e.size > 0).length > 0) {
        const filesData = new FormData();
        filesData.append("FolderName", "Task");

        data.files
          .filter(e => e.size > 0)
          .forEach(file => filesData.append("Files", file));

        uploadFileAxios.request(UploadFileService.uploadFile(filesData));
      }

      if (
        (!data.files || data.files.length === 0) &&
        (!data.commentFiles || data.commentFiles.length === 0)
      ) {
        let newFiles = [...files];
        const serverFilesDeleted = [...files].filter(file =>
          filesDeleted
            .filter(e => e.size === 0)
            .map(e => e.type)
            .includes(file.path.toLowerCase())
        );
        newFiles = newFiles.filter(e => !serverFilesDeleted.includes(e));

        handleUpdateTask(data, newFiles, []);
      }
    },
    [files, filesDeleted]
  );

  const handleUpdateTask = (
    data: DataForm,
    files: IAttachmentFile[],
    commentFiles: IAttachmentFile[]
  ) => {
    const request: ITaskCardTransitionRequest = {
      id: task.id!,
      title: data.taskName,
      workflowId: workflowId,
      boardId: task.boardId,
      description: data.description,
      attachmentFiles: files,
      personAssignedId: data.assign?.[0]?.id ?? null
    };

    if (
      (data.comment && data.comment.trim().length > 0) ||
      commentFiles.length > 0
    ) {
      request.Comment = {
        Description: data.comment,
        AttachmentFiles: commentFiles
      };
    }

    updateTaskAxios.request(TaskService.updateTaskTransition(request));
  };

  const validationSchema = Yup.object().shape({
    taskName: Yup.string().trim().required(`${"Vui lòng nhập tên nhiệm vụ"}`),
    assign: Yup.array().min(1, `${"Vui lòng chọn người nhận nhiệm vụ"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });
  const onUploaded = (data: File[], name: string) => {
    setValue("files", data);
  };
  const handleRemoveFile = (value: File) => {
    const newFilesDeleted = [...filesDeleted];
    newFilesDeleted.push(value);
    setFilesDeleted(newFilesDeleted);
  };

  return (
    <Modal
      title={"Cập nhật nhiệm vụ"}
      textSubmit={"Cập nhật"}
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={
        uploadFileAxios.isLoading ||
        uploadCommentFileAxios.isLoading ||
        updateTaskAxios.isLoading
      }
      disabledSubmit={
        uploadFileAxios.isLoading ||
        uploadCommentFileAxios.isLoading ||
        updateTaskAxios.isLoading
      }
      onClose={
        !uploadFileAxios.isLoading &&
        !updateTaskAxios.isLoading &&
        !uploadCommentFileAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="500px"
    >
      <Wrapper
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px"
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlForm title="Tên nhiệm vụ" classname="brand-form" isRequired>
          <InputForm
            placeholder="Tên nhiệm vụ"
            required
            name="taskName"
            errors={errors}
            control={control}
            size="small"
          />
        </ControlForm>
        <ControlForm title="Giao cho" classname="brand-form">
          <TagInput
            name={"assign"}
            store={"stage"}
            storeId={workflowId}
            control={control}
            limit={1}
            errors={errors}
            defaultValues={getValues("assign")}
            isError={!!errors.assign}
            placeholder={"Sử dụng @ để tag thành viên nhận nhiệm vụ"}
          />
        </ControlForm>
        <ControlForm
          title="Mô tả nhiệm vụ"
          infor="Mô tả ngắn về nhiệm vụ"
          classname="brand-form"
        >
          <Controller
            control={control}
            name={"description"}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextEditorQuill
                  {...field}
                  placeholder="Mô tả ngắn về nhiệm vụ"
                  toolbar={true}
                  editorStyle={{
                    border: "1px solid #D3DCDF",
                    borderRadius: "8px",
                    width: "100%",
                    height: "24vh"
                  }}
                  quillRef={quillRef}
                />
              </FormControl>
            )}
          />
        </ControlForm>
        <ControlForm title="Bình luận" infor="" classname="brand-form">
          <Box
            sx={{
              border: "1px solid #DEDEDE",
              borderRadius: "8px",
              margin: `10px 0 10px 0px`,
              padding: "1px"
            }}
          >
            <Controller
              control={control}
              name={"comment"}
              render={({ field }) => (
                <TextField
                  id="comment"
                  size="small"
                  multiline
                  InputProps={{ disableUnderline: true }}
                  variant="standard"
                  sx={{
                    "& .MuiInputBase-root": {
                      paddingLeft: 0,
                      padding: 0,
                      margin: 0
                    }
                  }}
                  fullWidth
                  placeholder="Viết thảo luận của bạn"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name={"commentFiles"}
              render={({ field: { onChange, value: value } }) => (
                <Box display="flex" margin="0 8px" alignItems="center">
                  <Box flexGrow={1}>
                    {value &&
                      value.map((f, idx) => (
                        <Chip
                          key={idx}
                          variant="outlined"
                          size="small"
                          sx={{
                            marginRight: "8px",
                            marginBottom: "6px",
                            borderRadius: "8px",
                            padding: "4px",
                            border: "1px solid #DEDEDE"
                          }}
                          label={f?.name}
                          onDelete={() =>
                            onChange(value.filter(e => e.name !== f.name))
                          }
                          avatar={
                            <FileImage type={`.${f?.type.split("/")[1]}`} />
                          }
                        />
                      ))}
                  </Box>
                  <Box textAlign="center">
                    <input
                      name="avatar"
                      ref={inputRef}
                      type="file"
                      id="input-file-upload"
                      multiple={true}
                      onChange={e => {
                        if (e.target.files) {
                          setValue("commentFiles", [
                            ...(value ?? []),
                            e.target.files[0]
                          ]);
                        }
                      }}
                      className="input-file-upload"
                      hidden
                    />
                    <IconButton
                      size="small"
                      onClick={() => inputRef.current.click()}
                    >
                      <AttachFileRoundedIcon sx={{ rotate: "45deg" }} />
                    </IconButton>
                  </Box>
                </Box>
              )}
            />
          </Box>
          {errors.comment && (
            <FormHelperText sx={{ color: "#ED3E47" }}>
              {errors.comment.message ?? ""}
            </FormHelperText>
          )}
        </ControlForm>

        <ControlForm
          title="Tài liệu đính kèm"
          infor="Đính kèm tối đa 10 tài liệu"
          classname="brand-form"
        >
          <DragAndDropFile
            title={"Tài liệu đính kèm"}
            classname="ee"
            limitFile={10}
            onUploaded={onUploaded}
            name={"attach"}
            data={getValues("files")}
            handleRemoveFile={handleRemoveFile}
          ></DragAndDropFile>
        </ControlForm>
      </Wrapper>
    </Modal>
  );
};

export default UpdateTaskTransitionModal;
