import { IStage } from "../../../../../models/Stage";
import { ITaskCard } from "../../../../../models/Task";


export type TBoard = {
    columns: IStage[];
};

const cardKey = Symbol('card');
export type TCardData = {
    [cardKey]: true;
    card: ITaskCard;
    columnId: string;
    rect: DOMRect;
};

export function getCardData({
    card,
    rect,
    columnId,
}: Omit<TCardData, typeof cardKey> & { columnId: string }): TCardData {
    return {
        [cardKey]: true,
        rect,
        card,
        columnId,
    };
}

export function isCardData(value: Record<string | symbol, unknown>): value is TCardData {
    return Boolean(value[cardKey]);
}

export function isDraggingACard({
    source,
}: {
    source: { data: Record<string | symbol, unknown> };
}): boolean {
    return isCardData(source.data);
}

const cardDropTargetKey = Symbol('card-drop-target');
export type TCardDropTargetData = {
    [cardDropTargetKey]: true;
    card: ITaskCard;
    columnId: string;
};

export function isCardDropTargetData(
    value: Record<string | symbol, unknown>,
): value is TCardDropTargetData {
    return Boolean(value[cardDropTargetKey]);
}

/**
 * Generates card drop target data for drag and drop operations.
 * 
 * @param {Object} params - The parameters object
 * @param {object} params.card - The card object being dragged
 * @param {string} params.columnId - The ID of the column where the card is being dropped
 * @returns {TCardDropTargetData} An object containing drop target data with card and column information
 */
export function getCardDropTargetData({
    card,
    columnId,
}: Omit<TCardDropTargetData, typeof cardDropTargetKey> & {
    columnId: string;
}): TCardDropTargetData {
    return {
        [cardDropTargetKey]: true,
        card,
        columnId,
    };
}

const columnKey = Symbol('column');
export type TColumnData = {
    [columnKey]: true;
    column: IStage;
};

export function getColumnData({ column }: Omit<TColumnData, typeof columnKey>): TColumnData {
    return {
        [columnKey]: true,
        column,
    };
}

export function isColumnData(value: Record<string | symbol, unknown>): value is TColumnData {
    return Boolean(value[columnKey]);
}

export function isDraggingAColumn({
    source,
}: {
    source: { data: Record<string | symbol, unknown> };
}): boolean {
    return isColumnData(source.data);
}