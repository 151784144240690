import { Create } from "@mui/icons-material";
import { get } from "lodash";

const AUTH_PATH = "/auth";
const USER_PATH = "/User";
const AUDIT_PATH = "/audit";
const WORKFLOW_PATH = "/Board";
const TASK_PATH = "/TaskCard";
const DEPARTMENT_PATH = "/Department";
const STAGE_PATH = "/Workflow";
const UPLOAD_FILE_PATH = "/api/FileUpload";
const COMMENT_PATH = "/Comment";
const NOTIFICATION_PATH = "/Firebase";
const MEETING_PATH = "/MeetingRoomSchedule";
const MEETING_ROOM_PATH = "/MeetingRoom";
const TIMEOFF_PATH = "/TimeOff";
const TIMEOFF_HR_PATH = "/Hr";
const TIMEOFFTYPE_PATH = "/TimeOffType";
const ROLE_PATH = "/Roles";
const FEATURE_PATH = "/Feature";
const PERMISSON_PATH = "/Permission";
const OFFICE_RULES_PATH = "/Rules";
const DOCS_PATH = "/Document";

const AUTH = {
  LOGIN_WITH_PASSWORD: {
    url: `${AUTH_PATH}/login`
  },
  ADMIN_LOGIN_WITH_PASSWORD: {
    url: `${AUTH_PATH}/adminlogin`
  },
  UPDATE_FCM_TOKEN: {
    url: `${AUTH_PATH}/UpdateFireBaseToken`
  },
  LOGOUT: {
    url: `${AUTH_PATH}/LogOut`
  }
};

const USER = {
  CREATE: {
    url: `${USER_PATH}/Register`
  },
  GET_SIGNLE: {
    url: `${USER_PATH}/GetSingle`
  },
  UPDATE_PASSWORD_BY_ADMIN: {
    url: `${USER_PATH}/AdminChangePassword`
  },
  UPDATE_PASSWORD_BY_USER: {
    url: `${USER_PATH}/UserChangePassword`
  },
  GET_USERS: {
    url: `${USER_PATH}/GetPage`
  },
  UPDATE_BY_ADMIN: {
    url: `${USER_PATH}/AdminUpdate`
  },
  USER_UPDATE: {
    url: `${USER_PATH}/UserUpdate`
  },
  GET_USER_OR_DEPARTMENT: {
    url: `${USER_PATH}/GetPageSearchUserOrDepartment`
  },
  GET_USER_OR_DEPARTMENT_BY_STAGE_ID: {
    url: `${USER_PATH}/GetPageUserOrDepartmentByWorkflowId`
  },
  GET_USER_OR_DEPARTMENT_BY_WORKFLOW_ID: {
    url: `${USER_PATH}/GetPageUserOrDepartmentByBoardId`
  },

  DELETE: {
    url: `${USER_PATH}/Delete`
  }
  // UPDATE_STATUS: {
  //   url: `${USER_PATH}/UpdateIsDelete`
  // }
};

const AUDIT = {
  POST_START_AUDIT: {
    url: `${AUDIT_PATH}/Task`
  },
  CANCEL_TASK: {
    url: `${AUDIT_PATH}/Task/cancel`
  },
  GET_STATUS_TASK: {
    url: `${AUDIT_PATH}/Task/status`
  },
  GET_DETAIL_TASK: {
    url: `${AUDIT_PATH}/Task/detail`
  },
  GET_LIST_TASK: {
    url: `${AUDIT_PATH}/Task/gets`
  },
  GET_LIST_SITEMAPS: {
    url: `${AUDIT_PATH}/Task/sitemaps`
  }
};

const WORKFLOW = {
  GET_PAGE: {
    url: `${WORKFLOW_PATH}/GetPage`
  },
  GET_PAGE_ALL: {
    url: `${WORKFLOW_PATH}/GetPageAll`
  },
  GET_ALL: {
    url: `${WORKFLOW_PATH}/GetAll`
  },
  GET_SINGLE: {
    url: `${WORKFLOW_PATH}/GetById`
  },
  DELETE: {
    url: `${WORKFLOW_PATH}/Delete`
  },
  CREATE: {
    url: `${WORKFLOW_PATH}/Create`
  },
  UPDATE: {
    url: `${WORKFLOW_PATH}/Update`
  },
  UPDATE_RELATED: {
    url: `${WORKFLOW_PATH}/UpdateWorkflowRelated`
  },
  UPDATE_STATUS: {
    url: `${WORKFLOW_PATH}/UpdateStatus`
  },
  GET_TRACKER_MANAGEMENT: {
    url: `${WORKFLOW_PATH}/GetTrackerAndManagement`
  },
  COPY_WORKFLOW: {
    url: `${WORKFLOW_PATH}/CopyBoard`
  }
};
const TASK = {
  CREATE: {
    url: `${TASK_PATH}/Create`
  },
  UPDATE: {
    url: `${TASK_PATH}/Update`
  },
  DELETE: {
    url: `${TASK_PATH}/Delete`
  },
  GET_SINGLE: {
    url: `${TASK_PATH}/GetSingle`
  },
  GET_PAGE: {
    url: `${TASK_PATH}/GetPageMyTaskCards`
  },
  GET_PAGE_CHILDREN: {
    url: `${TASK_PATH}/GetPageChildren`
  },
  UPDATE_POSITION: {
    url: `${TASK_PATH}/UpdateTaskCardPosition`
  },
  UPDATE_TASK_TRANSITION: {
    url: `${TASK_PATH}/UpdateTaskTransition`
  },
  UPDATE_ASSIGN: {
    url: `${TASK_PATH}/Assigntasks`
  },
  NEXT_STAGE: {
    url: `${TASK_PATH}/NextStage`
  },
  MARK_AS_FAILED: {
    url: `${TASK_PATH}/MarkAsFailed`
  },
  ROLL_BACK: {
    url: `${TASK_PATH}/RollBack`
  },
  UPDATE_STATUS: {
    url: `${TASK_PATH}/UpdateStatus`
  },
  EXPORT_EXCEL_TASK_BY_BOARD_ID: {
    url: `${TASK_PATH}/ExportExcelTaskByBoardId`
  }
};
const STAGE = {
  GET_ALL: {
    url: `${STAGE_PATH}/GetAll`
  },
  GET_BY_WORKFLOWID: {
    url: `${STAGE_PATH}/GetByBoardID`
  },
  GET_SINGLE: {
    url: `${STAGE_PATH}/GetSingle`
  },
  CREATE_NEW_STAGE: {
    url: `${STAGE_PATH}/Create`
  },
  DELETE: {
    url: `${STAGE_PATH}/Delete`
  },
  UPDATE_POSITION: {
    url: `${STAGE_PATH}/UpdateWorkflowPosition`
  },
  UPDATE: {
    url: `${STAGE_PATH}/Update`
  },
  GET_LIST_STAGE_BY_BOARD_ID: {
    url: `${STAGE_PATH}/GetListWorkflowByBoardId`
  }
};

const DEPARTMENT = {
  GET_DEPARTMENTS: {
    url: `${DEPARTMENT_PATH}/GetPage`
  },
  GET_DEPARTMENTS_ALL: {
    url: `${DEPARTMENT_PATH}/GetPageAll`
  },
  CREATE: {
    url: `${DEPARTMENT_PATH}/Create`
  },
  GET_SIGNLE: {
    url: `${DEPARTMENT_PATH}/GetSingle`
  },
  UPDATE: {
    url: `${DEPARTMENT_PATH}/Update`
  },
  DELETE: {
    url: `${DEPARTMENT_PATH}/Delete`
  }
};

const UPLOAD_FILE = {
  CREATE: {
    url: `${UPLOAD_FILE_PATH}/Upload`
  },
  DELETE: {
    url: `${UPLOAD_FILE_PATH}/DeleteFile`
  },
  DELETE_MUlTIPLE: {
    url: `${UPLOAD_FILE_PATH}/DeleteFileMultiple`
  },
  DOWNLOAD: {
    url: `${UPLOAD_FILE_PATH}/DownloadFile`
  }
};

const COMMENT = {
  GET_COMMENTS: {
    url: `${COMMENT_PATH}/GetPageByTaskCardId`
  },
  GET_CHILDREN_COMMENTS: {
    url: `${COMMENT_PATH}/GetPageByCommentParentId`
  },
  GET_PAGE_BY_COMMENT_PARENT_ID: {
    url: `${COMMENT_PATH}/GetPageByCommentParentId`
  },
  CREATE: {
    url: `${COMMENT_PATH}/Create`
  }
  // GET_SIGNLE: {
  //   url: `${DEPARTMENT_PATH}/GetSingle`
  // },
  // UPDATE: {
  //   url: `${DEPARTMENT_PATH}/Update`
  // },
  // DELETE: {
  //   url: `${DEPARTMENT_PATH}/Delete`
  // }
};

const NOTIFICATION = {
  GET_PAGE: {
    url: `${NOTIFICATION_PATH}/GetPageNotification`
  },
  UPDATE: {
    url: `${NOTIFICATION_PATH}/UpdateViewed`
  },
  READ_ALL: {
    url: `${NOTIFICATION_PATH}/ReadAll`
  }
  // UPDATE: {
  //   url: `${DEPARTMENT_PATH}/Update`
  // },
  // DELETE: {
  //   url: `${DEPARTMENT_PATH}/Delete`
  // }
};
const TIMEOFF = {
  CREATE: {
    url: `${TIMEOFF_PATH}/Create`
  },
  DELETE: {
    url: `${TIMEOFF_PATH}/Delete`
  },
  GET_MINE: {
    url: `${TIMEOFF_PATH}/GetPageMyTimeOff`
  },
  GET_MANAGER_TIMEOFF: {
    url: `${TIMEOFF_PATH}/GetPageManagerTimeOff`
  },
  GET_CEO_TIMEOFF: {
    url: `${TIMEOFF_PATH}/GetPageCEOTimeOff`
  },
  CEO_UPDATE_TIMEOFF_STATUS: {
    url: `${TIMEOFF_PATH}/AdminUpdateStatus`
  },
  MANAGER_UPDATE_TIMEOFF_STATUS: {
    url: `${TIMEOFF_PATH}/ManagerUpdateStatus`
  },
  GET_PAGE_VIEW_USER_REGISTER_TIMEOFF: {
    url: `${TIMEOFF_HR_PATH}/GetPageViewUserRegisterTimeOff`
  },
  EXPORT_EXCEL_TIMEOFF: {
    url: `${TIMEOFF_HR_PATH}/ExportExcelTimeOff`
  },
  UPDATE_TOTAL_TIMEOFF: {
    url: `${TIMEOFF_HR_PATH}/UpdateTotalTimeOffForUser`
  }
};

const TIMEOFFTYPE = {
  GET_PAGE: {
    url: `${TIMEOFFTYPE_PATH}/GetPage`
  },
  GET_TIMEOFFTYPE: {
    url: `${TIMEOFFTYPE_PATH}/GetTimeOfType`
  },
  GET_SINGLE: {
    url: `${TIMEOFFTYPE_PATH}/GetSingle`
  },
  CREATE: {
    url: `${TIMEOFFTYPE_PATH}/Create`
  },
  UPDATE: {
    url: `${TIMEOFFTYPE_PATH}/Update`
  },
  DELETE: {
    url: `${TIMEOFFTYPE_PATH}/Delete`
  },
  GET_MAIL_CC: {
    url: `${TIMEOFFTYPE_PATH}/GetMailCC`
  },
  UPDATE_MAIL_CC: {
    url: `${TIMEOFFTYPE_PATH}/UpdateMailCC`
  }
};

const ROLE = {
  GET_ROLES: {
    url: `${ROLE_PATH}/GetRoles`
  },
  GET_FEATURE: {
    url: `${FEATURE_PATH}/GetFutureByRoleId`
  },
  GET_PERMISSION: {
    url: `${PERMISSON_PATH}/GetPemissionByFeatureId`
  },
  UPDATE_ROLE_PERMISSION: {
    url: `${PERMISSON_PATH}/UpdateRolePermission`
  },
  GET_USER_ROLE_PERMISSION: {
    url: `${PERMISSON_PATH}/GetUserRolePermission`
  },
  GET_DEFAULT_PERMISSION: {
    url: `${PERMISSON_PATH}/GetDefaultPermissionByKeyRole`
  },
  GET_FEATURE_PERMISSION_BY_USERID: {
    url: `${FEATURE_PATH}/GetFutureAndPermissionByUserId`
  }
};

const MEETING = {
  GET_PAGE: {
    url: `${MEETING_PATH}/GetMeetingRoomScheduleByDate`
  },
  GET_PAGE_BY_STATUS: {
    url: `${MEETING_PATH}/GetPage`
  },
  GET_ROOMS: {
    url: `${MEETING_ROOM_PATH}/GetPageNotPermission`
  },
  GET_SIGNLE: {
    url: `${MEETING_PATH}/GetSingle`
  },
  UPDATE: {
    url: `${MEETING_PATH}/Update`
  },
  UPDATE_DATETIME: {
    url: `${MEETING_PATH}/UpdateDateTime`
  },
  ADMIN_UPDATE: {
    url: `${MEETING_PATH}/AdminUpdateStatus`
  },
  USER_UPDATE: {
    url: `${MEETING_PATH}/UserUpdateStatus`
  },
  CREATE: {
    url: `${MEETING_PATH}/Create`
  },
  CREATE_ROOM: {
    url: `${MEETING_ROOM_PATH}/Create`
  },
  DELETE: {
    url: `${MEETING_PATH}/Delete`
  },
  DELETE_ROOM: {
    url: `${MEETING_ROOM_PATH}/Delete`
  }
};

const OFFICE = {
  GET: {
    url: `${OFFICE_RULES_PATH}/Get`
  },
  UPDATE: {
    url: `${OFFICE_RULES_PATH}/Update`
  }
};

const DOCS = {
  GET_PAGE_BY_USER: {
    url: `${DOCS_PATH}/GetPageByUser`
  },
  GET_PAGE_BY_ADMIN: {
    url: `${DOCS_PATH}/GetPageByAdmin`
  },
  GET_SINGLE: {
    url: `${DOCS_PATH}/GetSingle`
  },
  CREATE: {
    url: `${DOCS_PATH}/Create`
  },
  CREATE_DOCUMENT_TYPE: {
    url: `${DOCS_PATH}Type/Create`
  },
  GET_DOCUMENT_TYPE: {
    url: `${DOCS_PATH}Type/GetPage`
  },
  GET_DOCUMENT_TYPE_NOT_PERMISSION: {
    url: `${DOCS_PATH}Type/GetPageNotPermission`
  },
  UPDATE: {
    url: `${DOCS_PATH}/Update`
  },
  UPDATE_READ: {
    url: `${DOCS_PATH}/UpdateReadDocument`
  },
  UPDATE_DOCUMENT_TYPE: {
    url: `${DOCS_PATH}Type/Update`
  },
  DELETE: {
    url: `${DOCS_PATH}/Delete`
  },
  DELETE_DOCUMENT_TYPE: {
    url: `${DOCS_PATH}Type/Delete`
  }
};

const ApiEndpoints = {
  AUTH,
  USER,
  AUDIT,
  WORKFLOW,
  TASK,
  DEPARTMENT,
  STAGE,
  UPLOAD_FILE,
  COMMENT,
  NOTIFICATION,
  MEETING,
  TIMEOFF,
  TIMEOFFTYPE,
  ROLE,
  OFFICE,
  DOCS
};

export default ApiEndpoints;
