import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const GroupPanelWrapper = styled("div")(theme => ({
  "& .MuiDivider-root": {
    marginLeft: 16
  },
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    "& .Mui-expanded": { margin: 0 },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 64,
      flexDirection: "row-reverse",
      "& MuiAccordionSummary-content": {
        margin: 0
      }
    },
    "& .MuiAccordionDetails-root": {
      padding: " 8px 8px 24px",
      "& .workflow-item": {
        minWidth: 280,
        height: 184
      }
    },
    "& .group-panel-title": {
      alignSelf: "center",
      fontWeight: 600,
      paddingLeft: ".5em",
      color: "#596970"
    }
  }
}));
