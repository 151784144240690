import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { ImageCustom } from "../../../../components";
import FileIcon from "../../../../assets/images/file/Paperclip.svg";
import Logo from "../../../../assets/images/common/effective.svg";
import moment from "moment";

import { CircleRounded, DownloadRounded } from "@mui/icons-material";
import { b64toBlob } from "../../../../common/handles/common.handles";

import "react-quill/dist/quill.core.css";
import { IDocument } from "../../../../models/docs";
import FileImage from "../../../ManageProject/Task/components/CommentTask/components/FileImage";
import { DATETIME_FORMAT2 } from "../../../../config/constants";
import {
  EDocumentStatus,
  EFileType
} from "../../../../models/common/models.enum";
import { useApi } from "../../../../hooks/useApi.hook";
import { IDownloadFile } from "../../../../models/Comment";
import { IAttachmentFile } from "../../../../models/Task";
import UploadFileService from "../../../../services/api/uploadFile.service";
import { Wrapper } from "./styles";
import { theme } from "../../../../theme/theme";

type Props = {
  item: IDocument;
};

const DocsContent = ({ item }: Props) => {
  const downloadFile = useApi<IDownloadFile>({});
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  function handleDownloadFile(file: IAttachmentFile) {
    downloadFile.request(
      UploadFileService.downloadFile({
        path: file.path,
        fileName: file.fileName
      }).then(response => {
        if (response.success) {
          const blob = b64toBlob(
            response.data.fileContent,
            response.data.fileName
          );
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode!.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
    );
  }

  const ListItem = ({ item }: { item: IAttachmentFile }) => (
    <Box
      border={"1px solid #DEDEDE"}
      borderRadius="8px"
      margin="12px 0"
      padding="8px 16px"
      display="flex"
      marginRight="12px"
    >
      <FileImage type={item.extension ?? EFileType.doc} />
      <Typography className="docs-title" variant="subtitle2" margin="0 12px">
        {item.fileName}
      </Typography>
      <Tooltip title="Tải xuống tài liệu">
        <Button
          sx={{ color: "#7E969F" }}
          onClick={() => handleDownloadFile(item)}
        >
          <DownloadRounded />
        </Button>
      </Tooltip>
    </Box>
  );

  return (
    <Wrapper>
      {item.documentType && (
        <Box display="flex" gap="6px" marginBottom="12px" alignItems="center">
          <CircleRounded sx={{ fontSize: 12, color: "#3091F3" }} />
          <Typography variant="caption">{item.documentType?.name}</Typography>
        </Box>
      )}
      <Typography
        className="docs-header"
        variant="subtitle2"
        sx={{ fontSize: "18px" }}
      >
        {item?.numberIdentifier ? `[${item?.numberIdentifier}]` : ""}{" "}
        {item.title}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ color: "#7E969F", paddingTop: "12px" }}
      >
        <Box>
          <Box display={"flex"} gap="8px">
            <Box display={"flex"} flexDirection="column" gap="6px">
              <Typography className="docs-title" variant="caption">
                Ngày phát hành:
              </Typography>
              <Typography className="docs-title" variant="caption">
                Ngày hết hạn:
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection="column" gap="6px">
              <Typography className="docs-title" variant="caption">
                {moment(item.dateOfIssue).format(DATETIME_FORMAT2)}
              </Typography>
              <Typography className="docs-title" variant="caption">
                {item.documentExpirationDate
                  ? moment(item.documentExpirationDate).format(DATETIME_FORMAT2)
                  : "Không có thông tin"}
              </Typography>{" "}
            </Box>
          </Box>
        </Box>
        {item.status === EDocumentStatus.Released && (
          <ImageCustom src={Logo} width="72px" height="72px" />
        )}
      </Box>
      <Box marginTop="12px">
        <div
          className="view ql-editor"
          dangerouslySetInnerHTML={{
            __html: item?.description ?? ""
          }}
        ></div>
      </Box>

      {item.attachmentFiles.length > 0 && (
        <Box className="attachmentFiles-container" marginTop="32px">
          <Box display="flex">
            <ImageCustom src={FileIcon} width="18px" height="18px" />
            <Typography
              className="docs-title"
              variant="subtitle2"
              sx={{ color: "#7E969F", marginLeft: "10px" }}
            >
              TỆP ĐÍNH KÈM
            </Typography>
          </Box>

          {matches ? (
            <Box>
              {item.attachmentFiles &&
                item?.attachmentFiles.map((file, i) => (
                  <Box key={`${i}/${file.fileName}`}>
                    <ListItem item={file} />
                  </Box>
                ))}
            </Box>
          ) : (
            <Grid container>
              {item.attachmentFiles &&
                item?.attachmentFiles.map((file, i) => (
                  <Grid key={`${i}/${file.fileName}`} item xs={"auto"}>
                    {" "}
                    <ListItem item={file} />
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      )}
    </Wrapper>
  );
};

export default DocsContent;
