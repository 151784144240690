import React, { useEffect, useMemo, useState } from "react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

import CreateTimeOffModal from "./components/CreateTimeOffModal/CreateTimeOffModal";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  Divider,
  Menu,
  MenuItem,
  CircularProgress
} from "@mui/material";
import TimeOffTable from "./components/DataTable/TimeOffDataTable";
import {
  ITimeOff,
  ITimeOffOperation,
  ITimeOffOption
} from "../../models/timeoff";
import variableStyles from "../../theme/variable-styles";
import {
  EDayOffSession,
  ETimeOffOperation,
  ETimeOffStatus,
  ETimeOffType,
  TimeOffPermissionModel
} from "../../models/common/models.enum";
import useAxios from "../../components/UseAxios/useAxios";
import TimeOffService from "../../services/api/timeoff.service";
import { toggleMessage } from "../../components/Toast/Toast";
import { IBasePaging } from "../../models/common/models.type";
import dayjs from "dayjs";
import ExportTimeOffModal from "./components/ExportTimeOffModal/ExportTimeOffModal";
import { IFeaturePermission } from "../../models/user/models.roles";
import RoleService from "../../services/api/role.service";
import authTokens, { USER_TOKEN_KEY } from "../../services/local/auth-tokens";
import { Helmet } from "react-helmet";
import palette from "../../theme/palette";

// khung thời gian nghỉ phép phép
export const sessionOptions: readonly ITimeOffOption[] = [
  {
    name: "8:30-12:30",
    value: EDayOffSession.Morning
  },
  {
    name: "13:30-17:30",
    value: EDayOffSession.Afternoon
  }
];
// loại nghỉ phép
export const typeOptions: readonly ITimeOffOperation[] = [
  {
    name: "Nghỉ phép năm dưới 3 ngày",
    value: ETimeOffType.Leave_of_absence_with_permission_for_less_than_3days,
    operation: ETimeOffOperation.Minus
  },
  {
    name: "Nghỉ phép năm trên 3 ngày",
    value: ETimeOffType.Leave_of_absence_with_permission_for_more_than_3days,
    operation: ETimeOffOperation.Minus
  },
  {
    name: "Nghỉ không lương dưới 3 ngày",
    value: ETimeOffType.Leave_of_absence_without_permission_for_less_than_3days,
    operation: ETimeOffOperation.NotOperation
  },
  {
    name: "Nghỉ không lương trên 3 ngày",
    value: ETimeOffType.Leave_of_absence_without_permission_for_more_than_3days,
    operation: ETimeOffOperation.NotOperation
  }
];

interface ICreateButonProps {
  onCreteTimeOff?: () => void;
  onCreateExcel?: () => void;
}

const CreateTimeOffButton = ({
  onCreteTimeOff,
  onCreateExcel
}: ICreateButonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!onCreateExcel) {
      handleCreateTimeOff();
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCreateTimeOff = () => {
    handleClose();
    onCreteTimeOff?.();
  };
  const handleCreateExcel = () => {
    handleClose();
    onCreateExcel?.();
  };
  if (!onCreteTimeOff) return null;

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-hidden={!open}
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowDropDownOutlinedIcon sx={{ height: 24, width: 24 }} />}
        sx={{
          color: "white",
          borderRadius: 1,
          height: 40,
          width: "100%",
          "& .MuiButton-endIcon": {
            marginLeft: 0
          },
          "&:hover": {
            backgroundColor: variableStyles.GreenPrimaryColor400
          }
        }}
      >
        <Typography fontSize={14} fontWeight={700}>
          Tạo đề xuất
        </Typography>
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateTimeOff} disableRipple>
          <Typography fontSize={14} fontWeight={400}>
            Tạo đề xuất
          </Typography>
        </MenuItem>
        {onCreateExcel && <Divider />}
        {onCreateExcel && (
          <MenuItem onClick={handleCreateExcel} disableRipple>
            <Typography fontSize={14} fontWeight={400}>
              Xuất excel theo tháng
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const defaultPaging = {
  currentPage: 1,
  pageSize: 10,
  totalCount: 0,
  totalPages: 0
};

/**
 * TimeOffPage Component - Manages the time off/leave request interface
 *
 * @component
 * @description This component handles the display and management of time off requests, including:
 * - Viewing personal time off requests
 * - Managing pending requests (for managers/admins)
 * - Approving/rejecting requests
 * - Creating new time off requests
 * - Exporting time off data
 *
 * The component includes different views based on user permissions:
 * - All requests view
 * - Pending requests
 * - Manager approved requests
 * - CEO approved requests
 * - Rejected requests
 *
 * @permissions Required permissions include:
 * - View: To see personal time off requests
 * - Create: To create new time off requests
 * - Delete: To delete time off requests
 * - AdminUpdateStatus/ManagerUpdateStatus: To approve/reject requests
 * - ExportExcelTimeOff: To export time off data
 *
 * @state
 * - currentTab: Current active tab
 * - currentPage: Current page number for pagination
 * - isCreateModalOpen: Controls create modal visibility
 * - isExportModalOpen: Controls export modal visibility
 * - isReload: Triggers data reload
 * - isLoading: Loading state indicator
 * - pendingNumber: Number of pending requests
 * - managerApproveNumber: Number of manager approved requests
 * - permission: User's feature permissions
 * - HrPermission: HR-specific permissions
 * - timeOffProposes: Array of time off requests
 * - paging: Pagination information
 *
 * @returns React component that renders the time off management interface
 */
const TimeOffPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<any>("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isReload, setIsReload] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);
  const [pendingNumber, setPendingNumber] = useState<number | undefined>(
    undefined
  );
  const [managerApproveNumber, setManagerApproveNumber] = useState<
    number | undefined
  >(undefined);
  const [permission, setPermission] = useState<IFeaturePermission | undefined>(
    undefined
  );
  const [HrPermission, setHrPermission] = useState<
    IFeaturePermission | undefined
  >(undefined);

  const canExportExcel = useMemo(() => {
    return HrPermission?.permissions.includes("ExportExcelTimeOff") ?? false;
  }, [HrPermission]);

  const [paging, setPaging] = useState<IBasePaging>(defaultPaging);

  const [timeOffProposes, setTimeOffProposes] = useState<ITimeOff[]>([]);

  const getPendingNumberAxios = useAxios<ITimeOff[]>({
    loading: "OnRequest"
  });
  const getManagerApproveNumberAxios = useAxios<ITimeOff[]>({
    loading: "OnRequest"
  });
  const getTimeOffProposesAxios = useAxios<ITimeOff[]>({
    loading: "OnRequest"
  });

  const getPermissionAxios = useAxios<IFeaturePermission[]>({
    loading: "OnRequest"
  });

  const isAdmin = useMemo(
    () =>
      permission?.permissions.includes(TimeOffPermissionModel.AdminTimeOff) ??
      false,
    [permission]
  );
  const isManager = useMemo(
    () =>
      permission?.permissions.includes(TimeOffPermissionModel.ManagerTimeOff) ??
      false,
    [permission]
  );

  const handleTabChange = (event: any, tab: string) => {
    setCurrentTab(tab);
    setCurrentPage(1);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleProposeStatusChange = () => {
    setIsReload(dayjs());
  };

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);
  const openExportModal = () => setIsExportModalOpen(true);
  const closeExportModal = () => setIsExportModalOpen(false);

  useEffect(() => {
    authTokens.getUser().then(user => {
      getPermissionAxios.request(
        RoleService.getFeaturePemissionByUserId(user?.id ?? "")
      );
    });
  }, []);

  useEffect(() => {
    if (getPermissionAxios.isSuccess && getPermissionAxios.data) {
      setPermission(getPermissionAxios.data.find(e => e.feature === "TimeOff"));
      setHrPermission(getPermissionAxios.data.find(e => e.feature === "HR"));
      // load admin PendingNumber
      if (
        getPermissionAxios.data
          .find(e => e.feature === "TimeOff")
          ?.permissions.includes(TimeOffPermissionModel.AdminTimeOff)
      ) {
        getPendingNumberAxios.request(
          TimeOffService.getCEOTimeOffs({
            CurrentPage: currentPage,
            PageSize: paging.pageSize,
            Status: ETimeOffStatus.Pending
          })
        );
        getManagerApproveNumberAxios.request(
          TimeOffService.getCEOTimeOffs({
            CurrentPage: currentPage,
            PageSize: paging.pageSize,
            Status: ETimeOffStatus.ManagerApprove
          })
        );
        return;
      }
      // load manager PendingNumber
      if (
        getPermissionAxios.data
          .find(e => e.feature === "TimeOff")
          ?.permissions.includes(TimeOffPermissionModel.ManagerTimeOff)
      ) {
        getPendingNumberAxios.request(
          TimeOffService.getManagerTimeOffs({
            CurrentPage: currentPage,
            PageSize: paging.pageSize,
            Status: ETimeOffStatus.Pending
          })
        );
        return;
      }
    }
    if (getPermissionAxios.error) {
      toggleMessage({
        type: "error",
        message: getPermissionAxios.error?.message ?? ""
      });
    }
  }, [
    getPermissionAxios.isLoading,
    getPermissionAxios.isSuccess,
    getPermissionAxios.error
  ]);

  const onCreateSuccess = () => {
    setCurrentTab("all");
    setCurrentPage(1);
    if (currentTab === "all" && currentPage === 1) setIsReload(dayjs());
  };

  const getTimeOff = () => {
    setIsLoading(true);
    if (currentTab === "all") {
      getTimeOffProposesAxios.request(
        TimeOffService.getMyTimeOffs({
          CurrentPage: currentPage,
          PageSize: paging.pageSize
        })
      );
      return;
    }
    if (isAdmin) {
      getTimeOffProposesAxios.request(
        TimeOffService.getCEOTimeOffs({
          CurrentPage: currentPage,
          PageSize: paging.pageSize,
          Status: currentTab
        })
      );
      return;
    }
    if (isManager) {
      getTimeOffProposesAxios.request(
        TimeOffService.getManagerTimeOffs({
          CurrentPage: currentPage,
          PageSize: paging.pageSize,
          Status: currentTab
        })
      );
      return;
    }
  };

  useEffect(() => {
    getTimeOff();
  }, [currentPage, currentTab, isReload]);

  useEffect(() => {
    if (getTimeOffProposesAxios.isSuccess && getTimeOffProposesAxios.data) {
      setIsLoading(false);
      setPaging(getTimeOffProposesAxios.paging!);
      const quantity = getTimeOffProposesAxios.paging?.totalCount;
      if (currentTab === ETimeOffStatus.Pending) {
        setPendingNumber(quantity);
      }
      if (currentTab === ETimeOffStatus.ManagerApprove) {
        setManagerApproveNumber(quantity);
      }
      setTimeOffProposes(getTimeOffProposesAxios.data);
    }
    if (getTimeOffProposesAxios.error) {
      setPaging(defaultPaging);
      setIsLoading(false);
      toggleMessage({
        type: "error",
        message: getTimeOffProposesAxios.error?.message ?? ""
      });
      setTimeOffProposes([]);
    }
  }, [
    getTimeOffProposesAxios.isSuccess,
    getTimeOffProposesAxios.data,
    getTimeOffProposesAxios.error
  ]);

  useEffect(() => {
    if (getPendingNumberAxios.isSuccess && getPendingNumberAxios.data) {
      const quantity = getPendingNumberAxios.paging?.totalCount;
      setPendingNumber(quantity);
    }
  }, [
    getPendingNumberAxios.isSuccess,
    getPendingNumberAxios.data,
    getPendingNumberAxios.error
  ]);
  useEffect(() => {
    if (
      getManagerApproveNumberAxios.isSuccess &&
      getManagerApproveNumberAxios.data
    ) {
      const quantity = getManagerApproveNumberAxios.paging?.totalCount;
      setManagerApproveNumber(quantity);
    }
  }, [
    getManagerApproveNumberAxios.isSuccess,
    getManagerApproveNumberAxios.data,
    getManagerApproveNumberAxios.error
  ]);

  if (getPermissionAxios.isSuccess && !permission) return null;
  if (getPermissionAxios.isLoading)
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Nghỉ phép"}</title>
      </Helmet>
      <div style={{ backgroundColor: "white" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ padding: "12px 18px 8px 26px" }}
        >
          <Typography
            fontSize={18}
            className="truncate font-medium prevent-select flex-shrink"
            sx={{ marginY: "4px" }}
          >
            Danh sách đề xuất
          </Typography>
          <CreateTimeOffButton
            onCreteTimeOff={
              permission?.permissions.includes("Create")
                ? openCreateModal
                : undefined
            }
            onCreateExcel={
              HrPermission?.permissions.includes("ViewUserRegisterTimeOff")
                ? openExportModal
                : undefined
            }
          />
        </Box>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          scrollButtons
          variant="scrollable"
          indicatorColor="secondary"
          sx={{
            "& .Mui-selected": {
              color: "#267CDE !important"
            },
            "& .MuiSvgIcon-root": {
              fontSize: 22
            }
          }}
        >
          {permission?.permissions.includes("View") && (
            <Tab
              value="all"
              icon={
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  textTransform={"uppercase"}
                  letterSpacing={"1.1px"}
                  lineHeight={1.5}
                >
                  Phép của tôi
                </Typography>
              }
            />
          )}

          {(isAdmin || isManager) && (
            <Tab
              value={ETimeOffStatus.Pending}
              icon={
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  textTransform={"uppercase"}
                  letterSpacing={"1.1px"}
                  lineHeight={1.5}
                >
                  Chờ duyệt
                  {pendingNumber !== undefined && pendingNumber >= 1 && (
                    <Box
                      component="span"
                      fontWeight="fontWeightMedium"
                      color={palette.secondary.main}
                    >
                      {`(${pendingNumber})`}
                    </Box>
                  )}
                </Typography>
              }
            />
          )}
          {(isAdmin || isManager) && (
            <Tab
              value={ETimeOffStatus.ManagerApprove}
              icon={
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  textTransform={"uppercase"}
                  letterSpacing={"1.1px"}
                  lineHeight={1.5}
                >
                  Quản lí đã duyệt
                  {isAdmin &&
                    managerApproveNumber !== undefined &&
                    managerApproveNumber >= 1 && (
                      <Box
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={palette.secondary.main}
                      >
                        {`(${managerApproveNumber})`}
                      </Box>
                    )}
                </Typography>
              }
            />
          )}
          {(isAdmin || isManager) && (
            <Tab
              value={ETimeOffStatus.CEOApprove}
              icon={
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  textTransform={"uppercase"}
                  letterSpacing={"1.1px"}
                  lineHeight={1.5}
                >
                  Đã được duyệt
                </Typography>
              }
            />
          )}
          {(isAdmin || isManager) && (
            <Tab
              value={ETimeOffStatus.NotApprove}
              icon={
                <Typography
                  fontWeight={700}
                  fontSize={12}
                  textTransform={"uppercase"}
                  letterSpacing={"1.1px"}
                  lineHeight={1.5}
                >
                  Đã từ chối
                </Typography>
              }
            />
          )}
        </Tabs>
      </div>
      <Box
        sx={{
          margin: { xs: "8px 0", md: 2 },
          flexGrow: 1,
          height: "100%",
          backgroundColor: "white"
        }}
      >
        {timeOffProposes && (
          <TimeOffTable
            tab={currentTab}
            loading={getPermissionAxios.isLoading}
            canDeleteTimeoff={
              permission?.permissions.includes("Delete") ?? false
            }
            canUpdateStatus={
              (permission?.permissions.includes("AdminUpdateStatus") ||
                permission?.permissions.includes("ManagerUpdateStatus")) ??
              false
            }
            updateType={
              permission?.permissions.find(e => e.endsWith("UpdateStatus")) ??
              ""
            }
            proposes={timeOffProposes}
            paging={paging}
            onPageChange={handlePageChange}
            onProposeStatusChange={handleProposeStatusChange}
            selectable={false}
          />
        )}
        {getTimeOffProposesAxios.isLoading && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

      <CreateTimeOffModal
        open={isCreateModalOpen}
        handleClose={closeCreateModal}
        onCreateSuccess={onCreateSuccess}
      />
      <ExportTimeOffModal
        open={isExportModalOpen}
        canExportExcel={canExportExcel}
        handleClose={closeExportModal}
      />
    </Box>
  );
};

export default TimeOffPage;
