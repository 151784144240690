import styled from "@emotion/styled";

export const CommentWrapper = styled("div")(() => ({
  "& .parent": {
    li: {
      paddingLeft: "2.4rem"
      //    ":before": {
      //      content: `""`,
      //      // position: "absolute",
      //      // marginLeft: "100px",
      //      left: "0.95rem",
      //      top: "2.2rem",
      //      bottom: 0,
      //      width: "2px",
      //      height: "40px",
      //      backgroundColor: "red"
      //    },
      //    ":after": {
      //      content: `""`,
      //      // position: "absolute",
      //      insetInlineStart: "15px",
      //      top: "4.5rem",
      //      height: "20px",
      //      width: "1.4rem",
      //      borderInlineStart: "2px solid red",
      //      borderBottom: "2px solid red",
      //      borderEndStartRadius: "10px"
      //    }
    },
    "& .depth0": {
      //  ":after": {
      //    content: `""`,
      //    // position: "absolute",
      //    insetInlineStart: "15px",
      //    top: "4.5rem",
      //    height: "20px",
      //    width: "1.5rem",
      //    borderInlineStart: "2px solid red",
      //    borderBottom: "2px solid red",
      //    borderEndStartRadius: "10px"
      //  }
      ul: {
        ":before": {
          content: `""`,
          // position: "absolute",
          // marginLeft: "100px",
          left: "0.5rem",
          top: "2rem",
          bottom: 0,
          width: "2px",
          backgroundColor: "red"
        }
        //  ":after": {
        //    content: `""`,
        //    // position: "absolute",
        //    insetInlineStart: "15px",
        //    top: "4.5rem",
        //    height: "20px",
        //    width: "1.5rem",
        //    borderInlineStart: "2px solid red",
        //    borderBottom: "2px solid red",
        //    borderEndStartRadius: "10px"
        //  }
      }
    }
  }
}));
