import { Box, Chip, CircularProgress, Typography } from "@mui/material";
import DvrIcon from "@mui/icons-material/Dvr";
import { useSearchParams } from "react-router-dom";
import { Roles } from "../../models/common/models.enum";
import { ITabItem } from "../WorkflowManagement/workflow_manage";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { useCallback, useEffect, useMemo, useState } from "react";
import WorkflowService from "../../services/api/workflow.service";
import { toggleMessage } from "../../components/Toast/Toast";
import Manage from "./Task";
import ProjectMagagerial from "./Managerial";
import StageService from "../../services/api/stage.service";
import {
  IBoardFilter,
  IRoleBoard,
  IStage,
  IStageByWorkflowIdRequest,
  IStageByWorkflowResponse,
  IUpdateStagePositionRequest
} from "../../models/Stage";
import {
  ITaskCardUpdatePositionRequest,
  ITaskUpdatePositionResponse
} from "../../models/Task";
import TaskService from "../../services/api/task.service";
import useAxios from "../../components/UseAxios/useAxios";
import { Helmet } from "react-helmet";
import { ManageProjectWrapper } from "./styles";
import useNavigateSearch from "../../components/NavigateSearch";
import { Modal } from "../../components";
import NameFilter from "./Filter/NameFilter";
import DateTimeFilter from "./Filter/DateTimeFilter";
import StageFilter from "./Filter/StageFilter";
import { Range } from "react-date-range";
import TrackerFilter from "./Filter/TrackerFilter";
import { ITag } from "../../models/user";
import BoardProjectWrapper from "./BoardProject";

export const tabItems = (role?: Roles): ITabItem[] => {
  const BOARD: ITabItem = {
    href: ROUTE_PATH.PROJECTS,
    title: "Dạng bảng",
    code: "board"
  };
  const SNARE: ITabItem = {
    href: ROUTE_PATH.MANAGER,
    title: "Dạng lưới",
    code: "list"
  };
  const MORE: ITabItem = {
    href: ROUTE_PATH.PROJECTS_MANAGERIAL,
    title: "Xem thêm",
    code: "managerial"
  };

  return [BOARD, SNARE, MORE];
};

/**
 * ManageProject Component - Handles project management interface and workflow operations
 *
 * @component
 * @description Main component for managing projects, workflows and tasks. Provides functionality for:
 * - Displaying and managing project workflows and stages
 * - Task filtering and search
 * - Workflow stage management
 * - Task position management across stages
 * - Project view modes (board/list/managerial)
 *
 * @example
 * ```tsx
 * <ManageProject />
 * ```
 *
 * @state
 * - searchParams - URL search parameters
 * - tabIndex - Current active tab index
 * - workflow - Current workflow data
 * - stages - List of workflow stages
 * - filter - Current board filter settings
 * - stagesUpdate - Flag to trigger stages refresh
 *
 * @hooks
 * - useSearchParams - For URL parameter handling
 * - useNavigateSearch - For navigation with search params
 * - useAxios - For API calls
 * - useMemo - For memoized values
 * - useEffect - For side effects management
 * - useCallback - For memoized callbacks
 *
 * @events
 * - handleConfirmShutWorkflow - Handles workflow shutdown
 * - handleUpdateStagePosition - Updates stage positions
 * - handleUpdateTaskPosition - Updates task positions within stages
 *
 * @filters
 * - taskNameSearch - Filter by task name
 * - taskTrackerSearch - Filter by task trackers
 * - taskDateSearch - Filter by task dates
 * - stageSearch - Filter by specific stage
 *
 * @returns React component that renders project management interface with boards,
 * lists, and managerial views based on user role and permissions
 */
const ManageProject = () => {
  const [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const tabItemsData = tabItems();
  const reloadChannel = useMemo(() => new BroadcastChannel("reload-board"), []);
  const [boardReloadTime, setBoardReloadTime] = useState<number | undefined>();
  const [tabIndex, setTabIndex] = useState(0);
  const [openShutWorkflowDialog, setOpenShutWorkflowDialog] = useState(false);

  const [workflow, setWorkflow] = useState<IStageByWorkflowResponse | null>(
    null
  );
  const [stages, setStages] = useState<IStage[]>([]);

  const [newStages, setNewStages] = useState<{
    destinationIndex: number | null;
    oldStages: IStage[];
    newStages: IStage[];
  }>({ destinationIndex: null, oldStages: stages, newStages: [] });

  const [filter, setFilter] = useState<IBoardFilter>({
    boardId: searchParams.get("id") as string
  });

  const workflowAxios = useAxios<IStageByWorkflowResponse>({
    loading: "OnRequest"
  });
  const shutWorkflowAxios = useAxios<string>({ loading: "OnRequest" });
  const updateStagePositionAxios = useAxios<IStageByWorkflowResponse>({
    loading: "onCreate"
  });
  const updateTaskPositionAxios = useAxios<ITaskUpdatePositionResponse>({
    loading: "onCreate"
  });

  const getWorkflow = useCallback(
    async (newFilter?: IBoardFilter) => {
      // const newFilter: IBoardFilter = {
      //   boardId: searchParams.get("id") as string
      // };
      // if (stageSearch) newFilter["WorkflowId"] = stageSearch.id;
      // if (taskNameSearch) newFilter["KeySearch"] = taskNameSearch;
      // if (taskDateSearch) {
      //   newFilter["StartDate"] = taskDateSearch.startDate;
      //   if (
      //     taskDateSearch.startDate?.toDateString() !==
      //     taskDateSearch.endDate?.toDateString()
      //   )
      //     newFilter["EndDate"] = taskDateSearch.endDate;
      // }
      // if (taskTrackerSearch.length > 0) {
      //   newFilter["PersonAssignedIds"] = taskTrackerSearch
      //     .map(e => e.id)
      //     .join(",");
      // }
      var f = newFilter ?? filter;
      const request: IStageByWorkflowIdRequest = {
        boardId: f.boardId,
        KeySearch: f.KeySearch,
        EndDate: f.Date?.startDate,
        StartDate: f.Date?.endDate,
        WorkflowId: f.Workflow ? f.Workflow.id : undefined,
        PersonAssignedIds:
          f.PersonAssigned?.length === 0
            ? undefined
            : f.PersonAssigned?.map(e => e.id).join(",")
      };
      workflowAxios.reset();
      workflowAxios.request(StageService.getStage(request));
      // setFilter(newFilter);
    },
    [
      filter
      // taskNameSearch,
      // taskDateSearch,
      // stageSearch,
      // taskTrackerSearch,
      // workflowAxios,
      // searchParams.get("id")
    ]
  );

  const reload = useCallback(
    (newFilter?: IBoardFilter) => {
      getWorkflow(newFilter);
    },
    [getWorkflow]
  );

  useEffect(() => {
    const filter: IBoardFilter = {
      boardId: searchParams.get("id") as string,
      PersonAssigned: []
    };
    setFilter(filter);
    setWorkflow(null);
    setStages([]);
    reload(filter);
  }, [searchParams.get("id")]);

  useEffect(() => {
    if (searchParams.get("type") === "board") setTabIndex(0);
    if (searchParams.get("type") === "list") setTabIndex(1);
    if (searchParams.get("type") === "managerial") setTabIndex(2);
  }, [searchParams.get("type")]);

  const roleBoardByUser: IRoleBoard | undefined = useMemo(() => {
    if (workflow) {
      return workflow.roleBoardByUser;
    }
  }, [workflow]);

  const reloadCallback = (event: MessageEvent<any>) => {
    setBoardReloadTime(event.timeStamp);
  };

  useEffect(() => {
    reloadChannel.addEventListener("message", reloadCallback);
    return () => {
      reloadChannel.removeEventListener("message", reloadCallback);
      reloadChannel.close();
    };
  }, [reloadChannel]);

  useEffect(() => {
    if (newStages.destinationIndex === null && boardReloadTime) reload();
  }, [boardReloadTime]);

  useEffect(() => {
    if (workflowAxios.isSuccess && workflowAxios.data) {
      setWorkflow(workflowAxios.data);
      setStages(workflowAxios.data.workflows);
    }
    if (workflowAxios.error)
      toggleMessage({
        type: "error",
        message: workflowAxios.error.message ?? ""
      });
  }, [workflowAxios.isSuccess, workflowAxios.error, workflowAxios.data]);

  useEffect(() => {
    if (updateTaskPositionAxios.isSuccess && updateTaskPositionAxios.data) {
      if (
        JSON.stringify(
          updateTaskPositionAxios?.data?.newWorkflow?.taskCards.map(card => ({
            ...card,
            personAssigned: null
          }))
        ) !==
        JSON.stringify(
          newStages.newStages[newStages.destinationIndex!]?.taskCards.map(
            card => ({ ...card, personAssigned: null })
          )
        )
      ) {
        setNewStages({ ...newStages, destinationIndex: null });
      }
      toggleMessage({
        type: "success",
        message: updateTaskPositionAxios.message ?? "Cập nhật thành công"
      });
    }
    if (updateTaskPositionAxios.error) {
      setStages(newStages.oldStages);
      toggleMessage({
        type: "error",
        message: updateTaskPositionAxios.error.message ?? ""
      });
    }
  }, [
    updateTaskPositionAxios.isSuccess,
    updateTaskPositionAxios.error,
    updateTaskPositionAxios.message
  ]);

  useEffect(() => {
    if (updateStagePositionAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateStagePositionAxios.message ?? "Cập nhật thành công"
      });
    }
    if (updateStagePositionAxios.error) {
      reload();
      toggleMessage({
        type: "error",
        message: updateStagePositionAxios.error.message ?? ""
      });
    }
  }, [updateStagePositionAxios.error, updateStagePositionAxios.message]);

  useEffect(() => {
    if (shutWorkflowAxios.isSuccess)
      navigateSearch(ROUTE_PATH.WORKFLOWS, {
        tab: searchParams.get("tab") ?? "availability"
      });
    if (shutWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: shutWorkflowAxios.error.message ?? ""
      });
  }, [
    shutWorkflowAxios.isSuccess,
    shutWorkflowAxios.error,
    navigateSearch,
    searchParams.get("tab")
  ]);

  const handleConfirmShutWorkflow = () => {
    shutWorkflowAxios.request(
      WorkflowService.closeWorkflow(workflow!.board.id)
    );
  };
  const handleCloseShutWorkflowDialog = () => {
    setOpenShutWorkflowDialog(false);
  };

  const handleUpdateStagePosition = async ({
    homeId,
    destinationIndex,
    newBoard
  }: {
    homeId: string;
    destinationIndex: number;
    newBoard: IStage[];
  }) => {
    const request: IUpdateStagePositionRequest = {
      BoardId: workflow!.board.id,
      WorkflowMoveId: homeId,
      NewPosition: destinationIndex
    };

    setNewStages({
      oldStages: stages,
      newStages: newBoard,
      destinationIndex: destinationIndex
    });

    updateStagePositionAxios.request(StageService.updateStagePosition(request));
  };

  const handleUpdateTaskPosition = async ({
    force,
    targetId,
    destinationId,
    destinationIndex,
    destinationCardIndex,
    newBoard
  }: {
    force?: boolean;
    targetId: string;
    destinationId: string;
    destinationIndex: number;
    destinationCardIndex: number;
    newBoard: IStage[];
  }) => {
    const request: ITaskCardUpdatePositionRequest = {
      WorkflowId: destinationId,
      TaskCardMoveId: targetId,
      NewPosition: destinationCardIndex
    };

    setNewStages({
      oldStages: stages,
      newStages: newBoard,
      destinationIndex: force === true ? null : destinationIndex
    });

    updateTaskPositionAxios.request(TaskService.updateTaskPosition(request));
  };

  if (workflowAxios.isLoading === true && stages.length === 0)
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <ManageProjectWrapper active={false}>
      {workflow && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{workflow.board.name}</title>
        </Helmet>
      )}
      {workflow && (
        <Box
          className="board-project-header flex"
          sx={{
            height: {
              xs: tabIndex === 0 ? 150 : 107,
              md: 60
            },
            gap: "4px"
          }}
        >
          <Box
            className="board-project-icon"
            sx={{ width: { md: "50px" }, marginTop: "6px" }}
          >
            <DvrIcon sx={{ color: "#AAAAAA", width: 18, height: 18 }} />
          </Box>
          <Box className="board-project-header-info" sx={{ flexGrow: 1 }}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              className="board-project-title"
              sx={{ flexGrow: 1, marginTop: "8px" }}
            >
              {workflow && (
                <Box
                  display="flex"
                  width="100%"
                  sx={{
                    gap: {
                      xs: 1,
                      md: 3
                    },
                    flexWrap: {
                      xs: "wrap",
                      md: "nowrap"
                    }
                  }}
                >
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    lineHeight={1}
                    className="prevent-select"
                    marginRight="12px"
                    marginTop="2px"
                    component={"span"}
                    sx={{
                      lineHeight: "18.8px",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical"
                    }}
                  >
                    {workflow.board.name}
                  </Typography>
                  <Chip
                    size="small"
                    label={`${workflow.board.totalTask} nhiệm vụ`}
                    sx={{
                      backgroundColor: "#D7F3FF",
                      color: "#057EF0",
                      fontSize: "12px"
                    }}
                  />
                  <Box
                    className="board-project-tabs"
                    sx={{
                      height: 30
                    }}
                  >
                    <ul className="tab-list">
                      {workflow &&
                        tabItemsData.map((tabItem, i) => (
                          <li
                            key={tabItem.code}
                            onClick={() =>
                              navigateSearch(ROUTE_PATH.PROJECTS, {
                                id: searchParams.get("id")!,
                                type: tabItem.code!,
                                tab: searchParams.get("tab")!
                              })
                            }
                            className={`prevent-select ${tabIndex === i ? "active" : ""}`}
                          >
                            {tabItem.title}
                          </li>
                        ))}
                    </ul>
                  </Box>
                </Box>
              )}
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ height: 80 }}
              ></Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* {workflow && roleBoardByUser && tabIndex === 0 && (
        <BoardProject
          workflow={workflow.board}
          role={roleBoardByUser}
          stages={stages}
          tasks={tasks}
          onCloseWorkflow={id => setOpenShutWorkflowDialog(true)}
          onReload={() => setStagesUpdate(true)}s
          onUpdateStagePosition={handleUpdateStagePosition}
          onUpdateTaskPosition={handleUpdateTaskPosition}
          onStagesChanged={newStages => setStages(newStages)}
          onTasksChanged={newTasks => setTasks(newTasks)}
        />
      )} */}
      {workflow && roleBoardByUser && tabIndex === 0 && (
        <BoardProjectWrapper
          stages={stages.map((stage: IStage) => {
            stage.taskCards.sort((a, b) => a.position - b.position);
            return stage;
          })}
          setStages={newStages => setStages(newStages)}
          workflow={workflow.board}
          role={roleBoardByUser}
          onCloseWorkflow={id => setOpenShutWorkflowDialog(true)}
          onReload={reload}
          onUpdateStagePosition={handleUpdateStagePosition}
          onUpdateTaskPosition={handleUpdateTaskPosition}
          filter={
            <Box
              className="flex flex-row align-middle overflow-auto"
              sx={{ gap: "16px", padding: "8px" }}
            >
              <NameFilter
                placeholder={"Tìm kiếm công việc.."}
                filter={filter}
                onChange={value => {
                  setFilter({ ...filter, KeySearch: value });
                  if (!value) reload({ ...filter, KeySearch: value });
                }}
                onSearch={() => {
                  reload();
                }}
              />
              <TrackerFilter
                workflowId={workflow!.board.id!}
                filter={filter}
                setValue={trackers => {
                  if (trackers !== filter.PersonAssigned) {
                    setFilter({
                      ...filter,
                      PersonAssigned: trackers
                    });
                    reload({
                      ...filter,
                      PersonAssigned: trackers
                    });
                  }
                }}
              />
              <DateTimeFilter
                filter={filter}
                onChange={newRange => {
                  let isChanged = newRange !== filter.Date;
                  if (isChanged) {
                    setFilter({ ...filter, Date: newRange });
                    reload({ ...filter, Date: newRange });
                  }
                }}
              />
              <StageFilter
                stages={stages}
                filter={filter}
                onChange={newStage => {
                  let isChanged = filter.Workflow?.id !== newStage?.id;
                  if (isChanged) {
                    setFilter({ ...filter, Workflow: newStage });
                    reload({ ...filter, Workflow: newStage });
                  }
                }}
              />
            </Box>
          }
        />
      )}
      {workflow && roleBoardByUser && tabIndex === 1 && (
        <Manage workflow={workflow} />
      )}
      {tabIndex === 2 && <ProjectMagagerial id={workflow?.board.id!} />}
      {workflow && (
        <Modal
          title={"Xác nhận đóng luồng công việc"}
          textSubmit={"Xác nhận"}
          textClose="Huỷ bỏ"
          open={openShutWorkflowDialog}
          onSubmit={handleConfirmShutWorkflow}
          disabledSubmit={shutWorkflowAxios.isLoading}
          onClose={handleCloseShutWorkflowDialog}
          type="warning-red"
        >
          {"Bạn có chắc chắn muốn đóng luồng công việc này?"}
        </Modal>
      )}
    </ManageProjectWrapper>
  );
};

export default ManageProject;
