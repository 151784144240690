import { Box, Button, Modal, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Avatar } from "../../../../../../components";
import { envConfig } from "../../../../../../config/env.config";
import { EFileType } from "../../../../../../models/common/models.enum";
import { IAttachmentFile } from "../../../../../../models/Task";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ImageCustom from "../../../../../../components/ImageCustom";
import { useApi } from "../../../../../../hooks/useApi.hook";
import UploadFileService from "../../../../../../services/api/uploadFile.service";
import { IDownloadFile } from "../../../../../../models/Comment";
import { b64toBlob } from "../../../../../../common/handles/common.handles";
import FileImage from "./FileImage";
import { DownloadRounded } from "@mui/icons-material";
import "react-quill/dist/quill.core.css";

type Props = {
  name: string;
  time: Date | null;
  content: string;
  avatar: string;
  attachmentFiles?: IAttachmentFile[];
  margin?: string;
  isCreate: boolean;
};

const CommentBox = (props: Props) => {
  const { name, time, content, avatar, attachmentFiles, margin } = props;

  const downloadFile = useApi<IDownloadFile>({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "70vw",
    maxHeight: "100vh"
    // bgcolor: "background.paper"
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleDownloadFile(file: IAttachmentFile) {
    downloadFile.request(
      UploadFileService.downloadFile({
        path: file.path,
        fileName: file.fileName
      }).then(response => {
        if (response.success) {
          const blob = b64toBlob(
            response.data.fileContent,
            response.data.fileName
          );
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode!.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
    );
  }

  return (
    // <Box margin={margin} display="flex">
    //   <Avatar src={avatar} width="28px" height="28px" />
    //   <Box
    //     marginLeft="12px"
    //     flexGrow={1}
    //     sx={{
    //       backgroundColor: "#F5F7F8",
    //       borderRadius: "8px",
    //       padding: "8px 14px"
    //     }}
    //   >
    //     <Box flexGrow={1} display="flex">
    //       <Typography variant="subtitle2" fontSize="12px">
    //         {name}
    //       </Typography>
    //       <Typography variant="caption" fontSize="10px" marginLeft="8px">
    //         {moment(time).fromNow()}
    //       </Typography>
    //     </Box>
    //     <Box marginTop="4px">
    //       {/* <Typography variant="caption">{content}</Typography> */}
    //       <div
    //         // ref={containerRef}
    //         // style={{
    //         //   maxHeight: isFullDescription
    //         //     ? "none"
    //         //     : `${maxHeight}px`,
    //         //   overflow: "hidden",
    //         //   transition: "max-height 0.3s ease"
    //         // }}
    //         dangerouslySetInnerHTML={{
    //           __html: content ?? ""
    //         }}
    //       />
    //       <Box
    //         marginTop="8px"
    //         sx={{ display: "flex", flexDirection: "column" }}
    //       >
    //         {attachmentFiles &&
    //           attachmentFiles.map((file, idx) => (
    //             <Button
    //               key={idx}
    //               color="info"
    //               onClick={() => handleDownloadFile(file)}
    //             >
    //               <Box display="flex" alignItems="center" marginBottom="12px">
    //                 <FileImage type={file.extension ?? EFileType.doc} />
    //                 <Typography variant="caption" margin="0 8px">
    //                   {file.fileName}
    //                 </Typography>
    //                 <CloudDownloadOutlinedIcon
    //                   sx={{ fontSize: 16, color: "GrayText" }}
    //                 />
    //               </Box>
    //             </Button>
    //           ))}
    //       </Box>
    //     </Box>
    //   </Box>
    // </Box>
    <Box display="flex" marginBottom="14px">
      <Box>
        <Avatar src={avatar} width="28px" height="28px" />
      </Box>
      <Box marginLeft="10px">
        <Box
          sx={{
            backgroundColor: props.isCreate ? "#73b0ed12" : "#F5F7F8",
            borderRadius: "8px",
            padding: "12px 16px"
            // flexGrow: 1
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              color: "#596970",
              fontSize: "12px",
              marginBottom: "8px"
            }}
          >
            {name}
            <span>
              <Typography variant="caption" marginLeft="8px" fontSize="11px">
                {moment(time).fromNow()}
              </Typography>
            </span>
          </Typography>
          <div
            className="view ql-editor"
            dangerouslySetInnerHTML={{
              __html: content ?? ""
            }}
          />
          {attachmentFiles &&
            attachmentFiles.map((file, idx) =>
              file.extension === EFileType.png ||
              file.extension === EFileType.jpeg ||
              file.extension === EFileType.jpg ? (
                <Box marginTop="12px">
                  <ImageCustom
                    src={envConfig.MEDIA_ENDPOINT + file.path}
                    height="100px"
                    width="auto"
                    borderRadius="8px"
                    onClick={() => setOpen(true)}
                  />
                  <Modal open={open} onClose={handleClose}>
                    <Box sx={style}>
                      <Box>
                        <Button
                          endIcon={<DownloadRounded />}
                          sx={{ color: "white", marginBottom: "8px" }}
                        >
                          Tải xuống
                        </Button>
                      </Box>
                      <ImageCustom
                        src={envConfig.MEDIA_ENDPOINT + file.path}
                        height="auto"
                        width="auto"
                      />
                    </Box>
                  </Modal>

                  <Typography variant="caption">{file.fileName}</Typography>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" margin="8px 0">
                  <FileImage type={file.extension ?? EFileType.doc} />
                  <Typography variant="caption" margin="0 8px">
                    {file.fileName}
                  </Typography>
                  <Button
                    key={idx}
                    color="info"
                    onClick={() => handleDownloadFile(file)}
                  >
                    <CloudDownloadOutlinedIcon
                      sx={{ fontSize: 16, color: "GrayText" }}
                    />
                  </Button>
                </Box>
              )
            )}
        </Box>
        {/* <Box display="flex" marginTop="4px" color="#596970">
          <ImageCustom src={IconReply} width="16px" height="16px" />
          <Typography variant="caption" marginLeft="8px">
            Trả lời (2)
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default CommentBox;
