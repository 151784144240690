import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import { IRoleBoard, IStage } from "../../../../../models/Stage";
import { IRoleWorkflow } from "../../../../../models/workflow";
import { ITaskCard } from "../../../../../models/Task";
import { IUser } from "../../../../../models/user";
import { EWorkflowProcess } from "../../../../../models/common/models.enum";

/**
 * Context interface for managing project board functionality
 * @interface BoardContextValue
 *
 * @property {() => void} refresh - Refreshes the board state
 * @property {() => IStage[]} getColumns - Retrieves all columns/stages in the board
 * @property {() => IRoleBoard} getRoleBoard - Gets the user role permissions for the board
 * @property {(worflowId: string) => IRoleWorkflow | null} getRoleWorkflow - Gets role permissions for a specific workflow
 * @property {(card: ITaskCard) => void} openCardNewTab - Opens a task card in a new browser tab
 * @property {(card: ITaskCard) => void} viewCardDetail - Shows the detail view of a task card
 * @property {(column: IStage) => void} openDeleteColumnDialog - Opens dialog to delete a column
 * @property {(card: ITaskCard) => void} openDeleteCardDialog - Opens dialog to delete a task card
 * @property {(card: ITaskCard) => void} openUpdateCardDialog - Opens dialog to update a task card
 * @property {(card: ITaskCard) => void} openUpdateAssignCardDialog - Opens dialog to update task assignments
 * @property {(stage: IStage) => void} openAddColumnLeft - Opens dialog to add a column to the left of specified stage
 * @property {(stage: IStage) => void} openAddColumnRight - Opens dialog to add a column to the right of specified stage
 * @property {(stage: IStage) => void} openUpdateColumnDialog - Opens dialog to update a column
 * @property {(card: ITaskCard) => void} handleRollBackTask - Handles rolling back a task to previous state
 * @property {Function} onUpdateColumnPosition - Updates the position of a column
 * @property {string} onUpdateColumnPosition.homeId - ID of the column being moved
 * @property {number} onUpdateColumnPosition.destinationIndex - New index position for the column
 * @property {IStage[]} onUpdateColumnPosition.newBoard - Updated board state after move
 * @property {Function} handleUpdateTaskPosition - Updates the position of a task card
 * @property {ITaskCard} handleUpdateTaskPosition.target - Task card being moved
 * @property {EWorkflowProcess} handleUpdateTaskPosition.destinationWorkflowProcess - Target workflow process
 * @property {number} handleUpdateTaskPosition.homeIndex - Original index of the task
 * @property {number} handleUpdateTaskPosition.destinationIndex - Target index position
 * @property {number} handleUpdateTaskPosition.destinationCardIndex - Target card index
 * @property {IStage[]} handleUpdateTaskPosition.newBoard - Updated board state after move
 * @property {IUser | null} userInfo - Current user information
 */
export type BoardContextValue = {
  refresh: () => void;
  getColumns: () => IStage[];
  getRoleBoard: () => IRoleBoard;
  getRoleWorkflow: (worflowId: string) => IRoleWorkflow | null;
  openCardNewTab: (card: ITaskCard) => void;
  viewCardDetail: (card: ITaskCard) => void;
  openDeleteColumnDialog: (column: IStage) => void;
  openDeleteCardDialog: (card: ITaskCard) => void;
  openUpdateCardDialog: (card: ITaskCard) => void;
  openUpdateAssignCardDialog: (card: ITaskCard) => void;
  openAddColumnLeft: (stage: IStage) => void;
  openAddColumnRight: (stage: IStage) => void;
  openUpdateColumnDialog: (stage: IStage) => void;
  handleRollBackTask: (card: ITaskCard) => void;
  onUpdateColumnPosition: ({
    homeId,
    destinationIndex,
    newBoard
  }: {
    homeId: string;
    destinationIndex: number;
    newBoard: IStage[];
  }) => void;
  handleUpdateTaskPosition: ({
    target,
    homeIndex,
    destinationWorkflowProcess,
    destinationIndex,
    destinationCardIndex,
    newBoard,
    force
  }: {
    force?: boolean;
    target: ITaskCard;
    destinationWorkflowProcess: EWorkflowProcess;
    homeIndex: number;
    destinationIndex: number;
    destinationCardIndex: number;
    newBoard: IStage[];
  }) => void;
  userInfo: IUser | null;
};

export const BoardContext = createContext<BoardContextValue | null>(null);

export function useBoardContext(): BoardContextValue {
  const value = useContext(BoardContext);
  invariant(value, "cannot find BoardContext provider");
  return value;
}
