import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Typography
} from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { GroupPanelWrapper } from "./styles";
import WorkflowItem from "../WorkflowItem/workflow_item";
import { IWorkflowByTypeResponse } from "../../../../models/workflow";
import ExpandIcon from "../../../../assets/images/common/map_arrow_right.svg";
import { ImageCustom } from "../../../../components";

interface Props {
  workflow: IWorkflowByTypeResponse;
  tabCode: string;
}

/**
 * Component for displaying a group panel containing workflow items in an accordion layout.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.workflow - The workflow object containing board type and boards data
 * @param {string} props.workflow.typeBoard - The type/title of the workflow board group
 * @param {Array} props.workflow.boards - Array of board items to display
 * @param {string} props.tabCode - Code representing the current active tab
 *
 * @returns {JSX.Element} A group panel component with expandable accordion containing workflow items
 *
 * @example
 * ```tsx
 * <GroupPanel
 *   workflow={{
 *     typeBoard: "Project Board",
 *     boards: [{id: 1, ...boardData}]
 *   }}
 *   tabCode="tab1"
 * />
 * ```
 */
const GroupPanel = ({ workflow, tabCode }: Props) => {
  return (
    <GroupPanelWrapper>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={
            <Box sx={{ margin: "0 12px" }}>
              <ImageCustom src={ExpandIcon} height="14px" width="14px" />
            </Box>
          }
          aria-controls="panel1-content"
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Avatar
              sx={{
                bgcolor: "#3091F3",
                width: 30,
                height: 30,
                fontSize: 13,
                fontWeight: 700
              }}
            >
              {workflow.typeBoard
                .split(" ")
                .splice(0, 2)
                .map(e => e.charAt(0))
                .join("")}
            </Avatar>
            <Typography className="group-panel-title" fontSize={"16px"}>
              {workflow.typeBoard}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display={"flex"} flexWrap={"wrap"} flexDirection={"row"} gap={3}>
            {workflow.boards.map(item => (
              <WorkflowItem key={item.id} workflow={item} tabCode={tabCode} />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider></Divider>
    </GroupPanelWrapper>
  );
};

export default GroupPanel;
