import GroupPanel from "../GroupPanel/group_panel";
import { WorkflowBoardWrapper } from "./styles";
import { useCallback, useEffect, useState } from "react";
import WorkflowService from "../../../../services/api/workflow.service";
import { IWorkflowByTypeResponse } from "../../../../models/workflow";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import useAxios from "../../../../components/UseAxios/useAxios";
import { toggleMessage } from "../../../../components/Toast/Toast";

interface Props {
  isMyBoard?: boolean;
  isStatus?: boolean;
  tabCode: string;
}
/**
 * WorkflowBoard Component
 * Displays a board of workflows grouped by type with pagination support
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.isMyBoard - Flag to filter workflows by user ownership
 * @param {boolean} props.isStatus - Flag to filter workflows by status
 * @param {string} props.tabCode - Code representing the current tab
 *
 * @returns {JSX.Element} Rendered WorkflowBoard component
 *
 * The component handles:
 * - Fetching workflow data with pagination
 * - Grouping workflows by type
 * - Loading states and error handling
 * - "Load more" functionality
 * - Empty state display
 *
 * @example
 * ```tsx
 * <WorkflowBoard
 *   isMyBoard={true}
 *   isStatus={false}
 *   tabCode="tab1"
 * />
 * ```
 */
const WorkflowBoard = ({ isMyBoard, isStatus, tabCode }: Props) => {
  const [state, setState] = useState<{
    workflows: IWorkflowByTypeResponse[];
  }>({ workflows: [] });
  const [currentPage, setCurrentPage] = useState(1);

  let pageSize = 10;

  const workflowAxios = useAxios<IWorkflowByTypeResponse[]>({
    loading: "OnRequest"
  });

  const groupBy = useCallback(
    (xs: IWorkflowByTypeResponse[]) => {
      return xs.reduce((result, currentValue) => {
        const index = result.findIndex(
          e => currentValue.typeBoard === e.typeBoard
        );
        if (index > -1) {
          currentValue.boards.forEach(workflow => {
            if (!result[index].boards.find(e => e.id === workflow.id))
              result[index].boards.push(workflow);
          });
          return result;
        }
        result.push(currentValue);
        return result;
      }, state.workflows ?? []);
    },
    [state.workflows]
  );

  const getData = () => {
    workflowAxios.request(
      WorkflowService.getWorkflows({
        currentPage: currentPage,
        pageSize: pageSize,
        IsMyBoard: isMyBoard,
        IsStatus: isStatus
      })
    );
  };

  const handleLoadMore = () => {
    if (workflowAxios.paging!.totalCount > workflowAxios.paging!.pageSize) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  useEffect(() => {
    if (workflowAxios.isSuccess && workflowAxios.data) {
      const newWorkflows = groupBy([...state.workflows, ...workflowAxios.data]);

      setState({ workflows: newWorkflows });
    }
    if (workflowAxios.error) {
      toggleMessage({
        type: "error",
        message: workflowAxios.error.message ?? ""
      });
    }
  }, [workflowAxios.isSuccess, workflowAxios.error]);

  if (workflowAxios.isLoading === true)
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (workflowAxios.error) return <p>Error: {workflowAxios.error.message}</p>;

  return (
    <WorkflowBoardWrapper>
      {state.workflows &&
        state.workflows.map(workflow => (
          <GroupPanel
            key={workflow.typeBoard}
            workflow={workflow}
            tabCode={tabCode}
          />
        ))}
      {state.workflows && state.workflows.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%"
          }}
        >
          <Typography fontSize={13} color={"#808080"}>
            Danh sách trống
          </Typography>
        </Box>
      )}
      {workflowAxios.paging &&
        Math.ceil(
          workflowAxios.paging.totalCount / workflowAxios.paging.pageSize
        ) > workflowAxios.paging.currentPage && (
          <center>
            <Button
              onClick={handleLoadMore}
              variant="contained"
              sx={{ height: "34px", borderRadius: "2px", marginTop: 2 }}
            >
              Xem thêm
            </Button>
          </center>
        )}
    </WorkflowBoardWrapper>
  );
};

export default WorkflowBoard;
