import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Button, CircularProgress, Pagination } from "@mui/material";
import { IUser } from "../../../../models/user";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import { useEffect, useMemo, useState } from "react";
import {
  ETimeOffStatus,
  ETimeOffType
} from "../../../../models/common/models.enum";
import { sessionOptions, typeOptions } from "../../TimeOff";
import Avatar from "../../../../components/Avatar";
import { IDayOffDataItem, ITimeOff } from "../../../../models/timeoff";
import TimeOffDetailDialog from "../TimeOffDetailDialog/TimeOffDetailDialog";
import { IBasePaging } from "../../../../models/common/models.type";
import dayjs from "dayjs";

interface IProps {
  tab: any;
  loading?: boolean;
  updateType: string;
  canUpdateStatus: boolean;
  canDeleteTimeoff: boolean;
  selectable?: boolean;
  proposes: ITimeOff[];
  paging: IBasePaging;
  onPageChange: (page: number) => void;
  onProposeStatusChange?: () => void;
}

export interface ITimeOffRowData {
  index: number;
  id: string;
  title: string;
  description: string;
  type: ETimeOffType;
  creator: IUser | null;
  proposeAt: IDayOffDataItem[];
  status: ETimeOffStatus;
}

function createData(
  index: number,
  id: string,
  title: string,
  description: string,
  type: number,
  creator: IUser | null,
  proposeAt: IDayOffDataItem[],
  status: ETimeOffStatus
): ITimeOffRowData {
  return {
    index,
    id,
    title,
    description,
    type,
    creator,
    proposeAt,
    status
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof ITimeOffRowData;
  label: string;
  colSpan: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    colSpan: 1,
    disablePadding: true,
    label: "#"
  },
  {
    id: "title",
    colSpan: 5,
    disablePadding: true,
    label: "Tiêu đề"
  },
  {
    id: "creator",
    colSpan: 10,
    disablePadding: false,
    label: "Người tạo"
  },
  {
    id: "proposeAt",
    colSpan: 14,
    disablePadding: false,
    label: "Ngày đề xuất"
  },
  {
    id: "status",
    colSpan: 10,
    disablePadding: false,
    label: "Trạng thái"
  }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#03c847",
    fontSize: 14,
    lineHeight: 1.5
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    lineHeight: 1.5
  }
}));

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | null;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead sx={{ backgroundColor: "white !important" }}>
      <TableRow>
        {onSelectAllClick && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              sx={{ color: "#03c847" }}
              name={`select-all`}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            />
          </StyledTableCell>
        )}

        {headCells
          // .filter(e => (!onSelectAllClick ? e.id !== "status" : true))
          .map(headCell => (
            <StyledTableCell
              key={headCell.id}
              colSpan={headCell.colSpan}
              align={
                onSelectAllClick
                  ? "left"
                  : headCell.id === "id" && !onSelectAllClick
                    ? "center"
                    : "left"
              }
              sx={{
                color: "#03c847 !important",
                fontSize: 14
              }}
              padding={
                !onSelectAllClick && headCell.id === "id"
                  ? "normal"
                  : headCell.disablePadding
                    ? "none"
                    : "normal"
              }
            >
              {headCell.label}
            </StyledTableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
interface EnhancedTableToolbarProps {
  numSelected: number;
}
function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;
  if (numSelected <= 0) return null;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        },
        numSelected > 0 && {
          bgcolor: theme =>
            alpha("#54BD95", theme.palette.action.activatedOpacity)
        }
      ]}
    >
      <Typography sx={{ flex: "1 1 80%" }} color="white" variant="subtitle1">
        {numSelected} Đề xuất
      </Typography>
      <Button
        sx={{
          color: "white",
          width: "124px !important",
          "&.MuiButton-root": {
            "&.MuiButton-text": {
              "&:hover": {
                boxShadow: "none",
                color: "white !important",
                backgroundColor: "transparent"
              }
            }
          }
        }}
      >
        {"Phê duyệt tất cả"}
      </Button>
    </Toolbar>
  );
}
const TimeOffTitle = ({ type, reason }: { type: number; reason: string }) => {
  return (
    <Box>
      <Typography color="black" fontWeight={600} fontSize={13}>
        {reason}
      </Typography>
      <Typography color="#939393" fontWeight={400} fontSize={13}>
        {typeOptions.find(e => e.value === type)?.name ?? ""}
      </Typography>
    </Box>
  );
};
const TimeOffCreator = ({ creator }: { creator: IUser | null }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Avatar src={creator?.avatar} alt="Avatar-Image" width={30} height={30} />

      <Box
        sx={{
          marginLeft: "12px",
          height: "30px",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography fontWeight={500} lineHeight={1.3} fontSize={12}>
          {creator?.fullName}
        </Typography>

        <Typography fontSize={11} color={"#858585"}>
          {creator?.position}
        </Typography>
      </Box>
    </Box>
  );
};
const dayFromDateTime = (days: IDayOffDataItem[]) => {
  return days
    .sort((a, b) =>
      dayjs(a.dateTime, "DD/MM/YYYY").isAfter(dayjs(b.dateTime, "DD/MM/YYYY"))
        ? 1
        : -1
    )
    .reduce((group, day) => {
      const { dateTime } = day;
      const newDays = [...(group.get(dateTime) ?? []), day];
      group.set(dateTime, newDays);
      return group;
    }, new Map<string, IDayOffDataItem[]>());
};

const DateOffPropose = ({ days }: { days: IDayOffDataItem[] }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1
      }}
    >
      {Array.from(dayFromDateTime(days)).map(entries => (
        <Box
          key={entries[0]}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 0.5,
            alignItems: "center"
          }}
        >
          <DateRangeRoundedIcon sx={{ fontSize: 24, color: "#1b63a9" }} />
          <Box
            sx={{
              marginLeft: "12px",
              height: "30px",
              justifyContent: "flex-start",
              display: "flex",
              flexDirection: "column",
              gap: "2px"
            }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={600}
              lineHeight={1.3}
              fontSize={13}
              display={"inline"}
              sx={{ inlineSize: "max-content" }}
            >
              {entries[0]}
            </Typography>
            <Box display={"flex"} flexDirection={"row"} gap={0.5}>
              {entries[1].map((item, i) => (
                <Typography
                  variant="caption"
                  fontSize={12}
                  fontWeight={400}
                  key={item.id}
                  display={"inline"}
                  sx={{ inlineSize: "max-content" }}
                >
                  {i > 0 ? "・ " : ""}
                  {sessionOptions.find(e => e.value === item.session)?.name ??
                    ""}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const statusData = (status: ETimeOffStatus) => {
  switch (status) {
    case ETimeOffStatus.ManagerApprove:
      return {
        label: "Quản lí đã duyệt",
        color: "#03c847"
      };
    case ETimeOffStatus.CEOApprove:
      return {
        label: "Đã phê duyệt",
        color: "#03c847"
      };
    case ETimeOffStatus.Pending:
      return { label: "Đang chờ duyệt", color: "#267CDE" };
    case ETimeOffStatus.NotApprove:
      return { label: "Đã từ chối", color: "#fa6464" };
    default:
      return { label: "Đã huỷ bỏ", color: "#8c8c8c" };
  }
};

const TimeOffStatus = (status: ETimeOffStatus) => {
  return (
    <Box
      sx={{
        backgroundColor: statusData(status).color,
        width: 128,
        height: 32,
        borderRadius: 1,
        alignContent: "center",
        color: "white",
        fontSize: 14,
        fontWeight: 600
      }}
    >
      <center>{statusData(status).label}</center>
    </Box>
  );
};

const TimeOffTable = ({
  tab,
  loading,
  updateType,
  canUpdateStatus,
  canDeleteTimeoff,
  selectable,
  proposes,
  paging,
  onPageChange,
  onProposeStatusChange
}: IProps) => {
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [openDetail, setOpenDetail] = useState<ITimeOffRowData | null>(null);

  const rows = useMemo(() => {
    return proposes.map((propose, i) =>
      createData(
        i + 1,
        propose.id,
        propose.title,
        propose.description,
        propose.type,
        propose.user,
        propose.dayOff,
        propose.status
      )
    );
  }, [proposes]);

  useEffect(() => {
    setSelected([]);
  }, [tab, proposes]);

  const selectableRows = useMemo(
    () =>
      rows.filter(
        n =>
          n.status === ETimeOffStatus.Pending ||
          n.status === ETimeOffStatus.ManagerApprove
      ),
    [rows]
  );

  const canSelect = selectable && selectableRows.length > 0;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = selectableRows.map(n => n.index);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    row: ITimeOffRowData
  ) => {
    const selectedIndex = selected.indexOf(row.index);
    let newSelected: readonly number[] = [];
    if (
      row.status === ETimeOffStatus.Pending ||
      row.status === ETimeOffStatus.ManagerApprove
    ) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row.index);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    }
  };

  const handleClickRow = (row: ITimeOffRowData) => {
    setOpenDetail(row);
  };

  const handleTimeOffStatusChange = () => {
    onProposeStatusChange?.();
  };

  const visibleRows = React.useMemo(() => [...rows], [rows]);

  if (rows.length === 0 || loading == true)
    return (
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          mb: 2,
          flexGrow: "1",
          alignContent: "center"
        }}
      >
        <Typography
          color="black"
          fontSize={14}
          fontWeight={400}
          textAlign={"center"}
        >
          {loading == true && <CircularProgress />}
          {rows.length === 0 && "Không có dữ liệu"}
        </Typography>
      </Paper>
    );

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          mb: 2,
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center"
        }}
      >
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer sx={{ flexGrow: 1 }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={canSelect ? handleSelectAllClick : null}
              rowCount={selectableRows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row.index);
                const labelId = `timeoff-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.index}
                    sx={{ cursor: "pointer" }}
                  >
                    {canSelect && (
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          onClick={event => handleClick(event, row)}
                          color="primary"
                          disabled={
                            row.status !== ETimeOffStatus.Pending &&
                            row.status !== ETimeOffStatus.ManagerApprove
                          }
                          name={`select-row-${row.index}`}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId
                          }}
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align={canSelect ? "left" : "center"}
                      style={{ fontSize: 14, fontWeight: 600 }}
                    >
                      {row.index}
                    </StyledTableCell>

                    <StyledTableCell
                      align={"left"}
                      colSpan={5}
                      onClick={() => handleClickRow(row)}
                      padding="none"
                    >
                      {TimeOffTitle({
                        type: row.type,
                        reason: row.title
                      })}
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={10}
                      onClick={() => handleClickRow(row)}
                      align={"left"}
                    >
                      {TimeOffCreator({ creator: row.creator })}
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={14}
                      onClick={() => handleClickRow(row)}
                      align={"left"}
                    >
                      {DateOffPropose({ days: row.proposeAt })}
                    </StyledTableCell>
                    {
                      <StyledTableCell
                        colSpan={10}
                        onClick={() => handleClickRow(row)}
                        align={"left"}
                      >
                        {TimeOffStatus(row.status)}
                      </StyledTableCell>
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {paging.totalPages > 1 && (
          <Pagination
            count={paging.totalPages}
            page={paging.currentPage}
            sx={{
              padding: 1,
              "& .MuiPagination-ul": {
                svg: { fontSize: 20 },
                button: { fontSize: 13 }
              }
            }}
            onChange={(e, value) => onPageChange(value)}
          />
        )}
      </Paper>

      <TimeOffDetailDialog
        open={openDetail !== null}
        propose={openDetail}
        tab={tab}
        updateType={updateType}
        canUpdateStatus={canUpdateStatus}
        canDeleteTimeoff={canDeleteTimeoff}
        status={statusData(openDetail?.status ?? ETimeOffStatus.NotApprove)}
        dateOffGroup={dayFromDateTime}
        handleClose={() => setOpenDetail(null)}
        handleStatusChange={handleTimeOffStatusChange}
      />
    </>
  );
};
export default TimeOffTable;
