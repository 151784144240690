import JobList from "./components/Jobs/JobList";
import { IRoleBoard, IStageByWorkflowResponse } from "../../../models/Stage";

interface Props {
  workflow: IStageByWorkflowResponse;
}

const Manage = ({ workflow }: Props) => {
  return <JobList boardId={workflow.board.id} />;
};

export default Manage;
