import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  Typography,
  useMediaQuery
} from "@mui/material";
import moment from "moment";
import { TIME_FORMAT } from "../../../config/constants";
import { meetingRoomStatusColor } from "../../../models/common/models.enum";
import { IMeetingSchedule } from "../../../models/meeting";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import { theme } from "../../../theme/theme";
import { responsiveFontSizes } from "../../../utils/responsive";

type Props = {
  item: IMeetingSchedule;
};

const ScheduleCard = ({ item }: Props) => {
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const StyleWrapper = styled("div")({
    "@media (max-width: 868px)": {
      svg: { fontSize: 16 },
      "& .text": {
        ...responsiveFontSizes({
          fontsize: {
            xs: 14,
            sm: 14,
            md: 16,
            lg: 16,
            xl: 16
          },
          lineheight: {
            xs: 22.59,
            sm: 22.59,
            md: 22.59,
            lg: 22.59,
            xl: 22.59
          }
        })
      }
    }
  });

  const icons = [
    <AccessTimeFilledRoundedIcon sx={{ color: "#3091F3", fontSize: "16px" }} />, //pendiing
    <CheckCircleRoundedIcon sx={{ color: "#54BD95", fontSize: "16px" }} />, //confirm
    <DoDisturbOnRoundedIcon sx={{ color: "#ED3E47", fontSize: "16px" }} />, //Cancel
    <DoneAllRoundedIcon />, //Completed
    <HourglassFullRoundedIcon sx={{ color: "#E7B036", fontSize: "16px" }} /> //WaitCancel
  ];

  return matches ? (
    <StyleWrapper>
      <Box
        sx={{
          border: "1px solid #1c274c28",
          display: "flex",
          marginRight: "12px",
          borderRadius: "8px"
        }}
      >
        <IconButton
          sx={{
            backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
            height: "32px",
            width: "32px",
            borderRadius: "32px",
            alignContent: "center"
          }}
        >
          {icons[item.status]}
        </IconButton>
        <Box>
          <Typography className="text" variant="subtitle2" component="div">
            {item.title}
          </Typography>
          <Typography className="text" variant="caption">
            {moment(item.startDate).format(TIME_FORMAT)}
            {item.endDate && `- ${moment(item.endDate).format(TIME_FORMAT)}`}
          </Typography>
        </Box>
      </Box>
    </StyleWrapper>
  ) : (
    // <Box
    //   margin="8px 0"
    //   borderRadius="4px"
    //   padding="12px"
    //   display="flex"
    //   sx={{ backgroundColor: "white" }}
    // >
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} sm container>
    //       <Grid item xs container direction="column" spacing={2}>
    //         <Grid item xs>
    //           <Box display={"flex"}>
    //             <Box
    //               sx={{
    //                 backgroundColor: meetingRoomStatusColor(item.status).color,
    //                 width: "3px",
    //                 height: "auto",
    //                 marginRight: "12px"
    //               }}
    //             />
    //             <Typography variant="subtitle2" component="div">
    //               {item.title}
    //             </Typography>
    //           </Box>
    //           <Box display="flex" marginTop="6px">
    //             <AccessTimeRoundedIcon
    //               sx={{ fontSize: 16, marginRight: "4px", color: "GrayText" }}
    //             />
    //             <Typography variant="caption">
    //               {moment(item.startDate).format(TIME_FORMAT)}
    //               {item.endDate &&
    //                 `- ${moment(item.endDate).format(TIME_FORMAT)}`}
    //             </Typography>
    //           </Box>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //     <Grid item>
    //       <IconButton
    //         sx={{
    //           backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
    //           height: "32px",
    //           width: "32px",
    //           borderRadius: "32px",
    //           alignContent: "center"
    //         }}
    //       >
    //         {icons[item.status]}
    //       </IconButton>
    //     </Grid>
    //   </Grid>
    // </Box>
    <Card
      sx={{
        borderLeft: `4px solid ${meetingRoomStatusColor(item.status).color}`,
        marginBottom: "8px",
        padding: "8px 16px"
      }}
    >
      <Typography variant="subtitle2" color="#596970">
        {item.title}
      </Typography>
      <Typography variant="caption" color={"#7E969F"}>
        {item?.description ?? "Không có"}
      </Typography>
      <Box display="flex" marginTop={"4px"}>
        <Typography
          variant="caption"
          color={"#596970"}
          fontWeight="600"
          flexGrow={1}
        >
          {moment(item.startDate).format(TIME_FORMAT)}
          {item.endDate && ` - ${moment(item.endDate).format(TIME_FORMAT)}`}
        </Typography>
        <IconButton
          sx={{
            backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
            height: "20px",
            width: "20px",
            borderRadius: "32px",
            alignContent: "center"
          }}
        >
          {icons[item.status]}
        </IconButton>
        <Typography
          variant="caption"
          marginLeft={"4px"}
          color={meetingRoomStatusColor(item.status).color}
        >
          Chờ duyệt
        </Typography>
      </Box>
    </Card>
  );
};

export default ScheduleCard;
