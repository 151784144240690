import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

import * as liveRegion from "@atlaskit/pragmatic-drag-and-drop-live-region";

import { IRoleBoard, IStage } from "../../../models/Stage";
import { ITaskCard } from "../../../models/Task";
import {
  BoardContext,
  type BoardContextValue
} from "./components/DragAndDrop/board_context";

import CreateStageModal from "../CreateStageModal/create_stage_modal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Modal } from "../../../components";
import useAxios from "../../../components/UseAxios/useAxios";
import StageService from "../../../services/api/stage.service";
import { toggleMessage } from "../../../components/Toast/Toast";
import { Box, Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import TaskDetail from "../Task/components/JobDetail/JobDetail";
import variableStyles from "../../../theme/variable-styles";
import { BoardProjectWrapper } from "./styles";
import { Board } from "./components/DragAndDrop/board";
import { b64toBlob } from "../../../common/handles/common.handles";
import TaskService from "../../../services/api/task.service";
import {
  EStageType,
  EWorkflowProcess
} from "../../../models/common/models.enum";
import { authTokens } from "../../../services/services";
import { IDownloadFile } from "../../../models/Comment";
import { IUser } from "../../../models/user";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import AssignTaskModal from "../assignTaskModal/assign_task_modal";
import CreateTaskModal from "../CreateTaskModal/create_task_modal";
import ActionProject from "../ActionProject";
import UpdateTaskTransitionModal from "../UpdateTaskTransitionModal/UpdateTaskTransitionModal";
import { IWorkflow } from "../../../models/workflow";

type BoardProps = {
  workflow: IWorkflow;
  role: IRoleBoard;
  stages: IStage[];
  setStages: (newStages: IStage[]) => void;
  onReload: () => void;
  onCloseWorkflow: (id: string) => void;
  onUpdateStagePosition: ({
    homeId,
    destinationIndex,
    newBoard
  }: {
    homeId: string;
    destinationIndex: number;
    newBoard: IStage[];
  }) => void;
  onUpdateTaskPosition: ({
    force,
    targetId,
    destinationId,
    destinationIndex,
    destinationCardIndex,
    newBoard
  }: {
    force?: boolean;
    targetId: string;
    destinationId: string;
    destinationIndex: number;
    destinationCardIndex: number;
    newBoard: IStage[];
  }) => void;
  filter: ReactNode;
};

type PositionCreateState = {
  stageSelected?: IStage;
  isUpdate?: boolean;
  side: number;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * BoardProject component for managing project board visualization and interactions
 *
 * @component
 * @param {BoardProps} props - Component props
 * @param {IWorkflow} props.workflow - Workflow configuration object
 * @param {IStage[]} props.stages - Array of stage objects representing board columns
 * @param {Function} props.setStages - Function to update stages state
 * @param {string} props.role - User role for permission control
 * @param {Function} props.onReload - Callback function to reload board data
 * @param {Function} props.onCloseWorkflow - Callback function when closing workflow
 * @param {Function} props.onUpdateStagePosition - Callback for updating stage positions
 * @param {Function} props.onUpdateTaskPosition - Callback for updating task positions
 * @param {ReactNode} props.filter - Filter component to be rendered
 *
 * @returns {JSX.Element} Rendered BoardProject component
 *
 * @description
 * A complex board management component that handles:
 * - Drag and drop functionality for tasks and stages
 * - CRUD operations for tasks and stages
 * - Task assignments and transitions
 * - Excel export functionality
 * - Task detail viewing
 * - Stage and task position management
 * - User role-based permissions
 *
 * The component uses BoardContext to provide shared functionality to child components
 * and manages multiple modal dialogs for different operations.
 *
 * @example
 * <BoardProject
 *   workflow={workflowData}
 *   stages={stagesData}
 *   setStages={handleStagesUpdate}
 *   role="admin"
 *   onReload={handleReload}
 *   onCloseWorkflow={handleClose}
 *   onUpdateStagePosition={handleStageUpdate}
 *   onUpdateTaskPosition={handleTaskUpdate}
 *   filter={<FilterComponent />}
 * />
 */

const BoardProject = ({
  workflow,
  stages,
  setStages,
  role,
  onReload,
  onCloseWorkflow,
  onUpdateStagePosition,
  onUpdateTaskPosition,
  filter
}: BoardProps): JSX.Element => {
  const rollbackTaskAxios = useAxios<string>({ loading: "OnRequest" });
  const deleteStageAxios = useAxios<string>({ loading: "OnRequest" });
  const deleteTaskAxios = useAxios<string>({ loading: "OnRequest" });
  const [deleteStageState, setDeleteStageState] = useState<{
    stage: IStage;
  } | null>(null);
  const [deleteTaskState, setDeleteTaskState] = useState<{
    task: ITaskCard;
  } | null>(null);
  const [updateTaskState, setUpdateTaskState] = useState<{
    task: ITaskCard;
  } | null>(null);
  const [updateAssignTaskState, setUpdateAssignTaskState] = useState<{
    task: ITaskCard;
  } | null>(null);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const exportExcelByBoardIdAxios = useAxios<IDownloadFile>({
    loading: "OnRequest"
  });

  const [updateTaskTransition, setUpdateTaskTransition] = useState<{
    open: boolean;
    task: ITaskCard;
    stage: IStage;
    newStages: IStage[];
    index: number;
  } | null>();

  const hasStepStage = useMemo(
    () => stages.find(e => e.type === EStageType.Step),
    [stages]
  );

  useEffect(() => {
    authTokens.getUser().then(user => setUserInfo(user));
  }, []);

  useEffect(() => {
    if (rollbackTaskAxios.isSuccess) {
      onReload();
      toggleMessage({
        type: "success",
        message: rollbackTaskAxios.message ?? "Cập nhật thành công"
      });
    }
    if (rollbackTaskAxios.error) {
      toggleMessage({
        type: "error",
        message: rollbackTaskAxios.error.message ?? "Failed to rollback task"
      });
    }
  }, [
    rollbackTaskAxios.isSuccess,
    rollbackTaskAxios.error,
    rollbackTaskAxios.message
  ]);

  useEffect(() => {
    if (deleteTaskAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: deleteTaskAxios.message ?? ""
      });
      onReload();
      closeDeleteCardDialog(); // Close the dialog after deletion
    }
    if (deleteTaskAxios.error) {
      toggleMessage({
        type: "error",
        message: deleteTaskAxios.error.message ?? ""
      });
    }
  }, [
    deleteTaskAxios.error,
    deleteTaskAxios.isSuccess,
    deleteTaskAxios.message
  ]);

  const openCardNewTab = (card: ITaskCard) => {
    window.open(ROUTE_PATH.TASK + "?id=" + card.id, "_blank");
  };

  const handleDeleteTask = () => {
    deleteTaskAxios.request(TaskService.deleteTask(deleteTaskState!.task.id));
  };

  const handleRollBackTask = (card: ITaskCard) => {
    rollbackTaskAxios.request(TaskService.rollBack(card.id));
  };

  const openAddColumn = () => {
    setPositionCreateStage({ side: 1 });
  };

  const openAddColumnLeft = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 1 });
  };

  const openAddColumnRight = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 2 });
  };

  const handleUpdateWorkflowSuccess = () => {
    onReload();
  };

  const handleCopyWorkflowSuccess = () => {
    onReload();
  };

  const openUpdateColumnDialog = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 0, isUpdate: true });
  };

  const handleDeleteStage = useCallback(() => {
    deleteStageAxios.request(
      StageService.deleteStage(deleteStageState!.stage!.id as string)
    );
  }, [deleteStageState]);

  const openDeleteColumnDialog = useCallback((column: IStage) => {
    setDeleteStageState({ stage: column }); // Open the delete confirmation dialog
  }, []);

  const closeDeleteColumnDialog = () => {
    setDeleteStageState(null); // Close the delete confirmation dialog
  };

  const openDeleteCardDialog = useCallback((card: ITaskCard) => {
    setDeleteTaskState({ task: card }); // Open the delete confirmation dialog
  }, []);

  const closeDeleteCardDialog = useCallback(() => {
    setDeleteTaskState(null); // Close the delete confirmation dialog
  }, []);

  const openUpdateAssignCardDialog = useCallback((card: ITaskCard) => {
    setUpdateAssignTaskState({ task: card });
  }, []);

  const closeUpdateAssignCardDialog = useCallback(() => {
    setUpdateAssignTaskState(null);
  }, []);
  const openUpdateCardDialog = useCallback((card: ITaskCard) => {
    setUpdateTaskState({ task: card });
  }, []);

  const closeUpdateCardDialog = useCallback(() => {
    setUpdateTaskState(null);
  }, []);

  const handleExportExcelByBoardId = () => {
    exportExcelByBoardIdAxios.request(
      TaskService.exportExcelTaskByBoardId(workflow.id)
    );
  };

  const handleUpdateTaskPosition = useCallback(
    ({
      force,
      target,
      homeIndex,
      destinationWorkflowProcess,
      destinationIndex,
      destinationCardIndex,
      newBoard
    }: {
      force?: boolean;
      target: ITaskCard;
      destinationWorkflowProcess: EWorkflowProcess;
      homeIndex: number;
      destinationIndex: number;
      destinationCardIndex: number;
      newBoard: IStage[];
    }) => {
      const destination = newBoard[destinationIndex];

      if (
        destination.id !== target.workflowId &&
        destinationWorkflowProcess === EWorkflowProcess.TaskAssigneeDecides
      ) {
        setUpdateTaskTransition({
          open: true,
          task: target,
          stage: stages[destinationIndex],
          newStages: newBoard,
          index: destinationCardIndex
        });
        return;
      }

      setStages(newBoard);

      onUpdateTaskPosition({
        targetId: target.id,
        destinationId: destination.id,
        destinationIndex: destinationIndex,
        destinationCardIndex: destinationCardIndex,
        newBoard: newBoard
      });
    },
    [onUpdateTaskPosition]
  );

  useEffect(() => {
    if (exportExcelByBoardIdAxios.isSuccess && exportExcelByBoardIdAxios.data) {
      const blob = b64toBlob(
        exportExcelByBoardIdAxios.data.fileContent,
        exportExcelByBoardIdAxios.data.fileName
      );
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", exportExcelByBoardIdAxios.data.fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
      window.URL.revokeObjectURL(url);
    }

    if (exportExcelByBoardIdAxios.error) {
      toggleMessage({
        type: "error",
        message: exportExcelByBoardIdAxios.error?.message ?? ""
      });
    }
  }, [exportExcelByBoardIdAxios.isSuccess, exportExcelByBoardIdAxios.error]);

  const [positionSelectedStage, setPositionCreateStage] =
    useState<PositionCreateState | null>(null);

  const [showCardDetailState, setshowCardDetailState] =
    useState<ITaskCard | null>(null);

  useEffect(() => {
    return liveRegion.cleanup();
  }, []);

  const refresh = useCallback(() => {
    onReload();
  }, [onReload]);

  const getColumns = useCallback(() => {
    return stages;
  }, [stages]);

  const getRoleBoard = useCallback(() => {
    return role;
  }, [role]);
  const getRoleWorkflow = useCallback(
    (worflowId: string) => {
      const stage = stages.find(stage => stage.id === worflowId);
      if (!stage) return null;
      return stages.find(stage => stage.id === worflowId)!.roleWorkflowByUser;
    },
    [stages]
  );

  const viewCardDetail = useCallback((card: ITaskCard) => {
    setshowCardDetailState(card);
  }, []);

  useEffect(() => {
    if (deleteStageAxios.isSuccess) {
      onReload();
      closeDeleteColumnDialog(); //
    }
    if (deleteStageAxios.error) {
      toggleMessage({
        type: "error",
        message: deleteStageAxios.error.message ?? ""
      });
    }
  }, [deleteStageAxios.isSuccess, deleteStageAxios.error]);

  const contextValue: BoardContextValue = useMemo(() => {
    return {
      refresh,
      getColumns,
      getRoleBoard,
      getRoleWorkflow,
      openAddColumnLeft,
      openAddColumnRight,
      openDeleteColumnDialog,
      openUpdateColumnDialog,
      openDeleteCardDialog,
      openUpdateCardDialog,
      openUpdateAssignCardDialog,
      handleRollBackTask,
      openCardNewTab,
      viewCardDetail,
      onUpdateColumnPosition: onUpdateStagePosition,
      handleUpdateTaskPosition,
      userInfo
    };
  }, [
    refresh,
    getColumns,
    getRoleBoard,
    getRoleWorkflow,
    openCardNewTab,
    viewCardDetail,
    openDeleteColumnDialog,
    openDeleteCardDialog,
    openUpdateCardDialog,
    openUpdateColumnDialog,
    openUpdateAssignCardDialog,
    openAddColumnLeft,
    openAddColumnRight,
    handleUpdateTaskPosition,
    onUpdateStagePosition,
    handleRollBackTask,
    userInfo
  ]);

  return (
    <BoardContext.Provider value={contextValue}>
      <BoardProjectWrapper className="flex flex-col">
        {filter}
        <Board
          initial={{ columns: stages }}
          setColumns={stages => setStages(stages)}
        />
      </BoardProjectWrapper>
      <ActionProject
        workflow={workflow}
        role={role}
        hasStepStage={!!hasStepStage}
        onCreateTaskSuccess={() => onReload()}
        onCreateStage={() => openAddColumn()}
        onCloseWorkflow={() => onCloseWorkflow(workflow.id!)}
        onUpdateWorkflowSuccess={() => handleUpdateWorkflowSuccess()}
        onCopyWorkflowSuccess={() => handleCopyWorkflowSuccess()}
        onExportFileExcelByBoardId={() => handleExportExcelByBoardId()}
      />
      {updateAssignTaskState && (
        <AssignTaskModal
          open={updateAssignTaskState !== null}
          task={updateAssignTaskState.task}
          onUpdateSuccess={() => onReload()}
          handleClose={closeUpdateAssignCardDialog}
        />
      )}
      <CreateTaskModal
        open={updateTaskState !== null}
        taskId={updateTaskState?.task.id}
        isUpdate
        workflowId={workflow.id}
        onCreateSuccess={refresh}
        handleClose={closeUpdateCardDialog}
      />
      <CreateStageModal
        open={positionSelectedStage !== null}
        workflow={workflow}
        isUpdate={positionSelectedStage?.isUpdate}
        {...positionSelectedStage}
        handleClose={() => setPositionCreateStage(null)}
        handleSuccess={() => onReload()}
      />
      <Modal
        title={"Xác nhận xóa nhiệm vụ"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={deleteTaskState !== null}
        onSubmit={handleDeleteTask}
        disabledSubmit={deleteTaskAxios.isLoading}
        onClose={closeDeleteCardDialog}
        type={"warning-red"}
      >
        {"Bạn có chắc chắn muốn xóa nhiệm vụ này?"}
      </Modal>
      <Modal
        title={"Xác nhận xóa giai đoạn"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={deleteStageState !== null}
        onSubmit={handleDeleteStage}
        disabledSubmit={deleteStageAxios.isLoading}
        onClose={closeDeleteColumnDialog}
        type="warning-red"
      >
        {"Bạn có chắc chắn muốn xóa giai đoạn này?"}
      </Modal>
      {updateTaskTransition && (
        <UpdateTaskTransitionModal
          open={updateTaskTransition !== null}
          workflowId={updateTaskTransition.stage.id}
          newStages={updateTaskTransition.newStages}
          task={updateTaskTransition.task}
          onUpdateSuccess={() => {
            onUpdateTaskPosition({
              force: true,
              targetId: updateTaskTransition.task.id,
              destinationId: updateTaskTransition.stage.id,
              destinationCardIndex: updateTaskTransition.index,
              destinationIndex: updateTaskTransition.newStages.findIndex(
                stage => stage.id === updateTaskTransition.stage.id
              ),
              newBoard: updateTaskTransition.newStages
            });
            setStages(updateTaskTransition.newStages);
            setTimeout(() => {
              setUpdateTaskTransition(null);
            }, 300);
          }}
          handleClose={() => {
            setStages(stages);
            setUpdateTaskTransition(null);
          }}
        />
      )}
      {showCardDetailState && (
        <Dialog
          open={showCardDetailState !== null}
          keepMounted
          maxWidth="xl"
          fullWidth
          onClose={() => setshowCardDetailState(null)}
          TransitionComponent={Transition}
          sx={{
            "& .MuiPaper-root": {
              width: "calc(100% - 12px)",
              margin: 0
            }
          }}
          aria-describedby="task-detail-dialog"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#267CDE",
              padding: "8px 16px"
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={500}
              color={variableStyles.NaturalColor300}
            >
              {showCardDetailState?.title}
            </Typography>
            <CloseRoundedIcon
              onClick={() => setshowCardDetailState(null)}
              sx={{ color: "white", height: 24, width: 24 }}
            />
          </Box>

          <Box sx={{ overflowY: "auto" }}>
            <TaskDetail
              id={showCardDetailState!.id as string}
              onJobUpdated={() => onReload()}
            />
          </Box>
        </Dialog>
      )}
    </BoardContext.Provider>
  );
};
export default BoardProject;
