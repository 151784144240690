/**
 * Performs a shallow comparison between two objects to determine if they are equal.
 * 
 * @param obj1 - First object to compare
 * @param obj2 - Second object to compare
 * @returns `true` if objects have the same keys and values (by reference), `false` otherwise
 * 
 * @example
 * ```typescript
 * const a = { x: 1, y: 2 };
 * const b = { x: 1, y: 2 };
 * const c = { x: 1 };
 * 
 * isShallowEqual(a, b); // true
 * isShallowEqual(a, c); // false
 * ```
 */
export function isShallowEqual(
    obj1: Record<string, unknown>,
    obj2: Record<string, unknown>,
): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }
    return keys1.every((key1) => Object.is(obj1[key1], obj2[key1]));
}