import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { ETaskStatus } from "../../../../../models/common/models.enum";

interface IProps {
  type?: string;
  bg?: string;
}

export const TaskCardWrapper = styled("div")<IProps>(({ theme, type, bg }) => {
  return {
    backgroundColor: bg,
    padding: "2px",
    borderRadius: "12px",
    "& .Overflow2Line": {
      lineHeight: "18.8px",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: "3",
      WebkitBoxOrient: "vertical"
    },

    "& .TaskCardName": {
      // height: 47.6,
      fontSize: 14,
      fontWeight: 500
    },
    "& .TaskShortDesc": {
      padding: "6px 0px",
      fontSize: 13,
      fontWeight: 300
    },
    "& .MuiChip-root": {
      height: "unset",
      color: "white",
      fontWeight: 500,
      paddingLeft: 6,
      paddingRight: 6,
      "& span": {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    "& .TaskStatus": {
      marginTop: "auto",
      alignItems: "center",
      gap: 2,
      color: "#aa0000",
      fontSize: 12,
      "& .MuiSvgIcon-root": {
        width: 16,
        height: 16
      }
    },
    "& .TaskDeadline": {
      color:
        type === `${ETaskStatus.Inprogress}` ? "#818181" : "white !important"
    },

    "& .MuiButton-root": {
      fontSize: 13
    }
  };
});
