import {
  draggable,
  dropTargetForElements
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import invariant from "tiny-invariant";

import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { unsafeOverflowAutoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/unsafe-overflow/element";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { DragLocationHistory } from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import { preserveOffsetOnSource } from "@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";

import { Card, CardShadow } from "../TaskCard/card";
import { IStage } from "../../../../../models/Stage";
import {
  getColumnData,
  isCardData,
  isCardDropTargetData,
  isColumnData,
  isDraggingACard,
  isDraggingAColumn,
  TCardData
} from "../DragAndDrop/data";
import { isShallowEqual } from "../DragAndDrop/is-shallow-equal";
import { isSafari } from "../DragAndDrop/is-safari";
import {
  EStageType,
  EWorkflowProcess
} from "../../../../../models/common/models.enum";
import { Box, Button, Divider, Typography } from "@mui/material";
import BasicMenu, { IMenuItem } from "../../../../../components/Menu/BasicMenu";
import { MoreHorizRounded } from "@mui/icons-material";
import CreateTaskModal from "../../../CreateTaskModal/create_task_modal";
import { useBoardContext } from "../DragAndDrop/board_context";

type TColumnState =
  | {
      type: "is-card-over";
      isOverChildCard: boolean;
      dragging: DOMRect;
    }
  | {
      type: "is-column-over";
    }
  | {
      type: "idle";
    }
  | {
      type: "is-dragging";
    };

const stateStyles: { [Key in TColumnState["type"]]: string } = {
  idle: "cursor-grab",
  "is-card-over": "outline outline-2 outline-neutral-50",
  "is-dragging": "opacity-40",
  "is-column-over": "bg-slate-900"
};

const idle = { type: "idle" } satisfies TColumnState;

interface StageColumnProps {
  name: string;
  column: IStage;
  workflowId: string;
  isShowMenu?: boolean;
  firstStage?: boolean;
}
/**
 * A memoized component for rendering out the card.
 *
 * Created so that state changes to the column don't require all cards to be rendered
 */
const CardList: React.FC<{
  props: { column: IStage; userId: string | undefined };
}> = memo(({ props }) => {
  return (
    <>
      {props.column.taskCards.map(card => (
        <Card
          key={card.id}
          card={card}
          columnId={props.column.id}
          canReassignTask={
            props.column.reassignedWork ===
              EWorkflowProcess.TaskAssigneeDecides &&
            props.userId === card.personAssignedId
          }
        />
      ))}
    </>
  );
});

CardList.displayName = "CardList";

/**
 * Column component represents a stage column in a project board.
 * It handles drag-and-drop functionality, scrolling, and column management.
 *
 * @component
 * @param {StageColumnProps} props - Component props
 * @param {IStage} props.column - Column data object containing stage information
 * @param {string} props.workflowId - ID of the workflow the column belongs to
 * @param {boolean} props.isShowMenu - Flag to determine if column menu should be displayed
 * @param {boolean} props.firstStage - Flag indicating if this is the first stage column
 * @param {string} props.name - Display name of the column
 *
 * @features
 * - Drag and drop functionality for cards and columns
 * - Auto-scrolling when dragging cards
 * - Column management (edit, delete, add left/right)
 * - Permission-based actions
 * - Different visual states for Complete, Cancel, and Step stages
 * - Task creation capability for Step type columns
 *
 * @visualStates
 * - idle: Default state
 * - is-dragging: When column is being dragged
 * - is-card-over: When a card is being dragged over
 * - is-column-over: When another column is being dragged over
 *
 * @permissions
 * Column actions are available based on user roles:
 * - Creator
 * - Process Management
 * - Stage Management
 *
 * @returns A draggable column component with header, scrollable card list, and optional task creation button
 */
export function Column(props: StageColumnProps) {
  const { column, workflowId, isShowMenu, firstStage, name } = props;
  const {
    userInfo,
    refresh,
    getRoleBoard,
    openDeleteColumnDialog,
    openUpdateColumnDialog,
    openAddColumnLeft,
    openAddColumnRight
  } = useBoardContext();
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const outerFullHeightRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<TColumnState>(idle);

  const roleBoard = useMemo(() => getRoleBoard(), [getRoleBoard]);

  const hasPermission = useMemo(
    () =>
      roleBoard.isCreator ||
      roleBoard.isProcessManagement ||
      column.roleWorkflowByUser?.isStageManagement,
    [roleBoard, column]
  );

  const itemsPadding = { width: "100%", padding: "11px 12px" };
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  useEffect(() => {
    const outer = outerFullHeightRef.current;
    const scrollable = scrollableRef.current;
    const header = headerRef.current;
    const inner = innerRef.current;
    invariant(outer);
    invariant(scrollable);
    invariant(header);
    invariant(inner);

    const data = getColumnData({ column });

    function setIsCardOver({
      data,
      location
    }: {
      data: TCardData;
      location: DragLocationHistory;
    }) {
      const innerMost = location.current.dropTargets[0];
      const isOverChildCard = Boolean(
        innerMost && isCardDropTargetData(innerMost.data)
      );

      const proposed: TColumnState = {
        type: "is-card-over",
        dragging: data.rect,
        isOverChildCard
      };
      // optimization - don't update state if we don't need to.
      setState(current => {
        if (isShallowEqual(proposed, current)) {
          return current;
        }
        return proposed;
      });
    }

    return combine(
      draggable({
        element: header,
        getInitialData: () => data,
        canDrag: () =>
          column.type === EStageType.Step &&
          !(
            roleBoard.isCreator === false &&
            roleBoard.isProcessManagement === false
          ),
        onGenerateDragPreview({ source, location, nativeSetDragImage }) {
          const data = source.data;
          invariant(isColumnData(data));
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: preserveOffsetOnSource({
              element: header,
              input: location.current.input
            }),
            render({ container }) {
              // Simple drag preview generation: just cloning the current element.
              // Not using react for this.
              const rect = inner.getBoundingClientRect();
              const preview = inner.cloneNode(true);
              invariant(preview instanceof HTMLElement);
              preview.style.width = `${rect.width}px`;
              preview.style.height = `${rect.height}px`;

              // rotation of native drag previews does not work in safari
              if (!isSafari()) {
                preview.style.transform = "rotate(4deg)";
              }

              container.appendChild(preview);
            }
          });
        },
        onDragStart() {
          setState({ type: "is-dragging" });
        },
        onDrop() {
          setState(idle);
        }
      }),
      dropTargetForElements({
        element: outer,
        getData: () => data,
        canDrop({ source }) {
          return (
            isDraggingACard({ source }) ||
            (isDraggingAColumn({ source }) && column.type === EStageType.Step)
          );
        },
        getIsSticky: () => true,
        onDragStart({ source, location }) {
          if (isCardData(source.data)) {
            setIsCardOver({ data: source.data, location });
          }
        },
        onDragEnter({ source, location }) {
          if (isCardData(source.data)) {
            setIsCardOver({ data: source.data, location });
            return;
          }
          if (
            isColumnData(source.data) &&
            source.data.column.id !== column.id
          ) {
            setState({ type: "is-column-over" });
          }
        },
        onDropTargetChange({ source, location }) {
          if (isCardData(source.data)) {
            setIsCardOver({ data: source.data, location });
            return;
          }
        },
        onDragLeave({ source }) {
          if (
            isColumnData(source.data) &&
            source.data.column.id === column.id
          ) {
            return;
          }
          setState(idle);
        },
        onDrop() {
          setState(idle);
        }
      }),
      autoScrollForElements({
        canScroll({ source }) {
          return isDraggingACard({ source });
        },
        getConfiguration: () => ({
          maxScrollSpeed: "standard"
        }),
        element: scrollable
      }),
      unsafeOverflowAutoScrollForElements({
        element: scrollable,
        getConfiguration: () => ({
          maxScrollSpeed: "standard"
        }),
        canScroll({ source }) {
          return isDraggingACard({ source });
        },
        getOverflow() {
          return {
            forTopEdge: {
              top: 1000
            },
            forBottomEdge: {
              bottom: 1000
            }
          };
        }
      })
    );
  }, [column]);
  const menuItems: IMenuItem[] = [
    {
      value: "edit",
      label: (
        <Box sx={itemsPadding} onClick={() => openUpdateColumnDialog(column)}>
          Chỉnh sửa giai đoạn
        </Box>
      )
    },
    {
      value: "left_add",
      label: (
        <Box sx={itemsPadding} onClick={() => openAddColumnLeft(column)}>
          Thêm 1 giai đoạn bên trái
        </Box>
      )
    },
    {
      value: "right_add",
      label: (
        <Box sx={itemsPadding} onClick={() => openAddColumnRight(column)}>
          Thêm 1 giai đoạn bên phải
        </Box>
      )
    },
    {
      value: "divider",
      label: (
        <Divider
          sx={{
            height: "1px",
            width: "90%",
            marginInline: 1
          }}
        />
      )
    },
    {
      value: "delete",
      label: (
        <Box
          sx={{ ...itemsPadding, color: "#ff4e4e" }}
          onClick={() => openDeleteColumnDialog(column)}
        >
          Xoá giai đoạn
        </Box>
      )
    }
  ];

  const getColor = () => {
    switch (column.type) {
      case EStageType.Complete:
        return "#D3F1A7";
      case EStageType.Cancel:
        return "#FDE2E2";
      case EStageType.Step:
        return `${column?.color ?? "#F1F5F6"}`;
      default:
        return "#F1F5F6";
    }
  };

  return (
    <div
      className="flex flex-shrink-0 select-none flex-col"
      ref={outerFullHeightRef}
      style={{
        width: "256px",
        maxHeight: "85%"
      }}
    >
      <div
        className={`flex max-h-full flex-col rounded-lg ${stateStyles[state.type]}`}
        ref={innerRef}
        style={{
          marginLeft: "6px",
          marginRight: "6px",
          paddingBottom: "8px",
          borderRadius: "12px",
          backgroundColor: getColor()
        }}
        {...{ ["data-block-board-panning"]: true }}
      >
        {/* Extra wrapping element to make it easy to toggle visibility of content when a column is dragging over */}
        <div
          className={`flex max-h-full flex-col ${state.type === "is-column-over" ? "invisible" : ""}`}
        >
          <div
            ref={headerRef}
            style={{
              marginBottom: "4px"
            }}
          >
            <Box
              className=" flex flex-row justify-between align-center text-wrap"
              sx={{
                "& #basic-button": {
                  "& .menu-button": {
                    padding: 0
                  }
                },
                padding: "8px 12px 0 8px"
              }}
            >
              <Typography
                className="font-bold prevent-select flex-grow"
                fontSize={14}
                marginLeft="8px"
                sx={{
                  color:
                    column.type === EStageType.Complete
                      ? "#337E60"
                      : column.type === EStageType.Cancel
                        ? "#B44746"
                        : "#2D393E"
                }}
              >
                {name} ({column.taskCards.length})
              </Typography>
              {isShowMenu && hasPermission && (
                <div className="flex-0">
                  <BasicMenu
                    key={name}
                    onChange={() => {}}
                    label={
                      <MoreHorizRounded sx={{ padding: 0, fontSize: 20 }} />
                    }
                    options={menuItems}
                    padding={0}
                  />
                </div>
              )}
              {!isShowMenu && <Box height={24} />}
            </Box>
          </div>
          <div
            className="flex flex-col overflow-y-auto [overflow-anchor:none] [scrollbar-color:theme(colors.slate.500)_theme(colors.transparent)] [scrollbar-width:thin]"
            ref={scrollableRef}
            style={{
              padding:
                scrollableRef.current &&
                state.type !== "is-card-over" &&
                (scrollableRef.current.offsetHeight <
                  scrollableRef.current.scrollHeight ||
                  scrollableRef.current.offsetWidth <
                    scrollableRef.current.scrollWidth)
                  ? "0 0 8px 8px"
                  : "0 8px"
            }}
          >
            <CardList props={{ column: column, userId: userInfo?.id }} />
            {state.type === "is-card-over" && !state.isOverChildCard ? (
              <div className="flex-shrink-0 ">
                <CardShadow dragging={state.dragging} />
              </div>
            ) : null}
          </div>
          {column.type === EStageType.Step && firstStage && (
            <Button
              variant="contained"
              sx={{
                height: "34px",
                backgroundColor: "rgba(255, 255, 255,0.3)",
                border: "1px dashed #59697052",
                color: "#596970ab",
                margin: "8px 8px 0 8px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#596970",
                  fontWeight: "700"
                }
              }}
              onClick={() => setOpenCreate(true)}
            >
              <Typography fontSize="12px" fontWeight="400">
                Thêm nhiệm vụ
              </Typography>
            </Button>
          )}
        </div>
      </div>
      {column.type === EStageType.Step && firstStage && (
        <CreateTaskModal
          open={openCreate}
          workflowId={workflowId}
          onCreateSuccess={refresh}
          handleClose={() => setOpenCreate(false)}
        />
      )}
    </div>
  );
}
