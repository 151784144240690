import { Box, Button, Typography } from "@mui/material";
import { IAttachmentFile } from "../../../../../models/Task";
import { useApi } from "../../../../../hooks/useApi.hook";
import CommentService from "../../../../../services/api/comment.service";
import { useEffect, useRef, useState } from "react";

import { IUser } from "../../../../../models/user";
import { Avatar, ImageCustom } from "../../../../../components";
import UploadFileService from "../../../../../services/api/uploadFile.service";
import { toggleMessage } from "../../../../../components/Toast/Toast";
import CommentInput from "./components/CommentInput";
import CommentBox from "./components/CommentBox";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { authTokens } from "../../../../../services/services";
import UserService from "../../../../../services/api/user.service";
import { IComment } from "../../../../../models/Comment";
import { CommentWrapper } from "./styles";
import IconReply from "../../../../../assets/images/common/chat_square_line_chat_line.svg";
import { KeyboardArrowDownRounded } from "@mui/icons-material";

type CommentContainerProps = {
  comment: IComment;
  taskId: string;
  userId?: string;
  refetch: Function;
};

const CommentContainer = (props: CommentContainerProps) => {
  const { comment, taskId, refetch } = props;
  const [files, setfiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isReply, setIsReply] = useState(false);
  const [page, setPage] = useState<number | null>(null);
  const [data, setData] = useState<IComment[]>([]);
  const inputRef = useRef<any>(null);
  const createReply = useApi<IComment>({});
  const uploadFileReply = useApi<IAttachmentFile[]>({});
  const getCommentChild = useApi<IComment[]>({ isFetch: true, isPaging: true });
  useEffect(() => {
    setData([...data, ...(getCommentChild.data ?? [])]);
  }, [getCommentChild.data]);

  function handleGetReply() {
    getCommentChild.request(
      CommentService.getChildren({
        CommentParentId: comment.id,
        CurrentPage: page ?? 1,
        PageSize: 5
      })
    );
  }

  useEffect(() => {
    if (page) handleGetReply();
  }, [page]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setfiles([...files, e.target.files[0]]);
    }
  };

  const handleDeleteFile = (file: File) => {
    const newFiles = files.filter(f => f !== file);
    setfiles(newFiles);
  };

  function createComment(attachmentFiles?: IAttachmentFile[]) {
    createReply.request(
      CommentService.create({
        Description: inputValue,
        TaskId: taskId,
        ParentCommentId: comment.id,
        AttachmentFiles: attachmentFiles
      }).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          setInputValue("");
          refetch();
        } else {
          if (attachmentFiles) {
            uploadFileReply.request(
              UploadFileService.deleteFiles(attachmentFiles)
            );
          }
        }
      })
    );
  }

  const handleSubmit = () => {
    const filesData = new FormData();
    filesData.append("FolderName", "Comment");
    files
      .filter(e => e.size > 0)
      .forEach(file => filesData.append("Files", file));
    if (files.length) {
      uploadFileReply.request(
        UploadFileService.uploadFile(filesData).then(response => {
          if (response.success && response.data) {
            createComment(response.data);
          } else {
            toggleMessage({
              type: "error",
              message: "Upload file xảy ra lỗi, vui lòng thử lại"
            });
          }
        })
      );
    } else {
      createComment();
    }
  };

  return (
    <Box marginBottom="8px" sx={{ position: "relative" }}>
      {/* <Button
        sx={{ position: "absolute", top: "8px", right: 0 }}
        onClick={() => setIsReply(!isReply)}
      >
        <ShortcutRoundedIcon sx={{ fontSize: 16, color: "dodgerblue" }} />
        <Typography variant="caption" color="dodgerblue" marginLeft="4px">
          Trả lời
        </Typography>
      </Button> */}
      <CommentBox
        name={comment.users?.fullName ?? "Không có dữ liệu"}
        time={comment.createdDate}
        content={comment.description}
        avatar={comment.users?.avatar ?? "Không có dữ liệu"}
        attachmentFiles={comment.attachmentFiles}
        isCreate={comment.users.id === props.userId}
      />

      <Button
        startIcon={<ImageCustom src={IconReply} width="16px" height="16px" />}
        endIcon={
          comment?.totalCountCommentChildren !== null &&
          comment.totalCountCommentChildren > 0 && (
            <KeyboardArrowDownRounded
              sx={{
                fontSize: 16,
                transform: `rotate(${isReply ? 180 : 0}deg)`
              }}
            />
          )
        }
        sx={{
          cursor: "pointer",
          color: "#596970",
          marginLeft: "40px",
          marginTop: "-22px"
        }}
        onClick={() => {
          setIsReply(!isReply);
          setData([]);
          if (comment?.totalCountCommentChildren !== null && !isReply) {
            handleGetReply();
          }
        }}
      >
        <Typography variant="caption" marginLeft="8px">
          Trả lời
          {comment?.totalCountCommentChildren
            ? ` (${comment?.totalCountCommentChildren}) `
            : ""}
        </Typography>
      </Button>

      {/* {comment?.totalCountCommentChildren && !getCommentChild.data && (
        <Button sx={{ marginLeft: "42px" }} onClick={handleGetReply}>
          <Typography variant="caption" color="GrayText">
            Xem thêm {comment?.totalCountCommentChildren} trả lời
          </Typography>
        </Button>
      )} */}

      {data && (
        <ul className="depth1">
          {data.map((cmt, index) => (
            <li key={cmt.id}>
              <CommentBox
                // key={cmt.id}
                margin={`0 0 ${index === comment?.totalCountCommentChildren! - 1 ? 0 : 18}px 0`}
                name={cmt.users.fullName}
                time={cmt.createdDate}
                content={cmt.description}
                avatar={cmt.users.avatar}
                attachmentFiles={cmt.attachmentFiles}
                isCreate={cmt.userId === props.userId}
              />
            </li>
          ))}
          {/* <Box display="flex" margin="12px 0">
            <Divider
              orientation="vertical"
              flexItem
              sx={{ margin: "0 16px 0 50px ", borderStyle: "dashed" }}
            />
            <Box>
              {data.map((cmt, index) => (
                <CommentBox
                  key={cmt.id}
                  margin={`0 0 ${index === comment?.totalCountCommentChildren! - 1 ? 0 : 18}px 0`}
                  name={cmt.users.fullName}
                  time={cmt.createdDate}
                  content={cmt.description}
                  avatar={cmt.users.avatar}
                  attachmentFiles={cmt.attachmentFiles}
                />
              ))}
            </Box>
          </Box> */}

          {getCommentChild.pagging &&
            data.length !== getCommentChild.pagging?.totalCount &&
            isReply && (
              <Box marginLeft="48px">
                <Button
                  // variant="contained"
                  size="small"
                  color="info"
                  sx={{ marginLeft: "28px", borderRadius: "8px" }}
                  onClick={() => setPage(page ?? 1 + 1)}
                >
                  Xem thêm bình luận
                </Button>
              </Box>
            )}

          {isReply && (
            <CommentInput
              isReply
              isLoading={createReply.loading}
              files={files}
              value={inputValue}
              inputRef={inputRef}
              onSubmit={handleSubmit}
              onDelete={handleDeleteFile}
              onChange={(e: string) => setInputValue(e)}
              handleFileChange={handleFileChange}
            />
          )}
        </ul>
      )}
    </Box>
  );
};

type Props = {
  taskId: string;
};

/**
 * CommentTask Component - Handles comment functionality for tasks
 *
 * @component
 * @param {Props} props - Component properties
 *
 * @state {File[]} files - Array of files to be uploaded with comments
 * @state {string} inputValue - Current value of the comment input
 * @state {number} currentPage - Current page number for pagination
 * @state {IComment[]} data - Array of comments
 * @state {IUser | null} userInfo - Current user information
 *
 * @hooks
 * - useApi<IComment[]> getPage - Handles fetching paginated comments
 * - useApi<IComment> create - Handles comment creation
 * - useApi<IAttachmentFile[]> uploadFile - Handles file uploads
 * - useApi<IUser> getInfo - Handles fetching user information
 *
 * @methods
 * - fetchData() - Fetches comment data based on current page
 * - handleFileChange(e) - Handles file input changes
 * - handleDeleteFile(file) - Removes a file from the files array
 * - createComment(attachmentFiles?) - Creates a new comment with optional attachments
 * - handleReset() - Resets component state after operations
 * - handleSubmit() - Handles comment submission with file uploads
 *
 * @returns {JSX.Element} A comment section with input field, file attachments, and comment list
 */
const CommentTask = (props: Props): JSX.Element => {
  const [files, setfiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<IComment[]>([]);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const inputRef = useRef<any>(null);

  const getPage = useApi<IComment[]>({ isFetch: true, isPaging: true });
  const create = useApi<IComment>({});
  const uploadFile = useApi<IAttachmentFile[]>({});
  const getInfo = useApi<IUser>({});

  useEffect(() => {
    setData([...data, ...(getPage.data ?? [])]);
  }, [getPage.data]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) getInfo.request(UserService.getSingle(userInfo.id));
  }, [userInfo]);

  function fetchData() {
    getPage.request(
      CommentService.getPage({
        TaskCardId: props.taskId,
        CurrentPage: currentPage,
        PageSize: 5
      })
    );
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const list = Array.from(e.target?.files);
      setfiles(files.concat(list));
    }
  };

  const handleDeleteFile = (file: File) => {
    const newFiles = files.filter(f => f !== file);
    setfiles(newFiles);
  };

  function createComment(attachmentFiles?: IAttachmentFile[]) {
    create.request(
      CommentService.create({
        Description: inputValue,
        TaskId: props.taskId,
        ParentCommentId: null,
        AttachmentFiles: attachmentFiles
      }).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          handleReset();
        } else {
          if (attachmentFiles) {
            uploadFile.request(UploadFileService.deleteFiles(attachmentFiles));
          }
        }
      })
    );
  }
  function handleReset() {
    setInputValue("");
    setData([]);
    fetchData();
    setfiles([]);
    inputRef.current.value = null;
  }

  const handleSubmit = () => {
    const filesData = new FormData();
    filesData.append("FolderName", "Comment");
    files
      .filter(e => e.size > 0)
      .forEach(file => filesData.append("Files", file));
    if (files.length) {
      uploadFile.request(
        UploadFileService.uploadFile(filesData).then(response => {
          if (response.success && response.data) {
            createComment(response.data);
          } else {
            toggleMessage({
              type: "error",
              message: "Upload file xảy ra lỗi, vui lòng thử lại"
            });
          }
        })
      );
    } else {
      createComment();
    }
  };

  return (
    <CommentWrapper>
      <Box sx={{ display: "flex", marginBottom: "14px" }}>
        <Avatar
          src={userInfo?.avatar}
          alt="Avatar-Image"
          width={30}
          height={30}
        />

        <Box
          sx={{
            marginLeft: "12px",
            height: "30px",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={500}
            lineHeight={1.3}
            fontSize={12}
          >
            {userInfo?.fullName}
          </Typography>

          <Typography variant="caption" fontSize={11} color={"#858585"}>
            {getInfo.data?.userName} - {getInfo.data?.position}
          </Typography>
        </Box>
      </Box>
      <CommentInput
        files={files}
        isLoading={create.loading}
        inputRef={inputRef}
        onSubmit={handleSubmit}
        onDelete={handleDeleteFile}
        value={inputValue}
        onChange={(e: string) => {
          setInputValue(e);
        }}
        handleFileChange={handleFileChange}
      />

      {/* <TextEditorQuill
        // {...field}
        placeholder="Nhập nội dung..."
        toolbar={true}
        editorStyle={{
          border: "1px solid #D3DCDF",
          borderRadius: "8px",
          width: "100%",
          height: "80px"
        }}
        onChange={e => setInputValue(e)}
        value={inputValue ?? ""}
      /> */}
      {/* <Box display={"flex"} marginTop="8px">
        <Box display={"flex"} flexGrow={1}>
          <Typography variant="subtitle2" marginRight="12px">
            Tệp đính kèm:
          </Typography>
          <Box display={"flex"}>
            {files &&
              files.map((f, idx) => (
                <Chip
                  key={idx}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: "8px",
                    marginBottom: "6px",
                    borderRadius: "8px",
                    padding: "4px",
                    border: "1px solid #DEDEDE"
                  }}
                  label={f?.name}
                  onDelete={() => handleDeleteFile(f)}
                  avatar={<FileImage type={`.${f?.type.split("/")[1]}`} />}
                />
              ))}
          </Box>
          <Box textAlign="center">
            <input
              name="avatar"
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleFileChange}
              className="input-file-upload"
              hidden
            />
            <IconButton
              sx={{ width: "24px", height: "24px" }}
              onClick={() => inputRef.current.click()}
            >
              <AttachFileRoundedIcon sx={{ rotate: "45deg", fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
        {!isQuillEmpty(inputValue) && (
          <Button
            variant="contained"
            size="small"
            color="info"
            sx={{ height: "32px", borderRadius: "8px" }}
            onClick={handleSubmit}
          >
            Gửi
          </Button>
        )}
      </Box> */}

      <Typography
        variant="subtitle2"
        sx={{ marginTop: "16px", marginBottom: "12px", color: "#7E969F" }}
      >
        BÌNH LUẬN ({data.length})
      </Typography>

      {/* {data &&
        data.map(comment => (
          <CommentContainer
            key={comment.id}
            comment={comment}
            taskId={props.taskId}
            refetch={handleReset}
          />
        ))} */}

      {/* Sửa giao diện  */}
      {data && (
        <ul className="depth0">
          {data.map(comment => (
            <li key={comment.id} className="parent">
              <CommentContainer
                // key={comment.id}
                comment={comment}
                taskId={props.taskId}
                refetch={handleReset}
                userId={userInfo?.id}
              />
            </li>
          ))}
        </ul>
      )}

      {data.length !== getPage.pagging?.totalCount && (
        <>
          <Button onClick={() => setCurrentPage(currentPage + 1)}>
            <Typography variant="subtitle2">
              Xem thêm bình luận <ExpandMoreRoundedIcon sx={{ fontSize: 16 }} />
            </Typography>
          </Button>
        </>
      )}
      <Box height="40px" />
    </CommentWrapper>
  );
};

export default CommentTask;
