import { Control, UseFormTrigger } from "react-hook-form";
import { AutocompleteForm } from "../../../../components/controls/AutocompleteForm";
import { IUser } from "../../../../models/user";
import { debounce, set } from "lodash";
import { ITag, ITagReq } from "../../../../models/user";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import UserService from "../../../../services/api/user.service";
import _debounce from "lodash/debounce";
import { TagType } from "../../../../models/common/models.enum";
import useAxios from "../../../../components/UseAxios/useAxios";
import { CircularProgress, Typography } from "@mui/material";

interface Props {
  name: string;
  control?: Control<any, any>;
  errors: any;
  type?: TagType;
  store?: "workflow" | "stage";
  storeId?: string;
  isError: boolean;
  placeholder: string | undefined;
  multiple?: boolean;
  limit?: number;
  optionDisabled?: IUser[];
  defaultValues?: ITag[];
  trigger?: UseFormTrigger<any>;
  disabled?: boolean;
}

/**
 * A component that provides a tag input field with autocomplete functionality.
 *
 * @component
 * @param {object} props - The component props
 * @param {string} props.name - The name of the input field
 * @param {Control} props.control - The form control object
 * @param {object} props.errors - Form validation errors
 * @param {boolean} props.isError - Whether the input has an error
 * @param {boolean} props.multiple - Whether multiple tags can be selected
 * @param {number} props.limit - Maximum number of tags that can be selected
 * @param {string} props.placeholder - Placeholder text for the input
 * @param {ITag[]} props.optionDisabled - Array of tags that should be disabled in the dropdown
 * @param {TagType} props.type - The type of tags to filter
 * @param {'workflow' | 'stage'} props.store - The context where the component is used
 * @param {string} props.storeId - ID of the workflow or stage
 * @param {ITag[]} props.defaultValues - Default selected tags
 * @param {Function} props.trigger - Function to trigger form validation
 * @param {boolean} props.disabled - Whether the input is disabled
 *
 * @returns {JSX.Element} A controlled autocomplete input component for tag selection
 *
 * @example
 * <TagInput
 *   name="users"
 *   control={control}
 *   errors={errors}
 *   multiple={true}
 *   placeholder="Select users..."
 * />
 */
export const TagInput = ({
  name,
  control,
  errors,
  isError,
  multiple,
  limit,
  placeholder,
  optionDisabled,
  type,
  store,
  storeId,
  defaultValues,
  trigger,
  disabled
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ITag[]>([]);
  const [noOptionsText, setnoOptionsText] = useState<ReactNode | undefined>();
  const optionDisabledItems: ITag[] | undefined =
    optionDisabled &&
    optionDisabled?.map(
      option =>
        ({
          id: option.id,
          username: option.userName,
          fullName: option.fullName,
          type: TagType.User
        }) as ITag
    );

  const search = useAxios<ITag[]>({ loading: "OnRequest" });

  const getTags = async (value: string) => {
    if (value !== "") {
      search.reset();
      let request: ITagReq = {
        CurrenPage: 1,
        PageSize: 10,
        KeySearch: value
      };
      if (type !== undefined) request.Type = type;

      if (store === "workflow" && storeId) {
        search.request(
          UserService.getUserOrDepartmentByWorkflowId(request, storeId)
        );
      }
      if (store === "stage" && storeId) {
        search.request(
          UserService.getUserOrDepartmentByStageId(request, storeId)
        );
      }

      if (!store) {
        search.request(UserService.getUserOrDepartment(request));
      }
    } else {
      setOptions([]);
      setLoading(false);
    }
  };

  const [handleTagSearch] = useState(() => _debounce(getTags, 500));

  useEffect(() => {
    setnoOptionsText(
      <center>
        <CircularProgress size={"24px"} />
      </center>
    );

    handleTagSearch(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (search.isSuccess) {
      setOptions(search.data ?? []);
      setnoOptionsText(undefined);
    }
    if (search.error) {
      setnoOptionsText(
        <Typography fontSize={14} key="noOptionsText">
          {"Đã có lỗi xảy ra"}
        </Typography>
      );
    }
  }, [search.isSuccess, search.error]);

  return (
    <AutocompleteForm<ITag>
      multiple={multiple ?? true}
      name={name}
      control={control}
      errors={errors}
      limit={limit}
      defaultValue={defaultValues}
      inputValue={inputValue}
      onInputChanged={(_, v) => setInputValue(v)}
      id={"tags-" + name}
      isError={isError}
      options={options}
      filterSelectedOptions={true}
      noOptionsText={noOptionsText}
      open={inputValue !== undefined && inputValue.length >= 1}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionTag={option => option.username}
      getOptionLabel={option => option.fullName + option.username}
      renderOption={option => (
        <Typography fontSize={13} fontWeight={400}>
          {option.fullName}
        </Typography>
      )}
      size={"small"}
      placeholder={placeholder}
      optionDisabled={optionDisabledItems}
      loading={loading}
      trigger={trigger}
      disabled={disabled}
    />
  );
};
