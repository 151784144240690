import { styled } from "@mui/material";
import variableStyles from "../../../../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  "& .title": {
    fontWeight: "600",
    fontSize: "13px"
  },
  "& .Overflow2Line": {
    marginTop: "4px",
    lineHeight: "16.8px",
    fontSize: "12px",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical"
  },
  "& .progress-box": {
    height: "8px",
    width: "28px",
    // backgroundColor: "green",
    marginRight: "2px",
    borderRadius: "32px",
    "& .active": {
      background: "darken(tomato, 20%)",
      color: "white",
      "&:after": {
        background: "darken(tomato, 20%)",
        color: "white"
      }
    }
  },
  "& .content": {
    fontWeight: "500",
    fontSize: "12px",
    margin: "4px 0",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    msTextOverflow: "ellipsis"
  },
  "& .MuiTable-root": {
    width: "calc(100vw - 280px)",
    margin: "16px  auto",
    backgroundColor: "white"
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: variableStyles.NaturalColor50
  },
  "& .MuiTableHead-root": {
    backgroundColor: "transparent"
  },
  "& .MuiTableCell-root": {
    borderBottom: "0.5px solid #E7E9EF",
    borderRight: "0.5px solid #E7E9EF",
    borderTop: "0.5px solid #E7E9EF",
    padding: "6px 16px"
  },
  "& .MuiTableCell-head": {
    backgroundColor: "white",
    padding: "6px 16px ",
    fontSize: "13px",
    color: "GrayText",
    fontWeight: "700"
  },

  "& .MuiChip-sizeSmall": {
    fontSize: "12px",
    fontWeight: "600",
    padding: "0 2px"
  }
}));
