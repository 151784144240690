import {
  Box,
  FormControl,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { ControlForm, DragAndDropFile, Modal } from "../../../components";
import InputForm from "../../../components/controls/InputForm";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TextEditorQuill from "../../../components/TextEditorQuill";
import { ITag } from "../../../models/user";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import { CircularProgress } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/vi";
import {
  IAttachmentFile,
  ITask,
  ITaskCard,
  ITaskCardCreateRequest,
  ITaskCardUpdateRequest
} from "../../../models/Task";
import TaskService from "../../../services/api/task.service";
import { toggleMessage } from "../../../components/Toast/Toast";
import useAxios from "../../../components/UseAxios/useAxios";
import UploadFileService from "../../../services/api/uploadFile.service";

import { TagType } from "../../../models/common/models.enum";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { Wrapper } from "./styles";
import ReactQuill from "react-quill";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";

dayjs.extend(utc);
dayjs.extend(timezone);
const today = dayjs();

interface IProps {
  open: boolean;
  workflowId: string;
  taskId?: string;
  isUpdate?: boolean;
  onCreateSuccess: (newTaskCard?: ITaskCard) => void;
  handleClose: () => void;
  hasDocument?: boolean;
}
interface DataForm {
  taskName: string;
  assign: ITag[];
  description: string;
  followers: ITag[];
  files: File[];
  deadline: dayjs.Dayjs | null;
  color: string;
}

/**
 * Modal component for creating and updating tasks in a project management system.
 *
 * @component
 * @param {IProps} props - Component props
 * @param {string} props.workflowId - ID of the workflow the task belongs to
 * @param {boolean} props.open - Controls visibility of the modal
 * @param {string} [props.taskId] - ID of the task (required for updates or creating subtasks)
 * @param {boolean} [props.isUpdate] - Flag indicating if this is an update operation
 * @param {boolean} [props.hasDocument=true] - Flag to show/hide document-related fields
 * @param {Function} props.handleClose - Callback function to close the modal
 * @param {Function} props.onCreateSuccess - Callback function called after successful task creation/update
 *
 * @description
 * This modal provides functionality for:
 * - Creating new tasks
 * - Creating subtasks
 * - Updating existing tasks
 * - Assigning users to tasks
 * - Adding followers to tasks
 * - Setting task color
 * - Adding descriptions with rich text editor
 * - Setting deadlines with date/time picker
 * - Uploading and managing attachments
 *
 * The component handles file uploads, deletions, and maintains state for:
 * - Attached files
 * - Deleted files
 * - Form data
 * - API request states
 *
 * Form validation is implemented using Yup schema validation for:
 * - Required task name
 * - Valid deadline dates
 *
 * @example
 * <CreateTaskModal
 *   workflowId="123"
 *   open={true}
 *   handleClose={() => setModalOpen(false)}
 *   onCreateSuccess={(task) => handleTaskCreated(task)}
 * />
 */
const CreateTaskModal = ({
  workflowId,
  open,
  taskId,
  isUpdate,
  hasDocument = true,
  handleClose,
  onCreateSuccess
}: IProps) => {
  const isSubTask = taskId !== undefined && !isUpdate;
  const quillRef = useRef<ReactQuill>(null);
  const createTaskAxios = useAxios<ITaskCard>({ loading: "OnRequest" });
  const getTaskAxios = useAxios<ITask>({ loading: "OnRequest" });
  const updateTaskAxios = useAxios<ITaskCard>({ loading: "OnRequest" });
  const uploadFileAxios = useAxios<IAttachmentFile[]>({ loading: "OnRequest" });
  const deleteFileAxios = useAxios<string>({ loading: "OnRequest" });
  const [files, setFiles] = useState<IAttachmentFile[]>([]);
  const [filesDeleted, setFilesDeleted] = useState<File[]>([]);
  const [updateLoaded, setUpdateLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (open === true) {
      setFiles([]);
      setFilesDeleted([]);

      reset({
        taskName: "",
        assign: [],
        followers: [],
        description: "",
        files: [],
        deadline: null
      });

      createTaskAxios.reset();
      getTaskAxios.reset();
      updateTaskAxios.reset();
      uploadFileAxios.reset();
      deleteFileAxios.reset();
      if (isUpdate) {
        handleGetTaskInfo();
      }
    }
  }, [open]);

  useEffect(() => {
    if (createTaskAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: createTaskAxios.message ?? "Tạo nhiệm vụ thành công"
      });
      onCreateSuccess(createTaskAxios.data);
      handleClose();
    } else if (createTaskAxios.error) {
      toggleMessage({
        type: "error",
        message: createTaskAxios.error?.message ?? ""
      });
      if (uploadFileAxios.data) {
        uploadFileAxios.data.forEach(file =>
          deleteFileAxios.request(UploadFileService.deleteFile(file))
        );
      }
    }
  }, [createTaskAxios.error, createTaskAxios.isSuccess]);

  useEffect(() => {
    if (uploadFileAxios.error)
      toggleMessage({
        type: "error",
        message: uploadFileAxios.error?.message ?? ""
      });
    if (open && uploadFileAxios.isSuccess && uploadFileAxios.data) {
      const newFiles = [...files, ...(uploadFileAxios.data ?? [])];

      const values = getValues();

      if (!isUpdate) handleCreateTask(values, newFiles);

      if (isUpdate) handleUpdateTask(values, newFiles);
    }
  }, [uploadFileAxios.error, uploadFileAxios.isSuccess]);

  useEffect(() => {
    if (getTaskAxios.error) {
      toggleMessage({
        type: "error",
        message: getTaskAxios.error?.message ?? ""
      });
      handleClose();
    }
    if (getTaskAxios.isSuccess && getTaskAxios.data) {
      let taskData = getTaskAxios.data;
      setValue("color", taskData.taskCard?.color ?? "white");
      setValue("description", taskData.taskCard.description);
      setValue("taskName", taskData.taskCard.title);
      if (taskData.taskCard.personAssigned) {
        const assignUser = taskData.taskCard.personAssigned;
        setValue("assign", [
          {
            id: assignUser.id,
            username: assignUser.userName,
            fullName: assignUser.fullName
          }
        ]);
      }
      if (taskData.taskCard.taskTrackers) {
        const trackers = taskData.taskCard.taskTrackers;

        setValue(
          "followers",
          trackers.map(e => ({
            id: e.id,
            username: e.userName,
            fullName: e.fullName
          }))
        );
      }

      setValue(
        "files",
        taskData.taskCard.attachmentFiles.map(
          e => new File([], e.fileName, { type: e.path })
        )
      );
      setValue(
        "deadline",
        taskData.taskCard.deadLine ? dayjs(taskData.taskCard.deadLine) : null
      );
      setFiles(taskData.taskCard.attachmentFiles);
      setUpdateLoaded(true);
    }
  }, [getTaskAxios.error, getTaskAxios.isSuccess]);

  useEffect(() => {
    if (updateTaskAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateTaskAxios.message ?? "Chỉnh sửa nhiệm vụ thành công"
      });
      onCreateSuccess(updateTaskAxios.data);
      handleClose();
    } else if (updateTaskAxios.error) {
      toggleMessage({
        type: "error",
        message: updateTaskAxios.error?.message ?? ""
      });
      if (uploadFileAxios.data) {
        deleteFileAxios.request(
          UploadFileService.deleteFiles(uploadFileAxios.data!)
        );
      }
    }
  }, [updateTaskAxios.error, updateTaskAxios.isSuccess]);

  const onSubmit = useCallback(
    (data: DataForm) => {
      //delete server files Deleted
      const serverFilesDeleted = [...files].filter(file =>
        filesDeleted
          .filter(e => e.size === 0)
          .map(e => e.type)
          .includes(file.path.toLowerCase())
      );
      if (filesDeleted.length > 0 && serverFilesDeleted.length > 0) {
        let newFiles = [...files];
        newFiles = newFiles.filter(e => !serverFilesDeleted.includes(e));
        setFiles(newFiles);

        deleteFileAxios.request(
          UploadFileService.deleteFiles(serverFilesDeleted)
        );
      }
      // upload file
      if (data.files.filter(e => e.size > 0).length > 0) {
        const filesData = new FormData();
        filesData.append("FolderName", "Task");

        data.files
          .filter(e => e.size > 0)
          .forEach(file => filesData.append("Files", file));

        uploadFileAxios.request(UploadFileService.uploadFile(filesData));
      } else {
        let newFiles = [...files];
        const serverFilesDeleted = [...files].filter(file =>
          filesDeleted
            .filter(e => e.size === 0)
            .map(e => e.type)
            .includes(file.path.toLowerCase())
        );
        newFiles = newFiles.filter(e => !serverFilesDeleted.includes(e));
        if (!isUpdate) handleCreateTask(data, newFiles);

        if (isUpdate) handleUpdateTask(data, newFiles);
      }
    },
    [files, filesDeleted]
  );

  const handleGetTaskInfo = () => {
    getTaskAxios.request(TaskService.getSingle(taskId!));
  };

  const handleCreateTask = useCallback(
    (data: DataForm, files: IAttachmentFile[]) => {
      const hasAssign = data.assign && data.assign[0];
      const hasFollowers = data.followers && data.followers[0];

      const request: ITaskCardCreateRequest = {
        Title: data.taskName,
        boardId: workflowId,
        description: data.description,
        AttachmentFiles: files,
        deadLine: data.deadline
          ? dayjs.tz(data.deadline, "YYYY-MM-DDTHH:MM:SS", "Etc/GMT-7").format()
          : null,
        color: data.color
      };
      if (hasAssign) request.PersonAssignedId = hasAssign.id;
      if (hasFollowers)
        request.TaskTrackerIds = data.followers.map(fl => fl.id).join(",");
      if (isSubTask) request.TaskCardParentId = taskId;

      createTaskAxios.request(TaskService.createTask(request));
    },
    []
  );

  const handleUpdateTask = (data: DataForm, files: IAttachmentFile[]) => {
    const request: ITaskCardUpdateRequest = {
      id: taskId!,
      title: data.taskName,
      workflowId: getTaskAxios.data?.taskCard.workflowId,
      boardId: workflowId,
      description: data.description,
      attachmentFiles: files,
      personAssignedId: data.assign?.[0]?.id ?? null,
      taskTrackerIds: data.followers?.length
        ? data.followers.map(fl => fl.id).join(",")
        : null,
      deadLine: data.deadline
        ? dayjs.tz(data.deadline, "YYYY-MM-DDTHH:MM:SS", "Etc/GMT-7").format()
        : null,
      color: data.color
    };

    updateTaskAxios.request(TaskService.updateTask(request));
  };

  const validationSchema = Yup.object().shape({
    taskName: Yup.string().trim().required(`${"Vui lòng chọn nhãn"}`),
    deadline: Yup.mixed<dayjs.Dayjs>()
      .nullable()
      .test("deadline-test", "Thời gian không hợp lệ", (value, _) => {
        if (!value) return true;
        return value > today;
      })
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });
  const onUploaded = (data: File[], name: string) => {
    setValue("files", data);
  };
  const handleRemoveFile = (value: File) => {
    const newFilesDeleted = [...filesDeleted];
    newFilesDeleted.push(value);
    setFilesDeleted(newFilesDeleted);
  };

  const colors = [
    "#FCB9C2",
    "#FED3B4",
    "#EADDA8",
    "#7EC79C",
    "#BFE8D7",
    "#99D4F0",
    "#CCD0FE",
    "#D5C9BB",
    "#B5BDC2"
  ];

  return (
    <Modal
      title={isUpdate ? "Chỉnh sửa nhiệm vụ" : "Tạo nhiệm vụ mới"}
      textSubmit={isUpdate ? "Cập nhật" : "Tạo nhiệm vụ mới"}
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={
        createTaskAxios.isLoading ||
        uploadFileAxios.isLoading ||
        updateTaskAxios.isLoading
      }
      disabledSubmit={
        createTaskAxios.isLoading ||
        uploadFileAxios.isLoading ||
        getTaskAxios.isLoading ||
        updateTaskAxios.isLoading
      }
      onClose={
        !createTaskAxios.isLoading &&
        !uploadFileAxios.isLoading &&
        !updateTaskAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="500px"
    >
      {isUpdate && getTaskAxios.isLoading && (
        <center>
          <CircularProgress />
        </center>
      )}
      {(!isUpdate || (isUpdate && getTaskAxios.data && updateLoaded)) && (
        <Wrapper
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px"
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ControlForm title="Tên nhiệm vụ" classname="brand-form" isRequired>
            <InputForm
              placeholder="Tên nhiệm vụ"
              required
              name="taskName"
              errors={errors}
              control={control}
              disabled={isUpdate}
              size="small"
            />
          </ControlForm>
          <ControlForm title="Giao cho" classname="brand-form">
            <TagInput
              name={"assign"}
              store={isUpdate ? "stage" : "workflow"}
              storeId={
                isUpdate ? getTaskAxios.data?.taskCard.workflowId : workflowId
              }
              control={control}
              limit={1}
              errors={errors}
              defaultValues={getValues("assign")}
              isError={!!errors.assign}
              placeholder={"Sử dụng @ để tag thành viên nhận nhiệm vụ"}
            />
          </ControlForm>

          <ControlForm title="Màu" classname="brand-form">
            <Controller
              name="color"
              control={control}
              render={({ field }) => {
                return (
                  <ToggleButtonGroup
                    {...field}
                    onChange={(
                      event: React.MouseEvent<HTMLElement>,
                      value: string
                    ) => {
                      setValue(field.name, value);
                    }}
                    color="info"
                    exclusive
                    sx={{ width: "100%" }}
                  >
                    {colors.map((value, inx) => (
                      <ToggleButton
                        value={value}
                        key={inx}
                        sx={{
                          backgroundColor: value,
                          border: `2px solid ${field.value === value ? "#3091F3" : "white"}`,
                          marginRight: "8px",
                          "&:hover": {
                            border: `2px solid #c1c1c1`,
                            backgroundColor: value
                          },
                          "&:focus": {
                            border: `2px solid #3091F3`,
                            backgroundColor: value
                          },
                          "&.Mui-selected": {
                            backgroundColor: value,
                            border: `2px solid #3091F3`,
                            "&:hover": {
                              backgroundColor: value
                            }
                          }
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "transparent",
                            width: "28px",
                            height: "16px"
                          }}
                        />
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                );
              }}
            />
            {/* <Box display="flex">
              {Object.values(EStageColumnColor).map((value, inx) => (
                <Box
                  key={inx}
                  onClick={() => setColor(value)}
                  sx={{
                    backgroundColor: value,
                    width: "40px",
                    height: "24px",
                    mariginRight: "8px",
                    border: `2px solid ${color === value ? "#3091F3" : "white"}`,
                    borderRadius: "4px"
                  }}
                /> */}
            {/* ))} */}
            {/* </Box> */}
          </ControlForm>

          {hasDocument && (
            <ControlForm
              title="Mô tả nhiệm vụ"
              infor="Mô tả ngắn về nhiệm vụ"
              classname="brand-form"
            >
              <Controller
                control={control}
                name={"description"}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <TextEditorQuill
                      {...field}
                      placeholder="Mô tả ngắn về nhiệm vụ"
                      toolbar={true}
                      editorStyle={{
                        border: "1px solid #D3DCDF",
                        borderRadius: "8px",
                        width: "100%",
                        height: "24vh"
                      }}
                      quillRef={quillRef}
                    />
                  </FormControl>
                )}
              />
            </ControlForm>
          )}

          <ControlForm title="Người theo dõi" classname="brand-form">
            <TagInput
              name={"followers"}
              control={control}
              store={isUpdate ? "stage" : "workflow"}
              storeId={
                isUpdate ? getTaskAxios.data?.taskCard.workflowId : workflowId
              }
              defaultValues={getValues("followers")}
              errors={errors}
              type={TagType.User}
              isError={!!errors.followers}
              placeholder={"Sử dụng @ để tag người theo dõi nhiệm vụ"}
            />
          </ControlForm>
          {
            <ControlForm title="Thời hạn" classname="brand-form">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="vi"
              >
                <Controller
                  name="deadline"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error, invalid }
                  }) => (
                    <>
                      <div style={{ display: "flex", gap: "16px" }}>
                        <MobileDatePicker
                          disablePast
                          minDate={today}
                          value={value}
                          dayOfWeekFormatter={date => date.format("dd")}
                          onChange={newDate =>
                            onChange(
                              newDate
                                ? dayjs(newDate)
                                    .hour(value ? value.hour() : 0)
                                    .minute(value ? value.minute() : 0)
                                : null
                            )
                          }
                          slotProps={{
                            textField: {
                              placeholder: "Nhấn để chọn ngày",
                              InputProps: {
                                readOnly: true,
                                sx: {
                                  cursor: "pointer",
                                  "& .MuiSvgIcon-root": {
                                    width: 24,
                                    height: 24
                                  }
                                }
                              }
                            },
                            toolbar: {
                              className: "dialog-toolbar",
                              sx: {
                                span: {
                                  fontSize: 12
                                },
                                h4: {
                                  fontSize: 20
                                }
                              }
                            },
                            calendarHeader: {
                              sx: {
                                "& .MuiPickersCalendarHeader-labelContainer": {
                                  fontSize: 16
                                },
                                "& .MuiSvgIcon-root": {
                                  width: 24,
                                  height: 24
                                },
                                button: { fontSize: 18 }
                              }
                            },
                            mobilePaper: {
                              sx: {
                                "& .MuiDayCalendar-weekDayLabel": {
                                  fontSize: 12
                                },
                                "& .MuiPickersDay-root": {
                                  fontSize: 12
                                },
                                "& .MuiPickersMonth-monthButton": {
                                  fontSize: 14
                                },
                                "& .MuiPickersYear-yearButton": {
                                  fontSize: 14
                                },
                                "& .MuiButtonBase-root": {
                                  fontSize: 14
                                }
                              }
                            }
                          }}
                        />
                        <MobileTimePicker
                          value={value}
                          disablePast={
                            value && value.diff(today, "day") > 0 ? false : true
                          }
                          onChange={newTime =>
                            onChange(
                              newTime
                                ? dayjs(value)
                                    .hour(newTime.hour())
                                    .minute(newTime.minute())
                                : null
                            )
                          }
                          slotProps={{
                            textField: {
                              placeholder: "Nhấn để chọn giờ",
                              InputProps: {
                                readOnly: true,
                                sx: {
                                  cursor: "pointer",
                                  "& .MuiSvgIcon-root": {
                                    width: 24,
                                    height: 24
                                  }
                                }
                              }
                            },
                            toolbar: {
                              className: "dialog-toolbar",
                              sx: {
                                span: {
                                  fontSize: 12
                                },
                                h4: {
                                  fontSize: 20
                                }
                              }
                            },
                            leftArrowIcon: {
                              sx: {
                                fontSize: 24
                              }
                            },
                            rightArrowIcon: {
                              sx: {
                                fontSize: 24
                              }
                            },
                            mobilePaper: {
                              sx: {
                                "& .MuiDayCalendar-weekDayLabel": {
                                  fontSize: 12
                                },
                                "& .MuiClockNumber-root": { fontSize: 12 },
                                "& .MuiButtonBase-root": { fontSize: 12 }
                              }
                            }
                          }}
                        />
                      </div>
                      {error && (
                        <FormHelperText sx={{ color: "#ED3E47" }}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </LocalizationProvider>
            </ControlForm>
          }
          {hasDocument && (
            <ControlForm
              title="Tài liệu đính kèm"
              infor="Đính kèm tối đa 10 tài liệu"
              classname="brand-form"
            >
              <DragAndDropFile
                title={"Tài liệu đính kèm"}
                classname="ee"
                limitFile={10}
                onUploaded={onUploaded}
                name={"attach"}
                data={getValues("files")}
                handleRemoveFile={handleRemoveFile}
              ></DragAndDropFile>
            </ControlForm>
          )}
        </Wrapper>
      )}
    </Modal>
  );
};

export default CreateTaskModal;
