import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { IBoardFilter, IStage } from "../../../models/Stage";
import { Box, Chip } from "@mui/material";
import { CheckRounded, KeyboardArrowDownRounded } from "@mui/icons-material";

/**
 * A filter component for project stages that displays a dropdown with stage options.
 *
 * @component
 * @param {IProps} props - The component props
 * @param {IStage | undefined} props.value - Currently selected stage value
 * @param {IStage[]} props.stages - Array of available stages to select from
 * @param {(stage: IStage | undefined) => void} props.onChange - Callback function when stage selection changes
 *
 * @returns A button that opens a popover with stage filter options
 *
 * @example
 * <StageFilter
 *   value={selectedStage}
 *   stages={availableStages}
 *   onChange={(stage) => setSelectedStage(stage)}
 * />
 */

interface IProps {
  stages: IStage[];
  filter: IBoardFilter;
  onChange: (value: IStage | undefined) => void;
}

const StageFilter = ({ filter, stages, onChange }: IProps) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div>
          <button
            {...bindTrigger(popupState)}
            type="button"
            className="inline-flex items-center font-medium text-center text-white bg-blue-400 rounded-full focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            style={{
              padding: "9px 12px",
              inlineSize: "max-content",
              fontSize: "13px",
              borderRadius: "10px",
              border: "1px solid #d3dcdf",
              color: filter.Workflow ? "white" : "#596970d8",
              backgroundColor: filter.Workflow ? "#3091F3" : "transparent"
            }}
          >
            {filter.Workflow?.name ?? "Tất cả"}
            <Box width="12px" />
            <KeyboardArrowDownRounded sx={{ fontSize: 20 }} />
          </button>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            sx={{
              maxWidth: "456px",
              maxHeight: "656px",
              marginTop: "4px",
              "& .MuiSvgIcon-root": {
                fontSize: "20px"
              },
              span: {
                fontSize: "13px"
              }
            }}
            // className="w-100"
          >
            <div
              className="flex flex-wrap align-middle"
              style={{
                margin: "8px",
                gap: "8px"
              }}
            >
              <div
                className={`select-none shrink-0 grow-0 w-30 items-center font-medium text-center text-white  rounded-lg focus:outline-none`}
                onClick={() => {
                  onChange(undefined);
                  popupState.close();
                }}
                style={{
                  margin: "8px",
                  // padding: "8px",
                  fontSize: 14
                }}
              >
                <Chip
                  className="focus:ring-green-300  hover:bg-blue-300 "
                  variant={
                    filter.Workflow === undefined ? "outlined" : "filled"
                  }
                  icon={
                    filter.Workflow === undefined ? <CheckRounded /> : undefined
                  }
                  label={"Tất cả"}
                  color="info"
                  sx={{
                    backgroundColor:
                      filter.Workflow === undefined ? "#d8f3ff79" : "#f8f8f8",

                    color: filter.Workflow === undefined ? undefined : "grey"
                  }}
                />
              </div>
              {stages.map(stage => (
                <div
                  key={stage.id}
                  className={`select-none shrink-0 grow-0 items-center font-medium text-center text-white focus:outline-none `}
                  onClick={() => {
                    onChange(stage);
                    popupState.close();
                  }}
                  style={{
                    margin: "8px",
                    fontSize: 14
                  }}
                >
                  <Chip
                    className="focus:ring-green-300  hover:bg-blue-300"
                    variant={
                      filter.Workflow?.id === stage.id ? "outlined" : "filled"
                    }
                    icon={
                      filter.Workflow?.id === stage.id ? (
                        <CheckRounded />
                      ) : undefined
                    }
                    label={stage.name}
                    color="info"
                    sx={{
                      backgroundColor:
                        filter.Workflow?.id === stage.id
                          ? "#d8f3ff79"
                          : "#f8f8f8",
                      color:
                        filter.Workflow?.id === stage.id ? undefined : "grey"
                    }}
                  />
                </div>
              ))}
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
export default StageFilter;
