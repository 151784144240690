import { Box, Button, FormControl, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TextEditorQuill from "../../components/TextEditorQuill";
import { useApi } from "../../hooks/useApi.hook";
import { IRules } from "../../models/office";
import OfficeService from "../../services/api/office.service";
import { toggleMessage } from "../../components/Toast/Toast";
import { IFeaturePermission } from "../../models/user/models.roles";
import { authTokens } from "../../services/services";
import RoleService from "../../services/api/role.service";
import { IUser } from "../../models/user";
import {
  FeatureModel,
  RulesPermissionModel
} from "../../models/common/models.enum";
import "react-quill/dist/quill.core.css";
import EMPTY from "../../assets/images/common/rule_empty_data.svg";
import { ImageCustom } from "../../components";

type Props = {};

const Rules = (props: Props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [rules, setRules] = useState<string | undefined>();
  const [userInfo, setUserInfo] = useState<IUser | null>(null);

  const getRules = useApi<IRules>({});
  const updateRules = useApi<IRules>({});
  const features = useApi<IFeaturePermission[]>({ isFetch: true });

  function fetchData() {
    getRules.request(OfficeService.get());
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo)
      features.request(RoleService.getFeaturePemissionByUserId(userInfo.id));
  }, [userInfo]);

  return (
    <Box
      sx={{
        padding: "12px",
        justifyItems: "flex-end"
      }}
    >
      <Box height={"44px"}>
        {features.data?.find(
          f =>
            f.feature === FeatureModel.Rules &&
            f.permissions.includes(RulesPermissionModel.Update)
        ) &&
          !isEdit && (
            <Button
              variant="contained"
              onClick={() => {
                setRules(getRules.data?.description);
                setIsEdit(true);
              }}
            >
              Chỉnh sửa nội quy
            </Button>
          )}
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          margin: "16px auto",
          width: "50%",
          minHeight: "100vh",
          padding: "64px"
        }}
      >
        {!isEdit && (
          <div
            className="view ql-editor"
            dangerouslySetInnerHTML={{
              __html: getRules?.data?.description.length
                ? getRules?.data?.description
                : ""
            }}
          ></div>
        )}

        {!isEdit &&
          getRules?.data?.description.split("<").length === 4 &&
          getRules?.data?.description.split("<")[2] === "br>" && (
            <Box justifyItems={"center"} margin="20% 0">
              <ImageCustom src={EMPTY} width="auto" height="300px" />
              <Typography variant="subtitle2" color={"#596970"}>
                Chưa có thông tin
              </Typography>
            </Box>
          )}

        {!features.data?.find(
          f =>
            f.feature === FeatureModel.Rules &&
            f.permissions.includes(RulesPermissionModel.Update)
        ) && !getRules.data?.description ? (
          <Typography>Không có thông tin</Typography>
        ) : (
          (isEdit || !getRules.data?.description) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end"
              }}
            >
              <FormControl fullWidth>
                <TextEditorQuill
                  // {...field}
                  placeholder="Vui lòng nhập nội quy..."
                  toolbar={true}
                  editorStyle={{
                    border: "1px solid #D3DCDF",
                    borderRadius: "8px",
                    width: "100%"
                    // height: "75vh"
                  }}
                  onChange={e => setRules(e)}
                  value={rules ?? ""}
                />
              </FormControl>

              <Box marginTop="12px" display="flex">
                {getRules.data?.description && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    sx={{ borderRadius: "8px", marginRight: "12px" }}
                    onClick={() => setIsEdit(false)}
                    disabled={updateRules.loading}
                  >
                    Huỷ bỏ thay đổi
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: "8px" }}
                  disabled={updateRules.loading}
                  color="success"
                  onClick={() =>
                    rules &&
                    updateRules.request(
                      OfficeService.update({
                        description: rules
                      }).then(res => {
                        if (res.success) {
                          toggleMessage({
                            type: "success",
                            message: "Cập nhật thành công"
                          });
                          setIsEdit(false);
                          fetchData();
                        } else {
                          toggleMessage({
                            type: "error",
                            message: "Đã có lỗi xảy ra"
                          });
                        }
                      })
                    )
                  }
                >
                  Cập nhật nội quy
                </Button>
              </Box>
            </Box>
          )
        )}
        {/* {features.data?.find(
          f =>
            f.feature === FeatureModel.Rules &&
            f.permissions.includes(RulesPermissionModel.Update)
        ) &&
          getRules.data?.description &&
          !isEdit && (
            <Button
              startIcon={<EditNoteRoundedIcon />}
              sx={{ marginTop: "24px" }}
              color="info"
              onClick={() => {
                setRules(getRules.data?.description);
                setIsEdit(true);
              }}
            >
              Chỉnh sửa
            </Button>
          )} */}
      </Box>
    </Box>
    // <Box
    //   style={{
    //     display: "flex",
    //     flexDirection: "column"
    //   }}
    // >
    //   <Button variant="contained">Chỉnh sửa nội quy</Button>
    //   <div
    //     style={{
    //       padding: "24px 32px",
    //       backgroundColor: "white",
    //       width: "90%",
    //       margin: "44px"
    //       // height: "auto",
    //       // minHeight: "100%"
    //     }}
    //   >
    //     {!isEdit && (
    //       <div
    //         className="view ql-editor"
    //         dangerouslySetInnerHTML={{
    //           __html: getRules?.data?.description.length
    //             ? getRules?.data?.description
    //             : ""
    //         }}
    //       ></div>
    //     )}
    //     {!features.data?.find(
    //       f =>
    //         f.feature === FeatureModel.Rules &&
    //         f.permissions.includes(RulesPermissionModel.Update)
    //     ) && !getRules.data?.description ? (
    //       <Typography>Không có thông tin</Typography>
    //     ) : (
    //       (isEdit || !getRules.data?.description) && (
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "end"
    //           }}
    //         >
    //           <FormControl fullWidth>
    //             <TextEditorQuill
    //               // {...field}
    //               placeholder="Vui lòng nhập nội quy..."
    //               toolbar={true}
    //               editorStyle={{
    //                 border: "1px solid #D3DCDF",
    //                 borderRadius: "8px",
    //                 width: "100%"
    //                 // height: "75vh"
    //               }}
    //               onChange={e => setRules(e)}
    //               value={rules ?? ""}
    //             />
    //           </FormControl>

    //           <Box marginTop="12px" display="flex">
    //             {getRules.data?.description && (
    //               <Button
    //                 variant="outlined"
    //                 size="small"
    //                 color="error"
    //                 sx={{ borderRadius: "12px", marginRight: "12px" }}
    //                 onClick={() => setIsEdit(false)}
    //                 disabled={updateRules.loading}
    //               >
    //                 Huỷ bỏ
    //               </Button>
    //             )}
    //             <Button
    //               variant="contained"
    //               size="small"
    //               sx={{ borderRadius: "12px" }}
    //               disabled={updateRules.loading}
    //               onClick={() =>
    //                 rules &&
    //                 updateRules.request(
    //                   OfficeService.update({
    //                     description: rules
    //                   }).then(res => {
    //                     if (res.success) {
    //                       toggleMessage({
    //                         type: "success",
    //                         message: "Cập nhật thành công"
    //                       });
    //                       setIsEdit(false);
    //                       fetchData();
    //                     } else {
    //                       toggleMessage({
    //                         type: "error",
    //                         message: "Đã có lỗi xảy ra"
    //                       });
    //                     }
    //                   })
    //                 )
    //               }
    //             >
    //               Cập nhật
    //             </Button>
    //           </Box>
    //         </Box>
    //       )
    //     )}
    //     {features.data?.find(
    //       f =>
    //         f.feature === FeatureModel.Rules &&
    //         f.permissions.includes(RulesPermissionModel.Update)
    //     ) &&
    //       getRules.data?.description &&
    //       !isEdit && (
    //         <Button
    //           startIcon={<EditNoteRoundedIcon />}
    //           sx={{ marginTop: "24px" }}
    //           color="info"
    //           onClick={() => {
    //             setRules(getRules.data?.description);
    //             setIsEdit(true);
    //           }}
    //         >
    //           Chỉnh sửa
    //         </Button>
    //       )}
    //   </div>
    // </Box>
  );
};

export default Rules;
