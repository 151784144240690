import styled from "@emotion/styled";
import { Backdrop, Box } from "@mui/material";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

export const ManageProjectWrapper = styled(LoadingOverlayWrapper)(theme => ({
  width: "100%",
  height: "100%",
  position: "relative",
  "& .prevent-select": {
    webkitTouchCalloutUserSelect: "none",
    webkitUserSelect: "none",
    khtmlUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none"
  },
  "& .board-project-header": {
    backgroundColor: "white",
    padding: "10px 15px 0px 0px",
    // boxSizing: "border-box",
    "& .board-project-header-info": {
      width: "98%",
      height: "4px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    "& .board-project-icon": {
      textAlignLast: "center"
    },
    "& .board-project-title": {
      //   height: "100px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {}
      }
    }
  },
  "& .board-project-tabs": {
    " & .tab-list": {
      // marginLeft: "32px",
      li: {
        display: "inline",
        padding: "9px 0px",
        marginRight: "15px",
        " fontSize": "13px",
        cursor: "pointer",
        "&.active": {
          a: {
            color: "black"
          },
          color: "#267CDE",
          fontWeight: 700,
          borderBottom: "solid 2px #267CDE"
        },
        a: {
          color: "#888"
        }
      }
    }
  }
}));
