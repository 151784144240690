import { styled } from "@mui/material";
import { responsiveFontSizes } from "../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  height: "100%",
  // backgroundColor: "white",
  "& .left-container": {
    width: "20%",
    backgroundColor: "white",
    overflow: "hidden"
  },
  "& .fc-daygrid-day-frame": {
    backgroundColor: "white",
    color: "black"
  },
  "& .fc-scrollgrid": {
    backgroundColor: "#7E969F",
    color: "white"
  },
  "& .fc-daygrid-day-top": {
    backgroundColor: "rgba(126,150,159,0.2)"
  },
  "& .fc-daygrid-day-number": {
    //     backgroundColor: "red"
    fontSize: "16px",
    fontWeight: "600",
    color: "#596970"
  },
  "& .fc-header-toolbar": {
    "& .fc-toolbar-title": {
      fontSize: "16px",
      fontWeight: "500"
    },
    "& .fc-button-group": {
      marginRight: "10px",
      backgroundColor: "#D4E3F2",
      borderRadius: "32px",
      "& .fc-button": {
        //    width: "80px",
        border: "none",
        // color: "#3091F3",
        color: "black",
        textTransform: "uppercase",
        borderRadius: "32px",
        backgroundColor: "transparent",
        fontSize: "14px",
        fontWeight: "500",
        width: "auto",
        margin: "4px 8px",
        padding: "0 12px"
      }
    }
  },
  "& .fc .fc-button-primary:not(:disabled).fc-button-active": {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#3091F3",
    border: "0x solid #3091F3",
    borderRadius: "32px",
    width: "auto",
    padding: "0 12px",
    margin: "4px 6px",
    fontSize: "14px"
  },
  "& .fc .fc-button-primary:not(:disabled).fc-button-active:focus": {
    boxShadow: "none"
  },
  "& .fc .fc-button-primary": {
    color: "GrayText",
    width: "32px",
    height: "32px",
    padding: 0,
    backgroundColor: "#F6F6F6",
    border: "1px solid #DDDDDD",
    borderRadius: "32px"
  },
  ".fc .fc-button-primary:disabled": {
    width: "100px",
    backgroundColor: "transparent",
    color: "GrayText",
    border: "none",
    fontWeight: "600"
  },
  ".fc-direction-ltr .fc-toolbar > * > :not(:first-child, :last-child)": {
    width: "auto",
    border: 0,
    fontWeight: "bold",
    backgroundColor: "transparent"
  },
  "& .schedules-md": {
    display: "none"
  },
  "@media (max-width: 868px)": {
    flexDirection: "column",
    "& .book-calendar": { display: "none" },
    "& .left-container": { width: "100%" },
    "& .subtitle2-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 14,
          sm: 14,
          md: 16,
          lg: 16,
          xl: 16
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .caption-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 12,
          sm: 12,
          md: 14,
          lg: 14,
          xl: 14
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .date-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 32,
          sm: 32,
          md: 34,
          lg: 34,
          xl: 34
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .schedules-md": {
      display: "flex",
      flexDirection: "column"
    },
    "& .current-date": {
      flexGrow: 1,
      textAlign: "center",
      ...responsiveFontSizes({
        fontsize: {
          xs: 14,
          sm: 14,
          md: 16,
          lg: 16,
          xl: 16
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    }
  }
}));

export const ApproveWrapper = styled("div")(() => ({
  display: "flex",
  "& .content-box": {
    backgroundColor: "white",
    margin: "0 12px",
    padding: "12px",
    borderRadius: "4px"
  }
}));
