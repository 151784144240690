import { styled } from "@mui/material";
import { responsiveFontSizes } from "../../../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  maxHeight: "calc(100vh - 52px)",
  height: "calc(100vh - 52px)",
  overflow: "hidden",
  width: "100%",
  backgroundColor: "white",
  "& .left-container": {
    width: "25%",
    display: "flex",
    flexDirection: "column",
    "& .search-container": { margin: "24px" },
    "& .list-data-container": {
      flex: "1 1 auto",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      paddingBottom: "20px",
      alignItems: "center",
      "::-webkit-scrollbar": {
        width: "4px"
      },
      "::-webkit-scrollbar-track": {
        // width: "10px",
        backgroundColor: "transparent"
      },
      "::-webkit-scrollbar-thumb": {
        // width: "10px",
        backgroundColor: "#3091F3",
        borderRadius: "12px"
      },
      "& .item-card-container": {
        border: "1px solid rgba(126,150,159,0.3)",
        padding: "16px",
        margin: "0 12px",
        borderRadius: "12px",
        width: "90%",
        "&:hover": {
          backgroundColor: "red"
        }
      }
    }

    // "& .content-list-item": {
    //   maxHeight: "calc(100vh - 52px - 100px)",
    //   overflow: "auto",
    //   justifyItems: "center",
    //   "& .list-item-conatainer": {
    //     width: "90%",
    //     padding: "18px",
    //     // backgroundColor: "white",
    //     margin: "8px",
    //     border: "1px solid rgba(126,150,159,0.3)"
    //   }
    // }
  },

  "& .right-container": {
    width: "75%",
    flexGrow: 1,
    backgroundColor: "#F1F5F9",
    // minHeight: "1123px",
    // height: "auto",
    // padding: "62px 72px",
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "6px"
    },
    "::-webkit-scrollbar-track": {
      // width: "10px",
      backgroundColor: "transparent"
    },
    "::-webkit-scrollbar-thumb": {
      // width: "10px",
      backgroundColor: "#3091F3",
      borderRadius: "12px"
    },
    justifyItems: "center",
    "& .empty-box": {
      justifyItems: "center",
      margin: "25vh"
    },
    "& .content-page": {
      backgroundColor: "white",
      width: "794px",
      // height: "100vh",
      margin: "40px"
    }
  },

  "@media (max-width: 868px)": {
    svg: {
      width: "12px",
      height: "12px"
    },
    "& .search-container": { margin: "0 12px 16px 12px" },
    "& .information-container": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 14,
          sm: 14,
          md: 16,
          lg: 16,
          xl: 18
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .content-list": {
      width: "100%",
      "& .content-list-item": {
        "& .list-item": {
          // padding: 0,
          "& .item-title": {
            ...responsiveFontSizes({
              fontsize: {
                xs: 14,
                sm: 14,
                md: 16,
                lg: 16,
                xl: 18
              },
              lineheight: {
                xs: 22.59,
                sm: 22.59,
                md: 22.59,
                lg: 22.59,
                xl: 22.59
              }
            })
          },
          "& .item-time": {
            ...responsiveFontSizes({
              fontsize: {
                xs: 12,
                sm: 13,
                md: 14,
                lg: 16,
                xl: 18
              },
              lineheight: {
                xs: 22.59,
                sm: 22.59,
                md: 22.59,
                lg: 22.59,
                xl: 22.59
              }
            })
          }
        }
      }
    },

    "& .login-box-left": {
      width: "400px",
      padding: "40px",
      svg: {
        width: "20px",
        height: "20px"
      },
      "&. sign-up-question": {
        ...responsiveFontSizes({
          fontsize: {
            xs: 14,
            sm: 16,
            md: 18,
            lg: 18,
            xl: 18
          },
          lineheight: {
            xs: 22.59,
            sm: 22.59,
            md: 22.59,
            lg: 22.59,
            xl: 22.59
          }
        })
      },
      button: {
        ...responsiveFontSizes({
          fontsize: {
            xs: 14,
            sm: 16,
            md: 18,
            lg: 18,
            xl: 18
          },
          lineheight: {
            xs: 22.59,
            sm: 22.59,
            md: 22.59,
            lg: 22.59,
            xl: 22.59
          }
        })
      }
    },
    "& .content-page": {
      display: "none"
    }
  }
}));
