import { Box, CircularProgress, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ModalCustomWrapper } from "./styles";
import Button from "@mui/material/Button";
import { Avatar, ImageCustom } from "..";
import IconClose from "../../assets/images/common/icon_close_line_black_background.svg";
import variableStyles from "../../theme/variable-styles";
import IconCheck from "../../assets/images/common/icon_check_line_green.svg";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalCustomProps {
  open?: boolean;
  textSubmit?: string;
  textClose?: string;
  onSubmit?: () => void;
  onClose?: () => void;
  title: string;
  type?: string;
  description?: string;
  children: any;
  moreAction?: any;
  width?: string;
  disabledSubmit?: boolean;
  loadingState?: boolean;
  projectInfor?: {
    name: string;
    domain: string;
    href: string;
    avatar: string;
  };
}

export default function ModalCustom(props: ModalCustomProps) {
  const { t } = useTranslation();

  const {
    open = false,
    textSubmit = "Create Project",
    textClose = "Cancel",
    onSubmit,
    onClose,
    title,
    children,
    moreAction,
    width,
    description,
    disabledSubmit,
    projectInfor,
    loadingState,
    type
  } = props;

  return (
    <ModalCustomWrapper
      open={open}
      TransitionComponent={Transition}
      width={width}
      className="scrollbar-small"
      BackdropProps={{
        style: {
          background: variableStyles.NaturalColor1000,
          opacity: ".3"
        }
      }}
    >
      <div className="modal-container">
        <div className="modal-header">
          <div className="header-content">
            <p className="modal-title">{title}</p>
            {!!description && (
              <p className="modal-description">{description}</p>
            )}
          </div>
          <Button
            className={"button-close"}
            size="large"
            variant="text"
            onClick={onClose}
            disabled={loadingState}
          >
            <ImageCustom
              src={IconClose}
              alt="Icon-Close"
              width={"28px"}
              height={"28px"}
            />
          </Button>
        </div>

        {!!projectInfor && (
          <div className="infor-box-wrapper">
            <div className="infor-box">
              <Avatar
                src={projectInfor.avatar}
                alt="Avatar-Image"
                width={"36px"}
                height={"36px"}
                className="project-avatar"
              />
              <div className="project-name">
                <p className="name-project fit-one-line">{projectInfor.name}</p>
                <p className="name-domain fit-one-line">
                  {projectInfor.domain}
                </p>
              </div>
            </div>
            <ImageCustom
              src={IconCheck}
              alt="Icon-Check"
              width={"24px"}
              height={"24px"}
              className="icon-check"
            />
          </div>
        )}
        <div className="modal-content">{children}</div>
        <div className="modal-action">
          {!!moreAction && <Box sx={{ flexGrow: 1 }}>{moreAction}</Box>}
          <Box display={loadingState ? "flex" : "none"}>
            <CircularProgress size="20px" sx={{ marginRight: "12px" }} />
            <Typography variant="caption">Đang xử lý...</Typography>
          </Box>
          <Button
            size="xsmall"
            variant="outlined"
            className="button-cancel"
            onClick={onClose}
            disabled={loadingState}
          >
            {t(textClose)}
          </Button>

          {onSubmit && (
            <Button
              size="xsmall"
              variant="contained"
              style={{ fontSize: 14, fontWeight: 600 }}
              className={`button-submit ${type ?? ""}`}
              onClick={onSubmit}
              disabled={disabledSubmit || loadingState}
            >
              {t(textSubmit)}
            </Button>
          )}
        </div>
      </div>
    </ModalCustomWrapper>
  );
}
