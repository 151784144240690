import {
  Box,
  Button,
  Chip,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

import { Wrapper } from "./styles";
import AvatarImage from "../../../../../assets/images/common/avatar.png";

import AccountLayout from "../../../../Member/components/AccountLayout";
import {
  ETaskStatus,
  EStageType,
  taskCardStatusColor,
  taskStatusTitle
} from "../../../../../models/common/models.enum";
import { useApi } from "../../../../../hooks/useApi.hook";
import { ITaskCardPage } from "../../../../../models/Task";
import TaskService from "../../../../../services/api/task.service";
import { useEffect, useState } from "react";
import CommonHandle from "../../../../../common/handles/common.handles";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../../config/constants";
import moment from "moment";
import { ROUTE_PATH } from "../../../../../common/constants/app.constant";
import useNavigateSearch from "../../../../../components/NavigateSearch";
import { IBasePaging } from "../../../../../models/common/models.type";
import SearchInput from "../../../../Member/components/SearchInput";
import SearchNoResult from "../../../../Member/components/SearchNoResult";

type Props = {
  boardId: string;
};

const columns = [
  "NHIỆM VỤ",
  "GIAI ĐOẠN",
  "TRẠNG THÁI",
  "ĐÃ GIAO CHO",
  "THỜI HẠN",
  "CÒN LẠI",
  // "CÔNG VIỆC",
  "NGƯỜI TẠO",
  "CẬP NHẬT"
];

const JobList = (props: Props) => {
  const { boardId } = props;
  const [data, setData] = useState<ITaskCardPage[]>([]);
  const navigateSearch = useNavigateSearch();
  const getPage = useApi<ITaskCardPage[]>({ isFetch: true, isPaging: true });
  const [paging, setPaging] = useState<IBasePaging | null>();
  const [inputValue, setInputValue] = useState("");
  const [searchState, setSearchState] = useState<"SUCCESS" | "NONE" | null>(
    null
  );
  const now = moment();

  useEffect(() => {
    fetchData();
  }, []);

  function handleSearch() {
    if (inputValue.length > 0) {
      fetchData(inputValue, 1);
    } else {
      fetchData();
      setSearchState(null);
    }
  }

  function fetchData(keySearch?: string, pageIndex?: number) {
    getPage.request(
      TaskService.getPage({
        CurrentPage: pageIndex ?? 1,
        PageSize: 10,
        BoardId: boardId,
        KeySearch: keySearch
      })
    );
    if (!keySearch) {
      setInputValue("");
    }
    setSearchState(null);
  }

  useEffect(() => {
    if (getPage.data) {
      setData(getPage.data ?? []);
      setPaging(getPage.pagging);
      if (getPage.data.length && inputValue.length) {
        setSearchState("SUCCESS");
      } else if (!getPage.data.length && inputValue.length) {
        setSearchState("NONE");
      }
    }
  }, [getPage.data]);

  const getDate = (date: Date | null) => {
    var remaining = "";
    var datetimeType = " ngày";
    if (date) {
      const byDay = moment(date, "YYYY-MM-DDThh:mm:ss").diff(now, "days");
      var remainingTime = byDay;
      if (byDay === 0) {
        const byHour = moment(date, "YYYY-MM-DDThh:mm:ss").diff(now, "hours");
        if (byHour === 0) {
          remainingTime = moment(date, "YYYY-MM-DDThh:mm:ss").diff(
            now,
            "minutes"
          );
          datetimeType = " phút";
        } else {
          remainingTime = byHour;
          datetimeType = " giờ";
        }
      }
      if (remainingTime < 0) remaining = "Quá hạn";
      else if (remainingTime === 0) remaining = "Hết hạn";
      else remaining = remainingTime + datetimeType;
    } else {
      remaining = "";
    }

    return remaining;
  };

  const handleChange = (value: number) => {
    fetchData(inputValue.length ? inputValue : undefined, value);
  };

  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        marginLeft="18px"
        marginTop="12px"
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <SearchInput
          placeholder="Tìm kiếm công việc.."
          input={inputValue}
          isSuccess={searchState === "SUCCESS"}
          search={handleSearch}
          clear={() => fetchData()}
          value={inputValue}
          countResult={data?.length ?? 0}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);
          }}
        />
        {paging && paging.totalPages > 1 && (
          <Box
            sx={{
              margin: "12px 18px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              "& .MuiSvgIcon-root": {
                fontSize: "20px"
              },
              button: {
                fontSize: "14px"
              }
            }}
          >
            <Typography fontSize="16px">
              {1 + 10 * (paging.currentPage - 1)} -{" "}
              {paging.currentPage * 10 < paging.totalCount!
                ? paging.currentPage * 10
                : paging.totalCount!}{" "}
              / {paging.totalCount}
            </Typography>
            <Pagination
              count={paging.totalPages}
              showFirstButton={paging.totalPages > 2}
              showLastButton={paging.totalPages > 2}
              shape="rounded"
              page={paging.currentPage ?? 0}
              onChange={(_, v) => handleChange(v)}
            />
          </Box>
        )}
      </Box>

      {!!data && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "&:first-child td, &:first-child th": { borderRight: 0 }
                }}
              >
                {columns.map((col, idx) => (
                  <TableCell
                    key={idx}
                    padding="normal"
                    sx={{
                      lineHeight: "21.8px"
                    }}
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(task => {
                const paragraphs =
                  task.description?.match(/<p[^>]*>.*?<\/p>/g)?.join("") ||
                  "Không có mô tả";

                return (
                  <TableRow key={task.id}>
                    <TableCell sx={{ fontSize: "14px" }}>
                      <Button
                        className="title"
                        sx={{
                          textDecoration: "underline",
                          color: "black",
                          textAlign: "-webkit-left"
                        }}
                        onClick={() =>
                          navigateSearch(ROUTE_PATH.TASK, {
                            id: task.id as string
                          })
                        }
                      >
                        {task.title}
                      </Button>

                      <div
                        className="TaskShortDesc Overflow2Line"
                        style={{
                          fontSize: "12px",
                          color: "#596970",
                          fontWeight: "400",
                          maxWidth: "224px",
                          WebkitLineClamp: "6"
                        }}
                        dangerouslySetInnerHTML={{
                          __html: paragraphs
                        }}
                      ></div>
                    </TableCell>
                    <TableCell>
                      {/* <Box display="flex">
                      {task.currentStage === 0 &&
                      task.typeWorkflow === EStageType.Complete ? (
                        <Box
                          sx={{
                            backgroundColor: "#54BD95",
                            width: "100%",
                            height: "8px",
                            borderRadius: "32px"
                          }}
                        ></Box>
                      ) : (
                        Array.from({ length: task.totalStage + 2 }).map(
                          (item, idx) => (
                            <Box
                              key={idx}
                              className="progress-box"
                              sx={{
                                backgroundColor:
                                  task.currentStage - 1 === idx
                                    ? taskCardStatusColor(
                                        ETaskStatus.Inprogress
                                      ).color
                                    : idx < task.currentStage
                                      ? taskCardStatusColor(
                                          ETaskStatus.Complete
                                        ).color
                                      : "#E5EDFF"
                              }}
                            ></Box>
                          )
                        )
                      )}
                    </Box> */}
                      {task.typeWorkflow === EStageType.Step && (
                        <p className="content">
                          {task.nameStage}
                          {/* [{task.currentStage}/
                          {task.totalStage + 2}] */}
                        </p>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={taskStatusTitle(task.status)}
                        size="small"
                        sx={{
                          color: taskCardStatusColor(task.status).color,
                          backgroundColor: taskCardStatusColor(task.status)
                            .bgcolor
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {task.personAssigned && (
                        <AccountLayout
                          name={task.personAssigned?.fullName ?? ""}
                          tag={task.personAssigned?.userName ?? ""}
                          position={task.personAssigned?.position ?? ""}
                          avatar={task.personAssigned.avatar}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <p className="content">
                        {CommonHandle.formatDateTime(
                          task.deadLine,
                          TIME_FORMAT
                        )}
                      </p>
                      <p className="content">
                        {CommonHandle.formatDateTime(
                          task.deadLine,
                          DATE_FORMAT
                        )}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="content">{getDate(task.deadLine)}</p>
                    </TableCell>
                    {/* <TableCell></TableCell> */}
                    <TableCell>
                      {task.creator && (
                        <AccountLayout
                          name={task.creator?.fullName ?? ""}
                          tag={task.creator?.userName ?? ""}
                          position={task.creator?.position ?? ""}
                          avatar={task.creator?.avatar}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <p className="content">
                        {CommonHandle.formatDateTime(
                          task.modifiedDate,
                          TIME_FORMAT
                        )}
                      </p>
                      <p className="content">
                        {CommonHandle.formatDateTime(
                          task.modifiedDate,
                          DATE_FORMAT
                        )}
                      </p>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {searchState === "NONE" && (
        <SearchNoResult
          keySearch={inputValue}
          title={"nhân viên"}
          clearSearch={() => {
            fetchData();
          }}
        />
      )}
    </Wrapper>
  );
};

export default JobList;
