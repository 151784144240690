import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import Breadcrumbs from "../Breadcrumbs";
import variableStyles from "../../theme/variable-styles";
import authTokens, {
  USER_FCM_TOKEN_KEY,
  USER_TOKEN_KEY
} from "../../services/local/auth-tokens";
import { ROUTE_PATH } from "../../common/constants/app.constant";
// import { getMessagingToken } from "../../firebase";
import useAxios from "../UseAxios/useAxios";
import AuthService from "../../services/api/auth.service";
import { IUpdateFcmTokenReq } from "../../models/auth";
import { getMessagingToken } from "../../firebase";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  height: "100vh",
  overflow: "hidden",
  width: "100%",
  position: "relative"
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  flex: "1 1 auto",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column"
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  // overflow: "hidden",
  height: "100vh",
  marginTop: "62px"
});

export const PageWrapper = styled("div")(() => ({
  flex: "1 1 auto",
  maxHeight: "calc(100vh - 110px)",
  overflow: "auto",
  backgroundColor: variableStyles.NaturalColor100,
  "&.fullHeight": { maxHeight: "calc(100vh - 32px)" },
  // "@media (max-width:767.98px)": {
  //   padding: "20px 8px"
  // },
  "& .MuiTableHead-root": {
    backgroundColor: "rgb(243, 244, 246)"
  }
}));

const DashboardLayout = () => {
  let navigate = useNavigate();
  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const updateFcmTokenAxios = useAxios<any>({ loading: "OnRequest" });

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isHiddenBreadcrumb, setHiddenBreadcrumb] = useState<boolean>(false);

  const storedFcmToken = useMemo(
    () => localStorage.getItem(USER_FCM_TOKEN_KEY),
    [localStorage]
  );

  useEffect(() => {
    const token = localStorage.getItem(USER_TOKEN_KEY);

    if (!token) {
      navigate(ROUTE_PATH.LOGIN);
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem(USER_TOKEN_KEY);
    if (token) {
      authTokens.getUser().then(user => {
        getMessagingToken().then(fcmToken => {
          if (user?.id && storedFcmToken !== fcmToken) {
            setFcmToken(fcmToken);
            if (fcmToken) {
              let updateFcmTokenReq: IUpdateFcmTokenReq = {
                UserId: user.id,
                NewFireBaseToken: fcmToken
              };
              updateFcmTokenAxios.request(
                AuthService.updateFcmToken(updateFcmTokenReq)
              );
            }
          }
        });
      });
    }
  }, [storedFcmToken]);

  useEffect(() => {
    if (updateFcmTokenAxios.error) {
      updateFcmTokenAxios.retry();
    }
    if (updateFcmTokenAxios.isSuccess) {
      if (fcmToken) authTokens.storeFcmToken(fcmToken);
    }
  }, [updateFcmTokenAxios.error, updateFcmTokenAxios.isSuccess]);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      {/* <div
        style={{
          height: "calc(100% - 52px)",
          marginTop: "52px",
          backgroundColor: "lightblue"
        }}
      >
        ưihiwhowwo
      </div> */}

      {/* <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} /> */}

      <DashboardLayoutContainer>
        {window.location.pathname.toLocaleUpperCase().includes("WORKFLOW") && (
          <DashboardSidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        )}

        <DashboardLayoutWrapper>
          <Breadcrumbs
            handleHidden={value => {
              setHiddenBreadcrumb(value);
            }}
          />
          <PageWrapper
            className={`scrollbar ${isHiddenBreadcrumb ? "fullHeight" : ""}`}
          >
            <Outlet />
          </PageWrapper>
        </DashboardLayoutWrapper>
      </DashboardLayoutContainer>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
