import { Box, Button, Divider } from "@mui/material";
import { useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BasicMenu from "../../components/Menu/BasicMenu";
import CreateTaskModal from "./CreateTaskModal/create_task_modal";
import { IWorkflow } from "../../models/workflow";
import CreateWorkflowDialog from "../WorkflowManagement/components/CreateWorkflowDialog/create_workflow_dialog";
import { IRoleBoard } from "../../models/Stage";
import CopyWorkflowModal from "./CopyWorkflowModal/copy_workflow_modal";

interface Props {
  workflow: IWorkflow;
  role: IRoleBoard;
  hasStepStage: boolean;
  onCloseWorkflow: (id: string) => void;
  onCreateStage: () => void;
  onCreateTaskSuccess: () => void;
  onUpdateWorkflowSuccess: () => void;
  onCopyWorkflowSuccess?: () => void;
  onExportFileExcelByBoardId?: () => void;
}

/**
 * A component that renders action buttons and menus for managing a project workflow.
 *
 * @component
 * @param {IWorkflow} props.workflow - The workflow object containing workflow information
 * @param {IRoleBoard} props.role - Object containing user role permissions
 * @param {boolean} props.role.isProcessManagement - Whether user has process management permission
 * @param {boolean} props.role.isCreator - Whether user is the creator
 * @param {boolean} props.role.isAssignTask - Whether user can assign tasks
 * @param {boolean} props.hasStepStage - Whether the workflow has stages
 * @param {Function} props.onCloseWorkflow - Callback when closing the workflow
 * @param {Function} props.onCreateStage - Callback when creating a new stage
 * @param {Function} props.onCreateTaskSuccess - Callback when task creation is successful
 * @param {Function} props.onUpdateWorkflowSuccess - Callback when workflow update is successful
 * @param {Function} props.onCopyWorkflowSuccess - Callback when workflow copy is successful
 * @returns {JSX.Element} The rendered action buttons and menus
 */
const ActionProject = ({
  workflow,
  role,
  hasStepStage,
  onCloseWorkflow,
  onCreateStage,
  onCreateTaskSuccess,
  onUpdateWorkflowSuccess,
  onCopyWorkflowSuccess,
  onExportFileExcelByBoardId
}: Props): JSX.Element => {
  const [openCreateTask, setOpenCreateTask] = useState<boolean>(false);
  const [openUpdateWorkflow, setOpenUpdateWorkflow] = useState<boolean>(false);
  const [openCopyWorkflow, setOpenCopyWorkflow] = useState<boolean>(false);

  return (
    <Box
      sx={{
        position: "absolute",
        top: { xs: 107, md: 0 },
        right: 0
      }}
    >
      <Box
        className="content-header"
        sx={{ display: "flex", margin: { xs: "0 8px", md: "10px  16px 0 0" } }}
      >
        <Box
          className="header-action"
          sx={{
            display: "flex",
            button: {
              marginLeft: "8px"
            },
            "& #basic-button": {
              "& .menu-button": { padding: 0 }
            }
          }}
        >
          {(role.isProcessManagement ||
            role.isCreator ||
            role.isAssignTask) && (
            <Button
              size="small"
              variant="contained"
              color="info"
              sx={{
                fontSize: 13,
                fontWeight: 700,
                backgroundColor: "#3091F3",
                borderRadius: "6px"
              }}
              onClick={_ =>
                hasStepStage ? setOpenCreateTask(true) : onCreateStage()
              }
            >
              {hasStepStage ? "Tạo nhiệm vụ" : "Tạo giai đoạn"}
            </Button>
          )}
          {(role.isProcessManagement || role.isCreator) && (
            <BasicMenu
              onChange={() => {}}
              label={
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  sx={{ minWidth: "unset", borderRadius: "6px" }}
                >
                  <MoreHorizIcon sx={{ fontSize: 24 }} />
                </Button>
              }
              padding={0}
              options={[
                {
                  value: "edit",
                  label: (
                    <Box
                      sx={{ width: "100%", padding: "11px 12px" }}
                      onClick={() => onCreateStage()}
                    >
                      Thêm giai đoạn
                    </Box>
                  )
                },
                {
                  value: "update",
                  label: (
                    <Box
                      sx={{ width: "100%", padding: "11px 12px" }}
                      onClick={() => setOpenUpdateWorkflow(true)}
                    >
                      Cập nhật luồng công việc
                    </Box>
                  )
                },
                {
                  value: "clone",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px"
                      }}
                      onClick={() => setOpenCopyWorkflow(true)}
                    >
                      Nhân bản luồng công việc
                    </Box>
                  )
                },
                {
                  value: "clone",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px"
                      }}
                      onClick={onExportFileExcelByBoardId}
                    >
                      Xuất file Excel
                    </Box>
                  )
                },
                {
                  value: "divider",
                  label: (
                    <Divider
                      sx={{
                        height: "1px",
                        width: "90%",
                        marginInline: 1,
                        minHeight: "1px"
                      }}
                    />
                  )
                },

                {
                  value: "delete",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px",
                        color: "#ff4e4e"
                      }}
                      onClick={() => onCloseWorkflow(workflow.id)}
                    >
                      Đóng luồng công việc
                    </Box>
                  )
                }
              ]}
            />
          )}
          <CreateTaskModal
            open={openCreateTask}
            workflowId={workflow.id}
            onCreateSuccess={onCreateTaskSuccess}
            handleClose={() => setOpenCreateTask(false)}
          />
          <CreateWorkflowDialog
            open={openUpdateWorkflow}
            workflowId={workflow.id}
            handleClose={() => setOpenUpdateWorkflow(false)}
            handleSuccess={onUpdateWorkflowSuccess}
          />
          <CopyWorkflowModal
            open={openCopyWorkflow}
            workflowId={workflow.id}
            handleClose={() => setOpenCopyWorkflow(false)}
            handleSuccess={
              onCopyWorkflowSuccess ? onCopyWorkflowSuccess : () => {}
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ActionProject;
