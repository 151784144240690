import { Box, Popover, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import CircleIcon from "@mui/icons-material/Circle";
import {
  FeatureModel,
  MeetingRoomSchedulePermissionModel,
  meetingRoomStatusColor
} from "../../models/common/models.enum";
import CommonHandle from "../../common/handles/common.handles";
import {
  DATE_FORMAT2,
  DATE_REQ_FORMAT,
  TIME_FORMAT
} from "../../config/constants";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

import EventDetailPopover from "./components/EventDetailPopover";
import BookingHeader from "./components/BookingHeader";
import { Wrapper } from "./styles";
import { useApi } from "../../hooks/useApi.hook";
import {
  ICreateMeetingRoomReq,
  IMeetingRegistrant,
  IMeetingSchedule,
  IUpdateScheduleDateTimeReq
} from "../../models/meeting";
import MeetingService from "../../services/api/meeting.service";
import { EventContentArg, EventSourceInput } from "@fullcalendar/core";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toggleMessage } from "../../components/Toast/Toast";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import moment from "moment";
import { authTokens } from "../../services/services";
import { IFeaturePermission } from "../../models/user/models.roles";
import ArrowLeft from "../../assets/images/common/icon_square_arrow_left.svg";
// import dsdsdsds from "../../../../../assets/images/common/icon_square_arrow_left.svg";
import RoleService from "../../services/api/role.service";
import { Avatar } from "../../components";
import _ from "lodash";

type Props = {};

const Booking = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const [currentMonth, setCurrentMonth] = useState<any>(null);
  const [userId, setUserId] = useState<string | undefined>();
  const [permisisons, setPermisisons] = useState<string[] | null>(null);
  const [selectedItem, setSelectedItem] = useState<{
    anchorEl: any;
    item: IMeetingSchedule;
  } | null>(null);
  const [events, setEvents] = useState<EventSourceInput | undefined>();
  const getPage = useApi<IMeetingSchedule[]>({ isFetch: true, isPaging: true });
  const getSingle = useApi<IMeetingSchedule>({});
  const updateSchedule = useApi<IMeetingSchedule>({});
  const getFeaturePersmission = useApi<IFeaturePermission[]>({ isFetch: true });

  const validationSchema = Yup.object().shape({
    RoomName: Yup.string().required(`${"Vui lòng nhập tên phòng"}`),
    Location: Yup.string().required(`${"Vui lòng nhập vị trí"}`),
    Description: Yup.string().required(`${"Vui lòng nhập mô tả"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ICreateMeetingRoomReq>({
    resolver: yupResolver(validationSchema)
  });

  const EventItem = ({
    time,
    color,
    content,
    isActive,
    register
  }: {
    time: string | null;
    color: string;
    content: string;
    isActive: boolean;
    register?: IMeetingRegistrant;
  }) => {
    const name = register?.fullName.split(" ") ?? [];

    return (
      <Box
        width={"calc(100% - 12px)"}
        borderBottom={"1px solid rgba(89,105,112,0.1)"}
        padding="4px 0"
        margin="0 6px"
      >
        <Box display="flex" gap="4px" alignItems={"center"}>
          <CircleIcon sx={{ color: color, fontSize: "10px" }} />
          <Typography
            variant="caption"
            fontWeight={"600"}
            color={isActive ? color : undefined}
          >
            {time}
          </Typography>
          <Box flexGrow={1} />
          <Avatar src={register?.avatar} width="12px" height="12px" />
          <Typography variant="caption" fontWeight={"500"}>
            {name[name.length - 1]}
          </Typography>
        </Box>
        <p
          // variant="caption"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {content}
        </p>
      </Box>
    );
  };

  useEffect(() => {
    const containerEl = document.getElementById("#events");
    if (containerEl) {
      new Draggable(containerEl, {
        itemSelector: ".event",
        eventData: eventEl => {
          return {
            title: eventEl.innerText
          };
        }
      });
    }
  }, []);

  function fetchData() {
    getPage.request(
      MeetingService.getPage({
        Type: 0,
        Year: currentDate.year(),
        Month: currentDate.month() + 1
      })
    );
  }

  useEffect(() => {
    if (userId) {
      getFeaturePersmission.request(
        RoleService.getFeaturePemissionByUserId(userId).then(res => {
          if (res.success) {
            const permission = res.data.find(
              per => per.feature === FeatureModel.MeetingRoomSchedule
            );
            if (permission) {
              setPermisisons(permission.permissions);
            }
          }
        })
      );
    }
  }, [userId]);

  useEffect(() => {
    if (currentDate) fetchData();
  }, [currentDate]);

  const handleEventClick = (e: any) => {
    getSingle.request(
      MeetingService.getSingle(e.event.id).then(res => {
        if (res.success) {
          setSelectedItem({ anchorEl: e.el, item: res.data });
        }
      })
    );
  };

  useEffect(() => {
    if (selectedItem) {
      setAnchorEl(selectedItem.anchorEl);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (getPage.data) {
      const res = getPage.data.map(item => {
        return {
          title: item.title,
          start: item.startDate,
          end: item.endDate,
          backgroundColor: meetingRoomStatusColor(item.status).bgcolor,
          borderColor: meetingRoomStatusColor(item.status).color,
          id: item.id
        };
      });
      setEvents(res);
    }
  }, [getPage.data]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserId(user?.id);
    }
    getUserInfo();
  }, []);

  function handleDrop(e: any) {
    var item = getPage?.data?.find(meeting => meeting.id === e.event.id);
    if (item) {
      var request: IUpdateScheduleDateTimeReq = {
        Id: item.id,
        StartDate: CommonHandle.formatDate(
          `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${CommonHandle.formatDate(item.startDate, TIME_FORMAT)}`,
          DATE_REQ_FORMAT
        ),
        EndDate:
          item.endDate &&
          CommonHandle.formatDate(
            `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${CommonHandle.formatDate(item.endDate, TIME_FORMAT)}`,
            DATE_REQ_FORMAT
          )
      };
      toggleConfirmModal({
        title: `Bạn muốn thay đổi thời gian thành`,
        content: (
          <Box>
            <Typography variant="subtitle2" marginBottom="8px">
              Bắt đầu
            </Typography>
            <Box display="flex">
              <TextField
                type="date"
                sx={{ fontSize: "10px" }}
                size="small"
                value={CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}
                disabled
              />
              <TextField
                type="time"
                name="startDate"
                sx={{ fontSize: "10px" }}
                size="small"
                defaultValue={CommonHandle.formatDateTime(
                  e.event.start,
                  TIME_FORMAT
                )}
                onChange={v => {
                  const date = CommonHandle.formatDate(
                    `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${v.target.value}`,
                    DATE_REQ_FORMAT
                  );
                  if (date !== "Invalid date") {
                    request = {
                      ...request,
                      StartDate: date
                    };
                  }
                }}
              />
            </Box>
            <Typography variant="subtitle2" margin="8px 0">
              Kết thúc
            </Typography>
            <Box display="flex">
              <TextField
                type="date"
                sx={{ fontSize: "10px" }}
                size="small"
                value={CommonHandle.formatDate(e.event.end, DATE_FORMAT2)}
                disabled
              />
              <TextField
                type="time"
                sx={{ fontSize: "10px" }}
                size="small"
                defaultValue={CommonHandle.formatDateTime(
                  e.event.end,
                  TIME_FORMAT
                )}
                onChange={v => {
                  const date = CommonHandle.formatDate(
                    `${CommonHandle.formatDate(e.event.start, DATE_FORMAT2)}T${v.target.value}`,
                    DATE_REQ_FORMAT
                  );
                  if (date !== "Invalid date") {
                    request = {
                      ...request,
                      EndDate: date
                    };
                  }
                }}
              />
            </Box>
          </Box>
        ),
        onSubmit: async () => {
          updateSchedule.request(
            MeetingService.updateDateTime(request).then(response => {
              if (response.success) {
                toggleMessage({
                  type: "success",
                  message: "Cập nhật thành công!"
                });
                fetchData();
              } else {
                e.revert();
                toggleMessage({
                  type: "error",
                  message: updateSchedule?.error ?? "Đã xảy ra lỗi"
                });
              }
            })
          );
        },
        submitText: "Xác nhận",
        type: "warning",
        onCancel: async () => {
          e.revert();
        }
      });
    }
  }

  return (
    <Wrapper>
      <Box className="left-container">
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          sx={{ marginLeft: "12px" }}
        >
          <EventDetailPopover
            item={selectedItem?.item}
            reFetch={() => {
              fetchData();
              setAnchorEl(null);
            }}
            isAdminUpdate={
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.AdminUpdateStatus
              ) ?? false
            }
            isUserUpdate={
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.AdminUpdateStatus
              ) === false &&
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.UserUpdateStatus
              )
            }
            isUpdate={
              permisisons?.includes(
                MeetingRoomSchedulePermissionModel.Update
              ) ?? false
            }
            isRegister={selectedItem?.item.meetingRegistrantId === userId}
          />
        </Popover>

        <BookingHeader
          isCreate={
            permisisons?.includes(MeetingRoomSchedulePermissionModel.Create) ??
            false
          }
          refetch={fetchData}
          myBookings={
            getPage?.data?.filter(
              booking => booking.meetingRegistrantId === userId
            ) ?? []
          }
        />
      </Box>

      <Box
        className="book-calendar"
        flexGrow={1}
        sx={{ paddingTop: "16px", margin: "0 12px" }}
      >
        <FullCalendar
          timeZone="UTC"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin
          ]}
          initialView="dayGridMonth"
          dayMaxEventRows={3}
          editable
          droppable
          eventContent={arg => {
            const checkEvent = getPage.data?.find(
              evt => evt.id === arg.event.id
            );
            return (
              <Tooltip title={checkEvent?.title}>
                <EventItem
                  time={`${CommonHandle.formatDateTime(
                    arg.event.start,
                    TIME_FORMAT
                  )}${
                    (arg.event.end &&
                      ` - ${CommonHandle.formatDateTime(
                        arg.event.end,
                        TIME_FORMAT
                      )}`) ??
                    ""
                  }`}
                  color={arg.event.borderColor}
                  content={arg.event.title}
                  isActive={checkEvent?.meetingRegistrantId === userId}
                  register={checkEvent?.meetingRegistrant}
                />
                {/* {checkEvent?.meetingRegistrantId === userId ? (
                  <Box
                    sx={{
                      // backgroundColor: arg.event.backgroundColor,
                      width: "100%",
                      borderRadius: 2,
                      padding: "0 8px"
                      // border: `2px solid ${arg.event.borderColor}`
                    }}
                  >
                    <CircleIcon
                      sx={{
                        fontSize: 10,
                        color: arg.event.borderColor
                      }}
                    />
                    <Typography
                      variant="caption"
                      fontWeight="500"
                      color="#596970"
                    >
                      &nbsp;&nbsp;
                      {CommonHandle.formatDateTime(
                        arg.event.start,
                        TIME_FORMAT
                      )}{" "}
                      {arg.event.end &&
                        `- ${CommonHandle.formatDateTime(
                          arg.event.end,
                          TIME_FORMAT
                        )}`}
                    </Typography>

                    <Box>
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {arg.event.title}
                      </p>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      // backgroundColor: "white",
                      width: "100%",
                      borderRadius: 2,
                      padding: "0 8px"
                      // border: `2px solid ${arg.event.borderColor}`
                    }}
                  >
                    <Box>
                      <CircleIcon
                        sx={{
                          fontSize: 10,
                          color: arg.event.borderColor
                        }}
                      />
                      <Typography
                        variant="caption"
                        fontWeight="500"
                        color="#596970"
                      >
                        &nbsp;&nbsp;
                        {CommonHandle.formatDateTime(
                          arg.event.start,
                          TIME_FORMAT
                        )}
                        {arg.event.end &&
                          ` - ${CommonHandle.formatDateTime(
                            arg.event.end,
                            TIME_FORMAT
                          )}`}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Avatar
                        src={checkEvent?.meetingRegistrant?.avatar}
                        width="12px"
                        height="12px"
                      />
                      <Typography
                        variant="caption"
                        fontSize="10px"
                        marginLeft="4px"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {
                          checkEvent?.meetingRegistrant?.fullName.split(" ")[
                            checkEvent?.meetingRegistrant?.fullName.split(" ")
                              .length - 1
                          ]
                        }
                      </Typography>
                    </Box>
                    {!checkEvent?.meetingRegistrant ? (
                      <p>Không có dữ liệu</p>
                    ) : (
                      <Box display="flex">
                        <Avatar
                          src={checkEvent?.meetingRegistrant?.avatar}
                          width="12px"
                          height="12px"
                        />
                        <Typography
                          variant="caption"
                          fontSize="10px"
                          marginLeft="4px"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {
                            checkEvent?.meetingRegistrant?.fullName.split(" ")[
                              checkEvent?.meetingRegistrant?.fullName.split(" ")
                                .length - 1
                            ]
                          }
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {arg.event.title}
                      </p>
                    </Box>
                  </Box>
                )} */}
              </Tooltip>
            );
          }}
          height="85vh"
          locale={"vi"}
          noEventsText={"Không có dữ liệu"}
          dayHeaderFormat={{ weekday: "long" }}
          nowIndicator
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          }}
          headerToolbar={{
            end: "dayGridMonth,listMonth",
            center: "title",
            start: "prev today next"
          }}
          buttonIcons={{
            dayGridPlugin: "chevron-left"
          }}
          buttonText={{
            today: "Hôm nay",
            month: "Tháng",
            week: "Tuần",
            day: "Ngày",
            list: "Danh sách",
            listDay: "Ngày"
          }}
          datesSet={dateInfo => {
            const dates = moment(dateInfo.start).add(14, "days");
            setCurrentDate(dates);
          }}
          businessHours
          events={events}
          eventClick={handleEventClick}
          eventDrop={handleDrop}
          // eventAllow={(dropInfo, draggedEvent) => {
          //   return moment(
          //     moment(dropInfo.start).utc().format(DATETIME_FORMAT3)
          //   ).isAfter(
          //     moment(draggedEvent?.start).utc().format(DATETIME_FORMAT3)
          //   );
          // }}
        />
      </Box>

      <div className="schedules-md">
        {/* <Box display="flex" margin="8px">
          <Button
            onClick={() => setCurrentDate(currentDate.subtract(1, "months"))}
          >
            <ImageCustom src={ArrowLeft} width="24px" height="24px" />
          </Button>
          <Typography className="current-date">
            {currentDate?.format("MMMM") ?? ""}
          </Typography>
          <Button
            sx={{ transform: "rotate(180deg)" }}
            onClick={() => setCurrentDate(currentDate.add(1, "months"))}
          >
            <ImageCustom src={ArrowLeft} width="24px" height="24px" />
          </Button>
        </Box> */}

        {getPage.data &&
          _(getPage.data)
            .groupBy(x => moment(x.startDate).format(DATE_FORMAT2))
            .map((value, key) => ({ date: key, schedule: value }))
            .value()
            .map(data => (
              <Box key={data.date}>
                <Box display="flex">
                  <Typography
                    className="date-text"
                    marginTop="8px"
                    marginLeft="8px"
                  >
                    {moment(data.date).format("DD")}
                  </Typography>
                  <Box marginLeft="8px">
                    <Typography className="subtitle2-text" variant="subtitle2">
                      {moment(data.date).isSame(moment(), "day")
                        ? "Hôm nay"
                        : moment(data.date).format("dddd")}
                    </Typography>
                    <Typography className="caption-text" variant="caption">
                      {data.schedule.length} lịch họp
                    </Typography>
                  </Box>
                </Box>
                <Box marginLeft="52px" marginRight="12px">
                  {data.schedule.map(val => (
                    <Box
                      key={val.id}
                      sx={{
                        border: `1px solid ${meetingRoomStatusColor(val.status).color}`,
                        marginBottom: "12px",
                        borderRadius: "8px",
                        padding: "4px 12px"
                      }}
                    >
                      <Box display="flex">
                        <Typography className="caption-text" variant="caption">
                          {moment(val.startDate).format(TIME_FORMAT)}
                        </Typography>
                        -
                        <Typography className="caption-text" variant="caption">
                          {moment(val.endDate).format(TIME_FORMAT)}
                        </Typography>
                      </Box>
                      <Typography
                        className="subtitle2-text"
                        variant="subtitle2"
                      >
                        {val.title}
                      </Typography>
                      <Box display="flex">
                        <Avatar
                          src={val?.meetingRegistrant?.avatar}
                          width="20px"
                          height="20px"
                        />
                        <Typography
                          variant="caption"
                          fontSize="10px"
                          marginLeft="4px"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {val?.meetingRegistrant?.fullName}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
      </div>
    </Wrapper>
  );
};

export default Booking;
